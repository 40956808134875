import React, { useState } from 'react';
import { 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions, 
  Button, 
  Accordion, 
  AccordionSummary, 
  AccordionDetails, 
  Typography, 
  Box, 
  List, 
  ListItem, 
  ListItemText,
  IconButton,
  Chip,
  useTheme,
  alpha
} from '@mui/material';
import { 
  ExpandMore as ExpandMoreIcon,
  School as SchoolIcon,
  BookmarkBorder as UnitIcon,
  PlayLesson as LessonIcon,
  Close as CloseIcon
} from '@mui/icons-material';
import { motion, AnimatePresence } from 'framer-motion';

const CoursesDialog = ({ plan, open, onClose }) => {
  const theme = useTheme();
  const [expandedCourse, setExpandedCourse] = useState(null);
  const [expandedUnit, setExpandedUnit] = useState(null);

  if (!plan) return null;

  const MotionAccordion = motion(Accordion);
  const MotionListItem = motion(ListItem);

  // אנימציות עדינות יותר
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.3,
        staggerChildren: 0.05
      }
    }
  };

  const itemVariants = {
    hidden: { 
      opacity: 0,
      y: 10 
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.2,
        ease: 'easeOut'
      }
    }
  };

  return (
    <Dialog 
      open={open} 
      onClose={onClose} 
      fullWidth 
      maxWidth="md"
      PaperProps={{
        sx: {
          borderRadius: 2,
          overflow: 'hidden',
          bgcolor: 'background.paper'
        }
      }}
    >
      <DialogTitle sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        p: 3,
        borderBottom: `1px solid ${theme.palette.divider}`
      }}>
        <Box component={motion.div} initial={{ x: -10, opacity: 0 }} animate={{ x: 0, opacity: 1 }} transition={{ duration: 0.2 }} display="flex" alignItems="center" gap={2}>
          <SchoolIcon color="primary" />
          <Typography variant="h5" fontWeight="500">
            קורסים בתוכנית: {plan.title}
          </Typography>
        </Box>
        <IconButton onClick={onClose} size="small">
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ p: 3 }}>
        <AnimatePresence>
          {plan.courses?.length > 0 ? (
            <motion.div
              variants={containerVariants}
              initial="hidden"
              animate="visible"
            >
              {plan.courses.map((course, courseIndex) => (
                <MotionAccordion
                  key={course._id}
                  variants={itemVariants}
                  expanded={expandedCourse === course._id}
                  onChange={() => setExpandedCourse(expandedCourse === course._id ? null : course._id)}
                  sx={{ 
                    mb: 2,
                    '&:before': { display: 'none' },
                    boxShadow: 'none',
                    border: `1px solid ${theme.palette.divider}`,
                    borderRadius: 1,
                    overflow: 'hidden'
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    sx={{ 
                      bgcolor: alpha(theme.palette.primary.main, 0.03),
                      '&:hover': {
                        bgcolor: alpha(theme.palette.primary.main, 0.05)
                      }
                    }}
                  >
                    <Box display="flex" alignItems="center" gap={2}>
                      <Chip 
                        label={`קורס ${courseIndex + 1}`}
                        color="primary"
                        size="small"
                        variant="outlined"
                      />
                      <Typography variant="h6" sx={{ fontSize: '1.1rem' }}>
                        {course.title}
                      </Typography>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails sx={{ pt: 3 }}>
                    <Typography color="text.secondary" paragraph>
                      {course.description}
                    </Typography>

                    <Box sx={{ mt: 2 }}>
                      {course.units?.map((unit, unitIndex) => (
                        <Accordion
                          key={unit._id}
                          expanded={expandedUnit === unit._id}
                          onChange={() => setExpandedUnit(expandedUnit === unit._id ? null : unit._id)}
                          sx={{ 
                            boxShadow: 'none',
                            '&:before': { display: 'none' },
                            border: `1px solid ${theme.palette.divider}`,
                            mb: 1,
                            borderRadius: 1
                          }}
                        >
                          <AccordionSummary 
                            expandIcon={<ExpandMoreIcon />}
                            sx={{
                              '&:hover': {
                                bgcolor: alpha(theme.palette.primary.main, 0.02)
                              }
                            }}
                          >
                            <Box display="flex" alignItems="center" gap={2}>
                              <UnitIcon color="primary" fontSize="small" />
                              <Typography>
                                יחידה {unitIndex + 1}: {unit.title}
                              </Typography>
                            </Box>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography paragraph color="text.secondary">
                              {unit.description}
                            </Typography>
                            <List>
                              {unit.roadmap
                                .sort((a, b) => a.order - b.order)
                                .map((item, lessonIndex) => (
                                  <MotionListItem
                                    key={item.lesson._id}
                                    variants={{
                                      hidden: { opacity: 0, x: -5 },
                                      visible: { 
                                        opacity: 1, 
                                        x: 0,
                                        transition: {
                                          delay: lessonIndex * 0.03,
                                          duration: 0.15
                                        }
                                      }
                                    }}
                                    initial="hidden"
                                    animate="visible"
                                    sx={{ 
                                      py: 1.5,
                                      px: 2,
                                      borderRadius: 1,
                                      mb: 0.5,
                                      transition: 'background-color 0.2s',
                                      '&:hover': {
                                        bgcolor: alpha(theme.palette.primary.main, 0.03)
                                      }
                                    }}
                                  >
                                    <LessonIcon 
                                      fontSize="small" 
                                      sx={{ mr: 2, color: 'primary.light' }}
                                    />
                                    <ListItemText 
                                      primary={`${item.order}. ${item.lesson.title}`}
                                      primaryTypographyProps={{
                                        variant: 'body2'
                                      }}
                                    />
                                  </MotionListItem>
                                ))}
                            </List>
                          </AccordionDetails>
                        </Accordion>
                      ))}
                    </Box>
                  </AccordionDetails>
                </MotionAccordion>
              ))}
            </motion.div>
          ) : (
            <Typography color="text.secondary" textAlign="center">
              אין קורסים כלולים בתוכנית זו.
            </Typography>
          )}
        </AnimatePresence>
      </DialogContent>

      <DialogActions sx={{ p: 2.5, borderTop: `1px solid ${theme.palette.divider}` }}>
        <Button 
          onClick={onClose}
          variant="outlined"
          sx={{
            px: 3,
            py: 1,
            borderRadius: 1.5,
            textTransform: 'none',
          }}
        >
          סגור
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CoursesDialog;