import React, { useState } from 'react';
import {
  Drawer,
  IconButton,
  TextField,
  Button,
  Tooltip,
  useMediaQuery,
  Typography,
  Divider,
  Paper,
  Box,
  Rating,
  styled,
  alpha,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  RateReview,
  Close,
  Attachment,
  Send as SendIcon,
  InsertEmoticon,
} from '@mui/icons-material';

// Styled Components
const StyledDrawer = styled(Drawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    width: 400,
    padding: theme.spacing(4),
    background: alpha(theme.palette.background.paper, 0.9),
    backdropFilter: 'blur(8px)',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

const FeedbackButton = styled(IconButton)(({ theme }) => ({
  position: 'fixed',
  bottom: theme.spacing(14),
  right: theme.spacing(-1),
  zIndex: 1300,
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.common.white,
  padding: theme.spacing(2),
  boxShadow: `0 4px 20px ${alpha(theme.palette.secondary.main, 0.25)}`,
  transition: theme.transitions.create(['transform', 'background-color', 'box-shadow'], {
    duration: 200,
  }),

  '&:hover': {
    backgroundColor: theme.palette.secondary.dark,
    transform: 'scale(1.1) translateX(5px)',
    boxShadow: `0 6px 25px ${alpha(theme.palette.secondary.main, 0.35)}`,
  },
  borderRadius: '50%',
  width: 48,
  height: 48,
}));

const FormContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  borderRadius: theme.shape.borderRadius * 2,
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[3],
  transition: theme.transitions.create(['box-shadow']),
  '&:hover': {
    boxShadow: theme.shadows[6],
  },
}));

const UploadButton = styled(Button)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius * 2,
  padding: theme.spacing(1, 3),
  transition: theme.transitions.create(['background-color', 'box-shadow']),
  '&:hover': {
    boxShadow: `0 4px 12px ${alpha(theme.palette.secondary.main, 0.2)}`,
  },
}));

const SubmitButton = styled(Button)(({ theme }) => ({
  width: '60%',
  marginTop: theme.spacing(4),
  padding: theme.spacing(1.5),
  borderRadius: theme.shape.borderRadius * 3,
  fontSize: '1.1rem',
  fontWeight: 600,
  boxShadow: `0 4px 14px ${alpha(theme.palette.secondary.main, 0.25)}`,
  transition: theme.transitions.create(['transform', 'box-shadow']),
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: `0 6px 20px ${alpha(theme.palette.secondary.main, 0.35)}`,
  },
}));

const ReviewSidebar = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [open, setOpen] = useState(false);
  const [reviewText, setReviewText] = useState('');
  const [screenshot, setScreenshot] = useState(null);
  const [rating, setRating] = useState(0);
  const [isHovered, setIsHovered] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    // Submit logic here
    setReviewText('');
    setScreenshot(null);
    setRating(0);
    setOpen(false);
  };

  return (
    <>
      <Tooltip 
        title="שלח משוב" 
        placement="right"
        arrow
      >
        <FeedbackButton
          onClick={() => setOpen(true)}
          aria-label="פתח משוב"
        >
          <RateReview />
        </FeedbackButton>
      </Tooltip>

      <StyledDrawer
        anchor="left"
        open={open}
        onClose={() => setOpen(false)}
        variant="temporary"
      >
        <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
            <Typography variant="h5" sx={{ fontWeight: 600 }}>
              נשמח לשמוע את דעתך!
            </Typography>
            <IconButton 
              onClick={() => setOpen(false)}
              aria-label="סגור"
              sx={{ 
                '&:hover': { 
                  transform: 'rotate(90deg)',
                  transition: 'transform 0.3s'
                }
              }}
            >
              <Close />
            </IconButton>
          </Box>

          <Divider sx={{ mb: 4 }} />

          <FormContainer elevation={0}>
            <form onSubmit={handleSubmit}>
              <Box sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                gap: 2,
                mb: 4 
              }}>
                <Typography variant="h6">הדירוג שלך:</Typography>
                <Rating
                  name="rating"
                  value={rating}
                  onChange={(e, newValue) => setRating(newValue)}
                  size="large"
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                  icon={<InsertEmoticon color={isHovered ? "secondary" : "action"} />}
                />
              </Box>

              <TextField
                label="המשוב שלך"
                multiline
                rows={4}
                value={reviewText}
                onChange={(e) => setReviewText(e.target.value)}
                required
                fullWidth
                variant="outlined"
                sx={{
                  mb: 3,
                  '& .MuiOutlinedInput-root': {
                    borderRadius: 2,
                    transition: theme.transitions.create(['box-shadow']),
                    '&:hover': {
                      boxShadow: `0 0 0 2px ${alpha(theme.palette.primary.main, 0.1)}`,
                    },
                    '&.Mui-focused': {
                      boxShadow: `0 0 0 3px ${alpha(theme.palette.primary.main, 0.2)}`,
                    },
                  },
                }}
              />

              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <UploadButton
                  variant="outlined"
                  component="label"
                  startIcon={<Attachment />}
                >
                  העלאת צילום מסך
                  <input
                    type="file"
                    hidden
                    accept="image/*"
                    onChange={(e) => setScreenshot(e.target.files[0])}
                  />
                </UploadButton>
                {screenshot && (
                  <Typography
                    variant="body2"
                    sx={{
                      wordBreak: 'break-all',
                      color: 'text.secondary',
                    }}
                  >
                    {screenshot.name}
                  </Typography>
                )}
              </Box>

              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <SubmitButton
                  type="submit"
                  variant="contained"
                  endIcon={<SendIcon />}
                >
                  שלח משוב
                </SubmitButton>
              </Box>
            </form>
          </FormContainer>
        </Box>
      </StyledDrawer>
    </>
  );
};

export default ReviewSidebar;