import React, { useState } from 'react';
import { Box, Typography, Paper } from '@mui/material';

const ExploreStudyMaterials = () => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Box sx={{ 
      textAlign: 'center',
      py: 6,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    }}>
      <Box 
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        sx={{ 
          position: 'relative',
          width: 160,
          height: 160,
          mb: 4,
          cursor: 'pointer'
        }}
      >
        {/* Books Stack */}
        <Paper
          elevation={3}
          sx={{
            position: 'absolute',
            bottom: 0,
            left: '32px',
            width: '96px',
            height: '128px',
            bgcolor: 'primary.main',
            borderRadius: '2px',
            transition: 'all 0.5s',
            transform: isHovered ? 'translateX(-24px) rotate(-15deg)' : 'none'
          }}
        />
        <Paper
          elevation={3}
          sx={{
            position: 'absolute',
            bottom: 0,
            left: '48px',
            width: '96px',
            height: '128px',
            bgcolor: 'secondary.main',
            borderRadius: '2px',
            transition: 'all 0.5s',
            transform: isHovered ? 'translateX(24px) rotate(15deg)' : 'none'
          }}
        />
        <Paper
          elevation={3}
          sx={{
            position: 'absolute',
            bottom: 0,
            left: '40px',
            width: '96px',
            height: '128px',
            bgcolor: 'info.main',
            borderRadius: '2px',
            transition: 'all 0.5s',
            transform: isHovered ? 'translateY(-16px)' : 'none'
          }}
        />
      </Box>

      <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 2 }}>
        חקור תכני לימוד
      </Typography>
      <Typography variant="h6" color="text.secondary">
        בקרוב!
      </Typography>
    </Box>
  );
};

export default ExploreStudyMaterials;