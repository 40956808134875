import React from 'react';
import { Outlet } from 'react-router-dom';
import { Box } from '@mui/material';
import NavBar from '../Navbar/Navbar';
import Footer from './Footer';
import AccessibilitySidebar from '../Widgets/AccessibilitySidebar';
import ScrollToTop from './ScrollToTop';
import ReviewSidebar from '../Widgets/ReviewSidebar';

const Layout = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        position: 'relative',
      }}
    >
      {/* Navigation Bar */}
      <NavBar />

      {/* Main Content Area */}
      <Box
        sx={{
          padding: { xs: 2, sm: 3 },
          marginBottom: {xs : 16 , md: 0},
          marginLeft: { xs: 0, md: 32 },
          transition: 'margin-left 0.3s',
          zIndex: 1,
          position: 'relative',
        }}
      >
        <Outlet />
      </Box>

      {/* Accessibility Sidebar */}
      <AccessibilitySidebar />

      <ReviewSidebar />

      {/* Scroll to Top */}
      <ScrollToTop />
    </Box>
  );
};

export default Layout;
