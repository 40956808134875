// ScheduleComponent.jsx
import scheduleData from './schedule.json';
import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  CircularProgress,
  Paper,
  Container,
  useTheme
} from '@mui/material';
import ReactPlayer from 'react-player';
import { parse, addMinutes, isWithinInterval, format } from 'date-fns';
import { he } from 'date-fns/locale';

const ScheduleComponent = () => {
  const theme = useTheme();
  const [currentLesson, setCurrentLesson] = useState(null);
  const [nextLesson, setNextLesson] = useState(null);

  const getCurrentLesson = (now) => {
    for (const day of scheduleData) {
      const scheduleDate = new Date(day.date);
      
      if (scheduleDate.getFullYear() === now.getFullYear() &&
          scheduleDate.getMonth() === now.getMonth() &&
          scheduleDate.getDate() === now.getDate()) {
        
        for (const lesson of day.lessons) {
          const lessonStartTime = parse(
            `${day.date} ${lesson.startTime}`,
            'yyyy-MM-dd HH:mm',
            new Date()
          );
          const lessonEndTime = addMinutes(lessonStartTime, 90);

          if (isWithinInterval(now, { start: lessonStartTime, end: lessonEndTime })) {
            return { ...lesson, date: day.date, lessonStartTime };
          }
        }
      }
    }
    return null;
  };

  const getNextLesson = (now) => {
    const upcomingLessons = scheduleData
      .filter(day => new Date(day.date) >= new Date(now.toDateString()))
      .flatMap(day => day.lessons.map(lesson => ({
        ...lesson,
        date: day.date,
        lessonStartTime: parse(`${day.date} ${lesson.startTime}`, 'yyyy-MM-dd HH:mm', new Date())
      })))
      .filter(lesson => lesson.lessonStartTime > now)
      .sort((a, b) => a.lessonStartTime - b.lessonStartTime);

    return upcomingLessons[0] || null;
  };

  useEffect(() => {
    const checkLessons = () => {
      const now = new Date();
      setCurrentLesson(getCurrentLesson(now));
      setNextLesson(getNextLesson(now));
    };

    checkLessons();
    const interval = setInterval(checkLessons, 60000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Container maxWidth="lg" sx={{ py: 4, direction: 'rtl' }}>
      {currentLesson ? (
        <Paper 
          elevation={3}
          sx={{
            p: 4,
            borderRadius: 2,
            border: `2px solid ${theme.palette.primary.main}`,
            bgcolor: theme.palette.background.paper
          }}
        >
          <Typography 
            variant="h4" 
            color="primary"
            gutterBottom
            sx={{ textAlign: 'center' }}
          >
            שיעור בשידור חי: {format(currentLesson.lessonStartTime, 'dd/MM/yyyy', { locale: he })} בשעה{' '}
            {format(currentLesson.lessonStartTime, 'HH:mm')}
          </Typography>
          
          {currentLesson.url ? (
            <Box sx={{ mt: 3, aspectRatio: '16/9', width: '100%' }}>
              <ReactPlayer
                url={currentLesson.url}
                controls
                width="100%"
                height="100%"
                playing
              />
            </Box>
          ) : (
            <Typography variant="body1" sx={{ textAlign: 'center', mt: 2 }}>
              .אין וידאו זמין לשיעור זה
            </Typography>
          )}
        </Paper>
      ) : (
        <Paper 
          elevation={2}
          sx={{
            p: 6,
            borderRadius: 2,
            textAlign: 'center',
            bgcolor: theme.palette.background.paper
          }}
        >
          <Typography variant="h3" gutterBottom>
            אין שיעור בשידור חי כעת
          </Typography>
          
          {nextLesson ? (
            <Typography 
              variant="h5" 
              color="text.secondary"
              sx={{ mt: 2 }}
            >
              השיעור הבא: {format(nextLesson.lessonStartTime, 'dd/MM/yyyy', { locale: he })} בשעה{' '}
              {format(nextLesson.lessonStartTime, 'HH:mm')}
            </Typography>
          ) : (
            <Typography 
              variant="h5" 
              color="text.secondary"
              sx={{ mt: 2 }}
            >
              .אין שיעורים מתוכננים בקרוב
            </Typography>
          )}
          
          <CircularProgress 
            size={40}
            sx={{ 
              mt: 4,
              color: theme.palette.primary.main,
              animation: 'custom-spin 300s linear infinite', // Custom animation
              '@keyframes custom-spin': {
                '0%': {
                  transform: 'rotate(0deg)',
                },
                '100%': {
                  transform: 'rotate(360deg)',
                },
              },
            }} 
            
          />
        </Paper>
      )}
    </Container>
  );
};

export default ScheduleComponent;