import { styled, alpha } from '@mui/material/styles';
import { Card, Chip, Button, Box, Paper } from '@mui/material';

export const QuestionCard = styled(Card)(({ theme }) => ({
  position: 'relative',
  marginBottom: theme.spacing(3),
  borderRadius: theme.shape.borderRadius * 2,
  background: alpha(theme.palette.background.paper, 0.8),
  backdropFilter: 'blur(10px)',
  transition: theme.transitions.create(['transform', 'box-shadow'], {
    duration: 200,
  }),
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: `0 8px 24px ${alpha(theme.palette.primary.main, 0.15)}`,
  },
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: '4px',
    background: `linear-gradient(90deg, ${theme.palette.primary.main}, ${theme.palette.primary.light})`,
    opacity: 0,
    transition: 'opacity 0.3s ease',
    borderRadius: `${theme.shape.borderRadius * 2}px ${theme.shape.borderRadius * 2}px 0 0`,
  },
  '&:hover::before': {
    opacity: 1,
  },
}));

export const LevelChip = styled(Chip)(({ level, theme }) => {
  const colors = {
    beginner: {
      bg: alpha(theme.palette.success.main, 0.1),
      color: theme.palette.success.main,
      border: theme.palette.success.main,
    },
    intermediate: {
      bg: alpha(theme.palette.warning.main, 0.1),
      color: theme.palette.warning.main,
      border: theme.palette.warning.main,
    },
    advanced: {
      bg: alpha(theme.palette.error.main, 0.1),
      color: theme.palette.error.main,
      border: theme.palette.error.main,
    },
  };
  const levelColors = colors[level] || {
    bg: alpha(theme.palette.grey[500], 0.1),
    color: theme.palette.grey[500],
    border: theme.palette.grey[500],
  };

  return {
    backgroundColor: levelColors.bg,
    color: levelColors.color,
    border: `1px solid ${levelColors.border}`,
    '& .MuiChip-label': {
      fontWeight: 600,
    },
  };
});

export const QuestionButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  padding: theme.spacing(1.5, 3),
  borderRadius: theme.shape.borderRadius * 3,
  fontWeight: 600,
  transition: theme.transitions.create(['transform', 'box-shadow']),
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: `0 4px 12px ${alpha(theme.palette.primary.main, 0.2)}`,
  },
}));

export const SolutionContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginTop: theme.spacing(3),
  borderRadius: theme.shape.borderRadius * 2,
  backgroundColor: alpha(theme.palette.background.default, 0.6),
  backdropFilter: 'blur(4px)',
  border: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
}));

export const QuestionImage = styled('img')(({ theme }) => ({
  maxWidth: '100%',
  height: 'auto',
  borderRadius: theme.shape.borderRadius * 2,
  boxShadow: `0 4px 12px ${alpha(theme.palette.common.black, 0.1)}`,
  transition: theme.transitions.create('transform'),
  '&:hover': {
    transform: 'scale(1.02)',
  },
}));

export const TimeIndicator = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(0.5),
  padding: theme.spacing(0.5, 1.5),
  borderRadius: theme.shape.borderRadius * 4,
  backgroundColor: alpha(theme.palette.primary.main, 0.1),
  color: theme.palette.primary.main,
  fontSize: '0.875rem',
  fontWeight: 500,
}));