import React from 'react';
import { Box, Typography, Chip, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { 
  AccessTime, 
  CalendarToday, 
  CheckCircle, 
  Warning 
} from '@mui/icons-material';
import dayjs from 'dayjs';
import { ProfileCard } from './styles';

const SubscriptionDetails = ({ subscription }) => {
  if (!subscription) {
    return (
      <Typography variant="subtitle1" color="text.secondary" sx={{ textAlign: 'center', mt: 2 }}>
        אין לך תוכנית מנוי פעילה.
      </Typography>
    );
  }

  const isActive = subscription.status.toLowerCase() === 'active';
  const daysLeft = dayjs(subscription.endDate).diff(dayjs(), 'day');

  return (
    <ProfileCard>
      <Box sx={{ p: 3 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: 3 }}>
          <Box>
            <Typography variant="h5" gutterBottom>
              {subscription.plan.title}
            </Typography>
            <Chip
              icon={isActive ? <CheckCircle /> : <Warning />}
              label={subscription.status}
              color={isActive ? 'success' : 'default'}
              sx={{ mb: 2 }}
            />
          </Box>
          <Typography variant="h4" color="primary.main" fontWeight="bold">
            ₪{subscription.plan.price}
          </Typography>
        </Box>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <CalendarToday color="primary" />
              <Box>
                <Typography variant="caption" color="text.secondary">
                  תאריך התחלה
                </Typography>
                <Typography>
                  {dayjs(subscription.startDate).format('DD/MM/YYYY')}
                </Typography>
              </Box>
            </Box>
          </Grid>
          
          <Grid item xs={12} sm={6}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <AccessTime color="primary" />
              <Box>
                <Typography variant="caption" color="text.secondary">
                  תאריך סיום
                </Typography>
                <Typography>
                  {dayjs(subscription.endDate).format('DD/MM/YYYY')}
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>

        {isActive && daysLeft > 0 && (
          <Box sx={{ mt: 3, textAlign: 'center' }}>
            <Typography variant="subtitle2" color="success.main">
              נותרו עוד {daysLeft} ימים למנוי שלך
            </Typography>
          </Box>
        )}
      </Box>
    </ProfileCard>
  );
};

export default SubscriptionDetails;
