import React from 'react';
import { Box, Typography } from '@mui/material';
import QuestionCard from './QuestionCard';

const HomeworkSection = ({ questions = [] }) => {
  return (
    <Box sx={{ mb: 4 }}>
      <Typography 
        variant="h5" 
        gutterBottom 
        sx={{ 
          fontWeight: 'bold',
          mb: 3,
          background: (theme) => 
            `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.primary.light})`,
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
        }}
      >
        שיעורי בית
      </Typography>

      {questions.length > 0 ? (
        questions.map((question, index) => (
          <QuestionCard
            key={question._id}
            question={question}
            index={index}
          />
        ))
      ) : (
        <Typography 
          variant="body1" 
          color="text.secondary"
          sx={{ textAlign: 'center', py: 4 }}
        >
          אין שיעורי בית זמינים לשיעור זה.
        </Typography>
      )}
    </Box>
  );
};

export default HomeworkSection;