// src/infoCards.js

const infoCards = [
  {
    title: 'החזון שלי',
    description:
      'להוביל מהפכה בחינוך באמצעות חדשנות וטכנולוגיה, ולהעניק לכל תלמיד את הכלים לממש את הפוטנציאל האינסופי שלו.',
    path: 'images/home/mission.jpg',
  },
  {
    title: 'מה מחכה לכם באינפיניטי',
    description:
      'גישה לתכנים חכמים, קורסים מתקדמים ומשאבים אינטראקטיביים שידחפו את גבולות הידע והיצירתיות שלכם.',
    path: 'images/home/offers.jpg',
  },
  {
    title: 'הקהילה שלנו',
    description:
      'הצטרפו לרשת של מוחות מבריקים, חולקים רעיונות ומשתפים פעולה כדי ליצור עתיד טוב יותר לכולנו.',
    path: 'images/home/community.jpg',
  },
];

export default infoCards;
