
// src/components/Checkout/PaymentForm/styled.js
import { styled } from '@mui/material/styles';
import { Paper, Box, Card } from '@mui/material';

export const StyledPaper = styled(Paper)(({ theme }) => ({
  position: 'relative',
  overflow: 'visible',
  padding: theme.spacing(3),
  borderRadius: theme.shape.borderRadius * 2,
  transition: 'all 0.3s ease',
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: theme.shadows[6]
  }
}));

export const SecurityBadge = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: -20,
  right: 20,
  backgroundColor: theme.palette.success.main,
  color: theme.palette.common.white,
  padding: theme.spacing(1, 2),
  borderRadius: theme.shape.borderRadius * 2,
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  boxShadow: theme.shadows[2],
  zIndex: 1
}));

export const PriceCard = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  background: 'linear-gradient(45deg, #f3f4f6 30%, #ffffff 90%)'
}));

export const SecurityInfo = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(3),
  paddingTop: theme.spacing(2),
  borderTop: `1px solid ${theme.palette.divider}`,
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  color: theme.palette.text.secondary
}));