import React from 'react';
import { Typography, Grid, Box } from '@mui/material';
import LessonCard from './LessonCard';

const LessonsSection = ({ 
  title, 
  lessons, 
  isEnrolled, 
  onEnroll,
  emptyMessage 
}) => {
  if (!lessons?.length) {
    return (
      <Box sx={{ mb: 6 }}>
        <Typography variant="h4" gutterBottom>
          {title}
        </Typography>
        <Typography variant="body1" color="text.secondary">
          {emptyMessage}
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ mb: 6 }}>
      <Typography 
        variant="h4" 
        gutterBottom
        sx={{
          fontWeight: 600,
          mb: 3,
          background: (theme) => 
            `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.primary.light})`,
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
        }}
      >
        {title}
      </Typography>
      <Grid container spacing={4}>
        {lessons.map((lesson) => (
          <Grid item key={lesson._id} xs={12} sm={6} md={4}>
            <LessonCard
              lesson={lesson}
              isEnrolled={isEnrolled}
              onEnroll={onEnroll}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default LessonsSection;
