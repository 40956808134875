import React, { useState } from 'react';
import {
  Box,
  Container,
  Typography,
  TextField,
  Button,
  Alert,
  Snackbar,
  Paper,
  Grid,
  IconButton,
  useTheme
} from '@mui/material';
import { Send, Phone, WhatsApp, Email, LocationOn } from '@mui/icons-material';
import { motion } from 'framer-motion';

const ContactUs = () => {
  const theme = useTheme();
  const [formData, setFormData] = useState({ name: '', email: '', phone: '', message: '' });
  const [status, setStatus] = useState({ submitted: false, error: null });
  const [snackbar, setSnackbar] = useState(false);

  const contactInfo = [
    { icon: <Phone />, text: '050-9711315', link: 'tel:0509711315' },
    { 
      icon: <WhatsApp />, 
      text: 'WhatsApp', 
      link: 'https://wa.me/972509711315' // Replace with your actual number in international format
    },
    { icon: <Email />, text: 'ohadinfinite@gmail.com', link: 'mailto:ohadinfinite@gmail.com' },
    { icon: <LocationOn />, text: 'שוהם , תל אביב' }
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!Object.values(formData).every(Boolean)) {
      setStatus({ error: 'נא למלא את כל השדות' });
      return;
    }
    
    try {
      // API call would go here
      setStatus({ submitted: true });
      setSnackbar(true);
      setFormData({ name: '', email: '', phone: '', message: '' });
    } catch (error) {
      setStatus({ error: 'אירעה שגיאה בשליחת הטופס' });
    }
  };

  return (
    <Container maxWidth="lg" >
      <Grid container spacing={6}>
        <Grid item xs={12} md={6}>
          <motion.div
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
          >
            <Typography variant="h3" gutterBottom color="primary" fontWeight="bold">
              בואו נתחיל את המסע שלכם
            </Typography>
            <Typography variant="h6" color="text.secondary" paragraph>
              מעוניינים להתקדם במתמטיקה, פיזיקה או מדעי המחשב? אשמח לסייע לכם להגיע ליעדים שלכם
            </Typography>
            
            <Box sx={{ mt: 4 }}>
              <Grid container spacing={2}>
                {contactInfo.map((info, index) => {
                  const isLink = Boolean(info.link);
                  return (
                    <Grid item xs={6} key={index}>
                      {isLink ? (
                        <Paper
                          component={motion.a}
                          href={info.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          whileHover={{ scale: 1.05 }}
                          sx={{
                            p: 2,
                            display: 'flex',
                            alignItems: 'center',
                            gap: 2,
                            textDecoration: 'none',
                            color: 'inherit',
                            cursor: 'pointer'
                          }}
                        >
                          <IconButton color="primary">
                            {info.icon}
                          </IconButton>
                          <Typography>{info.text}</Typography>
                        </Paper>
                      ) : (
                        <Paper
                          component={motion.div}
                          whileHover={{ scale: 1.05 }}
                          sx={{
                            p: 2,
                            display: 'flex',
                            alignItems: 'center',
                            gap: 2
                          }}
                        >
                          <IconButton color="primary">
                            {info.icon}
                          </IconButton>
                          <Typography>{info.text}</Typography>
                        </Paper>
                      )}
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
          </motion.div>
        </Grid>

        <Grid item xs={12} md={6}>
          <motion.div
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
          >
            <Paper
              elevation={3}
              sx={{
                p: 4,
                borderRadius: 2,
                bgcolor: 'background.paper'
              }}
            >
              {status.error && (
                <Alert severity="error" sx={{ mb: 3 }}>
                  {status.error}
                </Alert>
              )}

              <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                <TextField
                  label="שם מלא"
                  value={formData.name}
                  onChange={e => setFormData({ ...formData, name: e.target.value })}
                  fullWidth
                  required
                />
                <TextField
                  label="אימייל"
                  type="email"
                  value={formData.email}
                  onChange={e => setFormData({ ...formData, email: e.target.value })}
                  fullWidth
                  required
                />
                <TextField
                  label="טלפון"
                  type="tel"
                  value={formData.phone}
                  onChange={e => setFormData({ ...formData, phone: e.target.value })}
                  fullWidth
                  required
                />
                <TextField
                  label="הודעה"
                  multiline
                  rows={4}
                  value={formData.message}
                  onChange={e => setFormData({ ...formData, message: e.target.value })}
                  fullWidth
                  required
                />
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  endIcon={<Send />}
                  sx={{
                    py: 1.5,
                    fontSize: '1.1rem',
                    transition: theme.transitions.create(['transform', 'box-shadow']),
                    '&:hover': {
                      transform: 'translateY(-2px)',
                      boxShadow: theme.shadows[8]
                    }
                  }}
                >
                  שלח הודעה
                </Button>
              </Box>
            </Paper>
          </motion.div>
        </Grid>
      </Grid>

      <Snackbar
        open={snackbar}
        autoHideDuration={6000}
        onClose={() => setSnackbar(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert severity="success" onClose={() => setSnackbar(false)}>
          הודעתך התקבלה בהצלחה! ניצור איתך קשר בהקדם
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default ContactUs;
