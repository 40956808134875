import React, { useContext, useState } from 'react';
import {
  Box,
  Typography,
  Container,
  CardContent,
  Button,
  Alert,
  Grid,
  Divider,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { DataContext } from '../../context/DataContext';
import { UserContext } from '../../context/UserContext';

// Components
import SubscriptionStatus from './SubscriptionStatus';
import SubscriptionTimeline from './SubscriptionTimeline';
import { DetailsDialog, CancelDialog } from './SubscriptionDialogs';
import { SubscriptionCard } from './styles';

// Loading and Error Components
import LoadingState from '../Common/LoadingState';
import ErrorState from '../Common/ErrorState';

const MySubscription = () => {
  const navigate = useNavigate();
  const { plans, loading: dataLoading, error: dataError } = useContext(DataContext);
  const {
    subscriptions,
    loading: userLoading,
    error: userError,
    setError,
    cancelSubscription,
  } = useContext(UserContext);

  const [detailsDialogOpen, setDetailsDialogOpen] = useState(false);
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const [cancelDialogOpen, setCancelDialogOpen] = useState(false);
  const [subscriptionToCancel, setSubscriptionToCancel] = useState(null);

  const handleCancelSubscription = async () => {
    try {
      await cancelSubscription(subscriptionToCancel._id);
      setCancelDialogOpen(false);
      navigate('/subscription-canceled');
    } catch (error) {
      console.error('Error canceling subscription:', error);
      setError('Failed to cancel subscription. Please try again.');
    }
  };

  const renderSubscriptionCard = (subscription) => {
    const plan = plans.find((p) => p._id === subscription.plan._id);
    if (!plan) return null;

    return (
      <SubscriptionCard key={subscription._id}>
        <CardContent sx={{ p: 4 }}>
          <Typography variant="h4" gutterBottom>
            {plan.title}
          </Typography>
          
          <SubscriptionStatus subscription={subscription} />
          <SubscriptionTimeline subscription={subscription} />
          
          <Divider sx={{ my: 3 }} />
          
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Button
                fullWidth
                variant="contained"
                color="secondary"
                onClick={() => {
                  setSelectedSubscription(subscription);
                  setDetailsDialogOpen(true);
                }}
                sx={{ borderRadius: 8 }}
              >
                פרטי המנוי
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                fullWidth
                variant="outlined"
                color="error"
                onClick={() => {
                  setSubscriptionToCancel(subscription);
                  setCancelDialogOpen(true);
                }}
                sx={{ borderRadius: 8 }}
              >
                ביטול מנוי
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </SubscriptionCard>
    );
  };

  if (dataLoading || userLoading) {
    return <LoadingState />;
  }

  if (dataError || userError) {
    return <ErrorState error={dataError || userError} />;
  }

  return (
    <Container maxWidth="lg" sx={{ py: 8 }}>
      <Typography variant="h3" textAlign="center" gutterBottom>
        המנויים שלי
      </Typography>
      <Typography variant="h6" textAlign="center" color="text.secondary" sx={{ mb: 6 }}>
        נהל את המנויים הפעילים שלך
      </Typography>

      {subscriptions?.length > 0 ? (
        subscriptions.map(renderSubscriptionCard)
      ) : (
        <Alert
          severity="info"
          action={
            <Button color="primary" onClick={() => navigate('/select-plan')}>
              הירשם עכשיו
            </Button>
          }
        >
          אין לך מנויים פעילים כרגע.
        </Alert>
      )}

      <DetailsDialog
        open={detailsDialogOpen}
        onClose={() => setDetailsDialogOpen(false)}
        subscription={selectedSubscription}
        plan={selectedSubscription ? plans.find(p => p._id === selectedSubscription.plan._id) : null}
      />

      <CancelDialog
        open={cancelDialogOpen}
        onClose={() => setCancelDialogOpen(false)}
        onConfirm={handleCancelSubscription}
      />
    </Container>
  );
};

export default MySubscription;
