// src/components/Checkout/Checkout.js
import React, { useEffect, useState, useContext } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { UserContext } from '../../context/UserContext';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery, Container, Grid, Box, Button, CircularProgress, Alert } from '@mui/material';
import { PlanDetails } from './PlanDetails/PlanDetails';
import { PaymentForm } from './PaymentForm/PaymentForm';
import { fetchPlanDetails } from './checkoutService';


const Checkout = () => {
  const { planId } = useParams();
  const navigate = useNavigate();
  const { createSubscription } = useContext(UserContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [plan, setPlan] = useState(null);
  const [loading, setLoading] = useState(true);
  const [fetchError, setFetchError] = useState(null);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    const loadPlan = async () => {
      try {
        const data = await fetchPlanDetails(planId);
        setPlan(data);
      } catch (error) {
        setFetchError(error.message);
      } finally {
        setLoading(false);
      }
    };

    loadPlan();
  }, [planId]);

  if (loading) {
    return (
      <Container sx={{ py: 8, textAlign: 'center' }}>
        <CircularProgress />
      </Container>
    );
  }

  if (fetchError) {
    return (
      <Container>
        <Alert severity="error">{fetchError}</Alert>
        <Box sx={{ mt: 4, textAlign: 'center' }}>
          <Button variant="contained" color="primary" component={Link} to="/all-plans">
            חזרה לתוכניות
          </Button>
        </Box>
      </Container>
    );
  }

  return (
    <main className="checkout">
      <Container>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <PlanDetails plan={plan} />
          </Grid>
          <Grid item xs={12} md={6}>
            <PaymentForm 
              plan={plan}
              onSuccess={() => setSuccess(true)}
              createSubscription={createSubscription}
              isMobile={isMobile}
            />
          </Grid>
        </Grid>

        {!success && (
          <Box sx={{ mt: 4, textAlign: 'center' }}>
            <Button variant="outlined" color="primary" component={Link} to="/all-plans">
              חזרה לתוכניות
            </Button>
          </Box>
        )}
      </Container>
    </main>
  );
};

export default Checkout;