// src/context/ThemeContextProvider.js

import React, { createContext, useState, useMemo, useEffect } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import getDesktopTheme from '../theme/themeDesktop';
import getMobileTheme from '../theme/themeMobile';

// Create the ThemeContext with default values
export const ThemeContext = createContext({
  mode: 'light',
  highContrast: false,
  fontSize: 16,
  reduceMotion: false,
  dyslexiaFont: false,
  colorBlindMode: 'none', // options: 'none', 'protanopia', 'deuteranopia', 'tritanopia'
  textToSpeech: false,
  toggleTheme: () => {},
  toggleHighContrast: () => {},
  increaseFontSize: () => {},
  decreaseFontSize: () => {},
  toggleReduceMotion: () => {},
  toggleDyslexiaFont: () => {},
  setColorBlindMode: () => {},
  toggleTextToSpeech: () => {},
});

/**
 * ThemeContextProvider manages and provides accessibility settings to the application.
 * @param {object} props - React props.
 * @param {React.ReactNode} props.children - Child components.
 * @returns {JSX.Element} - ThemeContext provider wrapping children.
 */
const ThemeContextProvider = ({ children }) => {
  // Define minimum and maximum font sizes
  const MIN_FONT_SIZE = 8;
  const MAX_FONT_SIZE = 32;

  // Initialize states from localStorage or defaults with validation
  const [mode, setMode] = useState(() => localStorage.getItem('mode') || 'light');
  const [highContrast, setHighContrast] = useState(() => localStorage.getItem('highContrast') === 'true');
  
  const [fontSize, setFontSize] = useState(() => {
    const savedFontSize = parseInt(localStorage.getItem('fontSize'), 10);
    if (isNaN(savedFontSize) || savedFontSize < MIN_FONT_SIZE || savedFontSize > MAX_FONT_SIZE) {
      return 12; // Default font size
    }
    return savedFontSize;
  });

  const [reduceMotion, setReduceMotion] = useState(() => localStorage.getItem('reduceMotion') === 'true');
  const [dyslexiaFont, setDyslexiaFont] = useState(() => localStorage.getItem('dyslexiaFont') === 'true');
  const [colorBlindMode, setColorBlindMode] = useState(() => localStorage.getItem('colorBlindMode') || 'none');
  const [textToSpeech, setTextToSpeech] = useState(() => localStorage.getItem('textToSpeech') === 'true');

  // Persist states to localStorage
  useEffect(() => {
    localStorage.setItem('mode', mode);
  }, [mode]);

  useEffect(() => {
    localStorage.setItem('highContrast', highContrast);
  }, [highContrast]);

  useEffect(() => {
    localStorage.setItem('fontSize', fontSize);
  }, [fontSize]);

  useEffect(() => {
    localStorage.setItem('reduceMotion', reduceMotion);
  }, [reduceMotion]);

  useEffect(() => {
    localStorage.setItem('dyslexiaFont', dyslexiaFont);
  }, [dyslexiaFont]);

  useEffect(() => {
    localStorage.setItem('colorBlindMode', colorBlindMode);
  }, [colorBlindMode]);

  useEffect(() => {
    localStorage.setItem('textToSpeech', textToSpeech);
  }, [textToSpeech]);

  // Handlers for toggling features
  const toggleTheme = () => {
    setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
  };

  const toggleHighContrast = () => {
    setHighContrast((prev) => !prev);
  };

  const increaseFontSize = () => {
    setFontSize((prevSize) => {
      const newSize = prevSize + 2;
      return newSize <= MAX_FONT_SIZE ? newSize : prevSize;
    });
  };

  const decreaseFontSize = () => {
    setFontSize((prevSize) => {
      const newSize = prevSize - 2;
      return newSize >= MIN_FONT_SIZE ? newSize : prevSize;
    });
  };

  const toggleReduceMotion = () => {
    setReduceMotion((prev) => !prev);
  };

  const toggleDyslexiaFont = () => {
    setDyslexiaFont((prev) => !prev);
  };

  const handleSetColorBlindMode = (mode) => {
    setColorBlindMode(mode);
  };

  const toggleTextToSpeech = () => {
    setTextToSpeech((prev) => !prev);
  };

  // Detect if the device is mobile (width <= 600px)
  const isMobile = useMediaQuery('(max-width:600px)');

  // Create theme based on current settings
  const theme = useMemo(() => {
    const settings = {
      mode,
      highContrast,
      fontSize,
      reduceMotion,
      dyslexiaFont,
      colorBlindMode,
    };
    return isMobile ? getMobileTheme(settings) : getDesktopTheme(settings);
  }, [isMobile, mode, highContrast, fontSize, reduceMotion, dyslexiaFont, colorBlindMode]);

  // Update document body font size
  useEffect(() => {
    document.body.style.fontSize = `${fontSize}px`;
  }, [fontSize]);

  // Update document body classes for reduce motion
  useEffect(() => {
    if (reduceMotion) {
      document.body.classList.add('reduce-motion');
    } else {
      document.body.classList.remove('reduce-motion');
    }
  }, [reduceMotion]);

  // Update document body classes for dyslexia-friendly font
  useEffect(() => {
    if (dyslexiaFont) {
      document.body.classList.add('dyslexia-font');
    } else {
      document.body.classList.remove('dyslexia-font');
    }
  }, [dyslexiaFont]);

  // Provide context values
  const contextValue = useMemo(
    () => ({
      mode,
      highContrast,
      fontSize,
      reduceMotion,
      dyslexiaFont,
      colorBlindMode,
      textToSpeech,
      toggleTheme,
      toggleHighContrast,
      increaseFontSize,
      decreaseFontSize,
      toggleReduceMotion,
      toggleDyslexiaFont,
      setColorBlindMode: handleSetColorBlindMode,
      toggleTextToSpeech,
    }),
    [
      mode,
      highContrast,
      fontSize,
      reduceMotion,
      dyslexiaFont,
      colorBlindMode,
      textToSpeech,
    ]
  );

  return (
    <ThemeContext.Provider value={contextValue}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ThemeContext.Provider>
  );
};

export default ThemeContextProvider;
