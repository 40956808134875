// src/components/Checkout/PaymentForm/index.js
import React, { useState } from 'react';
import { 
  Paper, Typography, Box, Alert, 
  Grow, Fade, Zoom,
  Card, CardContent
} from '@mui/material';
import PaymentIcon from '@mui/icons-material/Payment';
import SecurityIcon from '@mui/icons-material/Security';
import LockIcon from '@mui/icons-material/Lock';
import { PaymentFormFields } from './PaymentFormFields';
import { validatePaymentForm } from './validations';
import { 
  StyledPaper,
  SecurityBadge, 
  PriceCard,
  SecurityInfo 
} from './styled';

const INITIAL_FORM_STATE = {
  cardNumber: '',
  expiryDate: '',
  cvv: '',
  cardHolder: '',
};

export const PaymentForm = ({ plan, onSuccess, createSubscription, isMobile }) => {
  const [formData, setFormData] = useState(INITIAL_FORM_STATE);
  const [formError, setFormError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  const finalPrice = plan.disPrice && plan.disPrice < plan.price ? plan.disPrice : plan.price;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
    setFormError(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const error = validatePaymentForm(formData);
    if (error) {
      setFormError(error);
      return;
    }

    setIsProcessing(true);
    try {
      await createSubscription(plan);
      setSuccess(true);
      onSuccess();
    } catch (err) {
      console.error('שגיאה בעיבוד התשלום:', err);
      setFormError('אירעה שגיאה בלתי צפויה בעת עיבוד התשלום.');
    } finally {
      setIsProcessing(false);
    }
  };

  const renderHeader = () => (
    <Box sx={{ mb: 3, textAlign: 'center' }}>
      <Typography variant="h5" gutterBottom color="primary">
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 1 }}>
          <PaymentIcon />
          פרטי תשלום
        </Box>
      </Typography>
      <Typography variant="body2" color="text.secondary">
        כל העסקאות מאובטחות ומוצפנות
      </Typography>
    </Box>
  );

  const renderPrice = () => (
    <Fade in timeout={1000}>
      <PriceCard>
        <CardContent>
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'space-between',
            alignItems: 'center'
          }}>
            <Typography variant="subtitle1" color="text.secondary">
              סכום לתשלום
            </Typography>
            <Typography variant="h4" color="primary" fontWeight="bold">
              ₪{finalPrice.toLocaleString()}
            </Typography>
          </Box>
        </CardContent>
      </PriceCard>
    </Fade>
  );

  const renderError = () => (
    <Fade in={!!formError}>
      <Box sx={{ mb: 2 }}>
        {formError && (
          <Alert severity="error" variant="filled">
            {formError}
          </Alert>
        )}
      </Box>
    </Fade>
  );

  const renderSuccess = () => (
    <Fade in={success}>
      <Box>
        {success && (
          <Alert severity="success" variant="filled" sx={{ mb: 2 }}>
            <Typography variant="subtitle1" gutterBottom>
              תודה! המנוי שלך נוצר בהצלחה
            </Typography>
            <Typography variant="body2">
              סכום ששולם: ₪{finalPrice.toLocaleString()}. תקבל אישור בקרוב.
            </Typography>
          </Alert>
        )}
      </Box>
    </Fade>
  );

  return (
    <Grow in timeout={800}>
      <StyledPaper elevation={3}>
        <SecurityBadge>
          <SecurityIcon fontSize="small" />
          <Typography variant="body2" fontWeight="medium">
            תשלום מאובטח
          </Typography>
        </SecurityBadge>

        {renderHeader()}
        {renderPrice()}
        {renderError()}
        {renderSuccess()}

        {!success && (
          <Fade in={!success} timeout={500}>
            <Box>
              <PaymentFormFields
                formData={formData}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                isMobile={isMobile}
                isProcessing={isProcessing}
              />
              <SecurityInfo>
                <LockIcon fontSize="small" />
                <Typography variant="caption">
                  המידע שלך מאובטח ומוצפן בתקן PCI DSS
                </Typography>
              </SecurityInfo>
            </Box>
          </Fade>
        )}
      </StyledPaper>
    </Grow>
  );
};
export default PaymentForm;