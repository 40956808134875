import React from 'react';
import { Box, Alert, Button, Container, Typography } from '@mui/material';
import { Refresh as RefreshIcon } from '@mui/icons-material';

const ErrorState = ({ 
  error, 
  onRetry,
  title = 'משהו השתבש',
  showRetry = true 
}) => (
  <Container>
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '400px',
        gap: 3
      }}
    >
      <Typography variant="h5" gutterBottom>
        {title}
      </Typography>
      <Alert 
        severity="error" 
        sx={{ 
          width: '100%', 
          maxWidth: 500,
          '& .MuiAlert-message': {
            width: '100%'
          }
        }}
      >
        {error}
        {showRetry && onRetry && (
          <Box sx={{ mt: 2, textAlign: 'right' }}>
            <Button
              startIcon={<RefreshIcon />}
              onClick={onRetry}
              color="error"
              variant="outlined"
              size="small"
            >
              נסה שוב
            </Button>
          </Box>
        )}
      </Alert>
    </Box>
  </Container>
);

export default ErrorState;
