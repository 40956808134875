import React from 'react';
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { Person as PersonIcon } from '@mui/icons-material';
import { SignedIn, SignedOut, SignInButton, UserButton } from '@clerk/clerk-react';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';

const UserMenu = ({ onMenuClose }) => {
  const theme = useTheme();

  return (
    <List>
      <SignedOut>
        <SignInButton>
          <ListItem button onClick={onMenuClose}>
            <ListItemIcon sx={{ color: theme.palette.text.primary }}>
              <PersonIcon />
            </ListItemIcon>
            <ListItemText
              primary="התחבר"
              sx={{ color: theme.palette.text.primary }}
            />
          </ListItem>
        </SignInButton>
      </SignedOut>
      <SignedIn>
        <ListItem
          button
          component={Link}
          to="/profile"
          onClick={onMenuClose}
        >
          <ListItemIcon sx={{ color: theme.palette.text.primary }}>
            <UserButton />
          </ListItemIcon>
          <ListItemText
            primary="הפרופיל שלי"
            sx={{ color: theme.palette.text.primary }}
          />
        </ListItem>
      </SignedIn>
    </List>
  );
};

export default UserMenu;
