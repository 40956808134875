import { Event } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";

// src/components/Courses/CourseScheduleDisplay.js
export const CourseScheduleDisplay = ({ schedule }) => {
    const daysHebrew = {
      'Sunday': 'ראשון',
      'Monday': 'שני',
      'Tuesday': 'שלישי',
      'Wednesday': 'רביעי',
      'Thursday': 'חמישי',
      'Friday': 'שישי',
      'Saturday': 'שבת'
    };
  
    return (
      <Box sx={{ mt: 2 }}>
        {schedule?.map((slot, index) => (
          <Typography
            key={index}
            variant="body2"
            color="text.secondary"
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              mb: 0.5,
              fontSize: '0.875rem'
            }}
          >
            <Event fontSize="small" />
            יום {daysHebrew[slot.day]} | {slot.startTime} - {slot.endTime}
          </Typography>
        ))}
      </Box>
    );
  };
  