import React from 'react';
import { Grid, Typography, Chip, Box } from '@mui/material';
import { PlayCircle, Schedule, Check } from '@mui/icons-material';
import { CourseCard } from './styles';

const CourseProgress = ({ progress }) => (
  <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', gap: 1 }}>
    <Box
      sx={{
        flexGrow: 1,
        height: 4,
        bgcolor: 'background.default',
        borderRadius: 2,
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          width: `${progress}%`,
          height: '100%',
          bgcolor: 'primary.main',
          transition: 'width 0.5s ease-in-out',
        }}
      />
    </Box>
    <Typography variant="caption" sx={{ minWidth: 40 }}>
      {progress}%
    </Typography>
  </Box>
);

const EnrolledCourses = ({ courses }) => {
  if (!courses?.length) {
    return (
      <Typography variant="subtitle1" color="text.secondary" sx={{ textAlign: 'center', mt: 2 }}>
        אתה לא רשום כרגע לקורסים.
      </Typography>
    );
  }

  return (
    <Grid container spacing={3}>
      {courses.map((course) => (
        <Grid item xs={12} sm={6} md={4} key={course._id}>
          <CourseCard>
            <Box sx={{ mb: 2, display: 'flex', gap: 1, flexWrap: 'wrap' }}>
              {course.isLive && (
                <Chip
                  icon={<PlayCircle />}
                  label="שידור חי"
                  color="primary"
                  size="small"
                />
              )}
              {course.completed && (
                <Chip
                  icon={<Check />}
                  label="הושלם"
                  color="success"
                  size="small"
                />
              )}
              {course.duration && (
                <Chip
                  icon={<Schedule />}
                  label={`${course.duration} דקות`}
                  variant="outlined"
                  size="small"
                />
              )}
            </Box>

            <Typography variant="h6" gutterBottom>
              {course.title}
            </Typography>
            
            <Typography variant="body2" color="text.secondary" sx={{ mb: 2, flexGrow: 1 }}>
              {course.description}
            </Typography>

            <CourseProgress progress={course.progress || 0} />
          </CourseCard>
        </Grid>
      ))}
    </Grid>
  );
};

export default EnrolledCourses;
