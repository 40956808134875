import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Typography,
  IconButton,
  TextField,
  Button,
  useMediaQuery,
  Paper,
  Collapse,
  Fade,
  Tooltip,
} from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import {
  Edit as EditIcon,
  Save as SaveIcon,
  Cancel as CancelIcon,
  CheckCircle as CheckCircleIcon,
} from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';

const ValueContainer = styled(Paper)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(2),
  transition: theme.transitions.create(['background-color', 'box-shadow']),
  backgroundColor: alpha(theme.palette.background.paper, 0.8),
  backdropFilter: 'blur(8px)',
  '&:hover': {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[2],
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:hover': {
      backgroundColor: alpha(theme.palette.background.paper, 0.9),
    },
    '&.Mui-focused': {
      boxShadow: `0 0 0 2px ${alpha(theme.palette.primary.main, 0.2)}`,
    },
  },
}));

const ActionButton = styled(Button)(({ theme }) => ({
  borderRadius: theme.spacing(1),
  padding: theme.spacing(1, 3),
  transition: theme.transitions.create([
    'background-color',
    'box-shadow',
    'transform',
  ]),
  '&:hover': {
    transform: 'translateY(-1px)',
    boxShadow: theme.shadows[4],
  },
  '&:active': {
    transform: 'translateY(0)',
  },
}));

const EditField = ({
  label,
  value,
  onSave,
  required,
  type = 'text',
  validation,
  placeholder,
  helperText,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [isEditing, setIsEditing] = useState(false);
  const [currentValue, setCurrentValue] = useState(value || '');
  const [error, setError] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);

  const handleSave = async () => {
    if (required && !currentValue.trim()) {
      setError('שדה זה הוא חובה');
      return;
    }

    if (validation) {
      const validationError = validation(currentValue);
      if (validationError) {
        setError(validationError);
        return;
      }
    }

    try {
      await onSave(currentValue);
      setIsSuccess(true);
      setTimeout(() => {
        setIsEditing(false);
        setIsSuccess(false);
      }, 1000);
      setError('');
    } catch (err) {
      setError(err.message || 'שגיאה בעדכון השדה.');
    }
  };

  const handleCancel = () => {
    setCurrentValue(value || '');
    setIsEditing(false);
    setError('');
  };

  return (
    <Box sx={{ mb: 3 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: 500,
            color: theme.palette.text.primary,
          }}
        >
          {label}
          {required && (
            <Box
              component="span"
              sx={{
                color: theme.palette.error.main,
                ml: 0.5,
                animation: 'pulse 2s infinite',
                '@keyframes pulse': {
                  '0%': { opacity: 1 },
                  '50%': { opacity: 0.6 },
                  '100%': { opacity: 1 },
                },
              }}
            >
              *
            </Box>
          )}
        </Typography>
        {helperText && (
          <Typography
            variant="caption"
            sx={{ ml: 1, color: theme.palette.text.secondary }}
          >
            ({helperText})
          </Typography>
        )}
      </Box>

      <Collapse in={!isEditing}>
        <ValueContainer elevation={1}>
          <Typography
            variant="body1"
            sx={{
              minHeight: 24,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {value || (
              <Box sx={{ color: theme.palette.error.light, fontStyle: 'italic' }}>
                לא הוגדר
              </Box>
            )}
          </Typography>
          <Tooltip title={`ערוך ${label}`}>
            <IconButton
              onClick={() => setIsEditing(true)}
              size="small"
              sx={{
                color: theme.palette.primary.main,
                '&:hover': {
                  backgroundColor: alpha(theme.palette.primary.main, 0.1),
                  transform: 'scale(1.1)',
                },
                transition: theme.transitions.create(['transform', 'background-color']),
              }}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
        </ValueContainer>
      </Collapse>

      <Collapse in={isEditing}>
        <Box>
          <StyledTextField
            variant="outlined"
            fullWidth
            type={type}
            value={currentValue}
            onChange={(e) => setCurrentValue(e.target.value)}
            error={Boolean(error)}
            helperText={error}
            placeholder={placeholder}
            autoFocus
            InputProps={{
              sx: {
                fontSize: isMobile ? '16px' : 'inherit',
                backgroundColor: theme.palette.background.paper,
              },
            }}
          />
          <Box
            sx={{
              mt: 2,
              display: 'flex',
              gap: 1.5,
              flexDirection: isMobile ? 'column' : 'row',
            }}
          >
            <Fade in={isSuccess}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  color: theme.palette.success.main,
                }}
              >
                <CheckCircleIcon sx={{ mr: 1 }} />
                <Typography>נשמר בהצלחה!</Typography>
              </Box>
            </Fade>
            <ActionButton
              variant="contained"
              color="primary"
              startIcon={<SaveIcon />}
              onClick={handleSave}
              fullWidth={isMobile}
              disabled={isSuccess}
            >
              שמור
            </ActionButton>
            <ActionButton
              variant="outlined"
              color="inherit"
              startIcon={<CancelIcon />}
              onClick={handleCancel}
              fullWidth={isMobile}
              disabled={isSuccess}
            >
              בטל
            </ActionButton>
          </Box>
        </Box>
      </Collapse>
    </Box>
  );
};

EditField.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  onSave: PropTypes.func.isRequired,
  required: PropTypes.bool,
  type: PropTypes.string,
  validation: PropTypes.func,
  placeholder: PropTypes.string,
  helperText: PropTypes.string,
};

export default EditField;