import React from 'react';
import { Box } from '@mui/material';
import { CheckCircle, Warning, Payment } from '@mui/icons-material';
import { StatusChip, PaymentStatusChip } from './styles';

const SubscriptionStatus = ({ subscription }) => (
  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
    <StatusChip
      status={subscription.status}
      label={subscription.status}
      icon={subscription.status === 'Active' ? <CheckCircle /> : <Warning />}
    />
    {/* <PaymentStatusChip
      status={subscription.paymentStatus}
      label={subscription.paymentStatus}
      icon={<Payment />}
    /> */}
  </Box>
);

export default SubscriptionStatus;
