import React, { useState } from 'react';
import {
  Box,
  IconButton,
  Typography,
  Tabs,
  Tab,
  Link,
  Paper,
  Drawer,
  Fade,
  Tooltip,
  Badge,
} from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import {
  OpenInNew as OpenInNewIcon,
  ChevronRight as ChevronRightIcon,
  Calculate as CalculateIcon,
  Functions as FunctionsIcon,
  Psychology as PsychologyIcon,
} from '@mui/icons-material';

const DrawerButton = styled(IconButton)(({ theme }) => ({
  position: 'fixed',
  right: 0,
  top: '20%',
  transform: 'translateY(-50%)',
  zIndex: theme.zIndex.drawer - 1,
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.common.white,
  padding: theme.spacing(1.5),
  borderRadius: '12px 0 0 12px',
  boxShadow: `0 4px 20px ${alpha(theme.palette.secondary.main, 0.25)}`,
  transition: theme.transitions.create(['transform', 'background-color', 'box-shadow'], {
    duration: 200,
  }),
  '&:hover': {
    backgroundColor: theme.palette.secondary.dark,
    transform: 'translateY(-50%) translateX(-4px)',
    boxShadow: `0 6px 25px ${alpha(theme.palette.secondary.main, 0.35)}`,
  },
  '& .MuiSvgIcon-root': {
    fontSize: 28,
  },
}));

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    width: 400,
    background: alpha(theme.palette.background.paper, 0.95),
    backdropFilter: 'blur(10px)',
    borderLeft: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
  },
}));

const ContentBox = styled(Box)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  textTransform: 'none',
  fontSize: '1rem',
  fontWeight: 600,
  minHeight: 64,
  '&.Mui-selected': {
    color: theme.palette.secondary.main,
  },
}));

const FormulaCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: theme.spacing(2),
  transition: theme.transitions.create(['transform', 'box-shadow']),
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: theme.shadows[8],
  },
}));

const FormulaBox = styled(Box)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.secondary.main, 0.05),
  padding: theme.spacing(2),
  borderRadius: theme.spacing(1),
  border: `1px solid ${alpha(theme.palette.secondary.main, 0.1)}`,
  marginBottom: theme.spacing(2),
  fontFamily: 'monospace',
  fontSize: '1.1rem',
  position: 'relative',
  overflow: 'auto',
  maxHeight: '200px',
}));

const TabPanel = ({ children, value, index }) => (
  <Fade in={value === index}>
    <Box
      hidden={value !== index}
      sx={{
        flexGrow: 1,
        overflow: 'auto',
        p: 3,
        display: value === index ? 'block' : 'none',
      }}
    >
      {value === index && children}
    </Box>
  </Fade>
);

const FormulasTheorems = ({ lessonFormulas = { title: '', description: '', formulas: [], theorems: [] } }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [tabValue, setTabValue] = useState(0);

  const formulasCount = lessonFormulas.formulas.length;
  const theoremsCount = lessonFormulas.theorems.length;

  return (
    <>
      <Tooltip title="נוסחאות ותיאוריות" placement="left" arrow>
        <DrawerButton onClick={() => setIsOpen(true)}>
          <Badge badgeContent={formulasCount + theoremsCount} color="error" max={99}>
            <CalculateIcon />
          </Badge>
        </DrawerButton>
      </Tooltip>

      <StyledDrawer
        anchor="right"
        open={isOpen}
        onClose={() => setIsOpen(false)}
        variant="persistent"
      >
        <ContentBox>
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            p: 3,
            borderBottom: '1px solid',
            borderColor: 'divider',
          }}>
            <IconButton 
              onClick={() => setIsOpen(false)}
              sx={{ 
                mr: 2,
                '&:hover': { 
                  transform: 'rotate(-180deg)',
                  transition: 'transform 0.3s'
                }
              }}
            >
              <ChevronRightIcon />
            </IconButton>
            <Box>
              <Typography variant="h6" fontWeight="bold">
                {lessonFormulas.title || 'נוסחאות ותיאוריות'}
              </Typography>
              {lessonFormulas.description && (
                <Typography variant="body2" color="text.secondary">
                  {lessonFormulas.description}
                </Typography>
              )}
            </Box>
          </Box>

          <Tabs
            value={tabValue}
            onChange={(_, newValue) => setTabValue(newValue)}
            variant="fullWidth"
            sx={{
              borderBottom: '1px solid',
              borderColor: 'divider',
              '& .MuiTabs-indicator': {
                height: 3,
                borderRadius: '3px 3px 0 0',
              },
            }}
          >
            <StyledTab 
              icon={<FunctionsIcon />} 
              iconPosition="start" 
              label={`נוסחאות (${formulasCount})`} 
            />
            <StyledTab 
              icon={<PsychologyIcon />} 
              iconPosition="start" 
              label={`תיאוריות (${theoremsCount})`} 
            />
          </Tabs>

          <TabPanel value={tabValue} index={0}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
              {lessonFormulas.formulas.map((formula, index) => (
                <FormulaCard key={index} elevation={2}>
                  <Typography variant="h6" gutterBottom fontWeight="bold">
                    נוסחה {index + 1}
                  </Typography>
                  <FormulaBox>
                    <Typography>{formula.formula}</Typography>
                  </FormulaBox>
                  <Link
                    href={formula.reel}
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 0.5,
                      textDecoration: 'none',
                      color: 'secondary.main',
                      fontWeight: 500,
                      '&:hover': {
                        textDecoration: 'underline',
                      },
                    }}
                  >
                    למידע נוסף
                    <OpenInNewIcon sx={{ fontSize: 16 }} />
                  </Link>
                </FormulaCard>
              ))}
            </Box>
          </TabPanel>

          <TabPanel value={tabValue} index={1}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
              {lessonFormulas.theorems.map((theorem, index) => (
                <FormulaCard key={index} elevation={2}>
                  <Typography variant="h6" gutterBottom fontWeight="bold">
                    תיאוריה {index + 1}
                  </Typography>
                  <FormulaBox>
                    <Typography>{theorem.theorem}</Typography>
                  </FormulaBox>
                  <Link
                    href={theorem.reel}
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 0.5,
                      textDecoration: 'none',
                      color: 'secondary.main',
                      fontWeight: 500,
                      '&:hover': {
                        textDecoration: 'underline',
                      },
                    }}
                  >
                    למידע נוסף
                    <OpenInNewIcon sx={{ fontSize: 16 }} />
                  </Link>
                </FormulaCard>
              ))}
            </Box>
          </TabPanel>
        </ContentBox>
      </StyledDrawer>
    </>
  );
};

export default FormulasTheorems;