// src/components/Courses/CourseCard.js
import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import { 
  Grid, 
  Typography, 
  Box, 
  Button, 
  CardContent, 
  Grow,
  Zoom,
  Fade,
  Chip,
  Tooltip,
  Paper
} from '@mui/material';
import { useTheme, alpha } from '@mui/material/styles';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LockIcon from '@mui/icons-material/Lock';
import LiveTvIcon from '@mui/icons-material/LiveTv';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import ScheduleIcon from '@mui/icons-material/Schedule';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import IconDisplay from '../../Icon/IconDisplay';
import { StyledCard } from './styles';

// מילון תרגום ימים
const daysTranslation = {
  'Sunday': 'ראשון',
  'Monday': 'שני',
  'Tuesday': 'שלישי',
  'Wednesday': 'רביעי',
  'Thursday': 'חמישי',
  'Friday': 'שישי',
  'Saturday': 'שבת'
};

const enhancedStyles = {
  card: {
    position: 'relative',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
    '&:hover': {
      transform: 'translateY(-8px)',
    },
  },
  cardMedia: {
    position: 'relative',
    backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.1),
    padding: (theme) => theme.spacing(3),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    borderBottom: '1px solid',
    borderColor: 'divider',
  },
  badge: {
    position: 'absolute',
    zIndex: 2,
    transition: 'transform 0.3s ease',
    '&:hover': {
      transform: 'scale(1.1)',
    },
  },
  typeBadge: {
    top: 16,
    left: 16,
  },
  accessBadge: {
    top: 16,
    right: 16,
  },
  infoContainer: {
    display: 'flex',
    gap: 1,
    justifyContent: 'center',
    padding: (theme) => theme.spacing(2),
    borderTop: '1px solid',
    borderColor: 'divider',
  },
  scheduleContainer: {
    padding: (theme) => theme.spacing(2),
    backgroundColor: (theme) => alpha(theme.palette.background.paper, 0.6),
  },
  scheduleHeader: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
    marginBottom: (theme) => theme.spacing(2),
    padding: (theme) => theme.spacing(1),
    backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.1),
    borderRadius: (theme) => theme.shape.borderRadius,
  },
  scheduleContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: (theme) => theme.spacing(1.5),
  },
  scheduleDay: {
    display: 'flex',
    flexDirection: 'column',
    padding: (theme) => theme.spacing(1.5),
    backgroundColor: (theme) => alpha(theme.palette.background.paper, 0.9),
    borderRadius: (theme) => theme.shape.borderRadius,
    boxShadow: (theme) => `0 2px 4px ${alpha(theme.palette.common.black, 0.05)}`,
    transition: 'all 0.2s ease',
    '&:hover': {
      backgroundColor: (theme) => alpha(theme.palette.primary.light, 0.1),
      transform: 'translateX(-4px)',
    },
  },
  dayRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  timeRow: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
    marginTop: (theme) => theme.spacing(1),
    color: 'text.secondary',
  },
  actionButton: (type) => ({
    borderRadius: '50px',
    textTransform: 'none',
    transition: 'all 0.3s ease',
    fontWeight: 600,
    py: 1.5,
    ...(type === 'details' && {
      '&:hover': {
        transform: 'translateX(-4px)',
      },
    }),
    ...(type === 'plan' && {
      '&:hover': {
        transform: 'translateX(4px)',
        boxShadow: (theme) => `0 4px 12px ${alpha(theme.palette.primary.main, 0.2)}`,
      },
    }),
  }),
};

const CourseCard = memo(({ course, userHasAccess, handleSelectPlan }) => {
  const theme = useTheme();

  // פונקציה להמרת פורמט השעה ל-24 שעות
const formatTime = (time12) => {
    const [time, period] = time12.split(' ');
    let [hours, minutes] = time.split(':');
    hours = parseInt(hours, 10);
  
    if (period === 'PM' && hours < 12) {
      hours += 12;
    }
    if (period === 'AM' && hours === 12) {
      hours = 0;
    }
  
    return `${hours.toString().padStart(2, '0')}:${minutes}`;
  };

  // מיון הימים לפי סדר השבוע
  const sortedSchedule = course.schedule?.sort((a, b) => {
    const daysOrder = {
      'Sunday': 1, 'Monday': 2, 'Tuesday': 3, 'Wednesday': 4,
      'Thursday': 5, 'Friday': 6, 'Saturday': 7
    };
    return daysOrder[a.day] - daysOrder[b.day];
  });

  return (
    <Grid item xs={12} sm={6} md={4}>
      <Grow in={true} timeout={600}>
        <StyledCard sx={enhancedStyles.card}>
          {/* Course Type Badge */}
          <Zoom in={true} timeout={800}>
            <Box sx={{ ...enhancedStyles.badge, ...enhancedStyles.typeBadge }}>
              <Chip
                icon={course.isLive ? <LiveTvIcon /> : <VideoLibraryIcon />}
                label={course.isLive ? 'שיעור חי' : 'מוקלט'}
                color={course.isLive ? 'primary' : 'default'}
                variant="filled"
                size="small"
              />
            </Box>
          </Zoom>

          {/* Access Badge */}
          <Zoom in={true} timeout={1000}>
            <Box sx={{ ...enhancedStyles.badge, ...enhancedStyles.accessBadge }}>
              <Tooltip title={userHasAccess ? 'יש לך גישה לקורס' : 'אין לך גישה לקורס'}>
                <Chip
                  icon={userHasAccess ? <LockOpenIcon /> : <LockIcon />}
                  label={userHasAccess ? 'יש גישה' : 'אין גישה'}
                  color={userHasAccess ? 'success' : 'error'}
                  variant="filled"
                  size="small"
                />
              </Tooltip>
            </Box>
          </Zoom>

          {/* Course Icon */}
          <Box sx={enhancedStyles.cardMedia}>
            <Fade in={true} timeout={1200}>
              <Box
                sx={{
                  transform: 'scale(1.2)',
                  transition: 'transform 0.3s ease',
                  '&:hover': {
                    transform: 'scale(1.3)',
                  },
                }}
              >
                <IconDisplay 
                  iconName={course.icon} 
                  size={80} 
                  color="primary.main"
                />
              </Box>
            </Fade>
          </Box>

          {/* Course Basic Info */}
          <CardContent sx={{ flexGrow: 1, textAlign: 'center' }}>
            <Fade in={true} timeout={1400}>
              <Typography
                variant="h5"
                component="h2"
                gutterBottom
                sx={{
                  fontSize: { xs: '1.2rem', sm: '1.4rem', md: '1.6rem' },
                  fontWeight: 600,
                  color: 'text.primary',
                }}
              >
                {course.title}
              </Typography>
            </Fade>
            
            <Fade in={true} timeout={1600}>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{
                  fontSize: { xs: '0.9rem', sm: '1rem' },
                  mb: 2,
                }}
              >
                {course.description.length > 100
                  ? `${course.description.substring(0, 100)}...`
                  : course.description}
              </Typography>
            </Fade>
          </CardContent>

          {/* Course Duration */}
          <Fade in={true} timeout={1800}>
            <Box sx={enhancedStyles.infoContainer}>
              <Chip
                icon={<AccessTimeIcon />}
                label={`${course.duration} שעות`}
                variant="outlined"
                size="small"
                color="primary"
              />
            </Box>
          </Fade>

          {/* Schedule Display */}
          {course.isLive && sortedSchedule?.length > 0 && (
            <Fade in={true} timeout={2000}>
              <Box sx={enhancedStyles.scheduleContainer}>
                <Paper sx={enhancedStyles.scheduleHeader} elevation={0}>
                  <ScheduleIcon color="primary" />
                  <Typography variant="subtitle1" fontWeight="medium">
                    זמני שיעורים
                  </Typography>
                </Paper>

                <Box sx={enhancedStyles.scheduleContent}>
                  {sortedSchedule.map((schedule, index) => (
                    <Paper
                      key={index}
                      sx={enhancedStyles.scheduleDay}
                      elevation={0}
                    >
                      <Box sx={enhancedStyles.dayRow}>
                        <Typography variant="subtitle1" fontWeight="medium">
                          יום {daysTranslation[schedule.day]}
                        </Typography>
                      </Box>
                      <Box sx={enhancedStyles.timeRow}>
                        <QueryBuilderIcon fontSize="small" />
                        <Typography variant="body2">
                          {formatTime(schedule.startTime)} - {formatTime(schedule.endTime)}
                        </Typography>
                      </Box>
                    </Paper>
                  ))}
                </Box>
              </Box>
            </Fade>
          )}

          {/* Actions */}
          <Box sx={{ p: 2, gap: 1, display: 'flex' }}>
            {userHasAccess ? (
              <Fade in={true} timeout={2200}>
                <Button
                  variant="outlined"
                  color="info"
                  component={Link}
                  to={`/course/${course._id}/details`}
                  fullWidth
                  sx={enhancedStyles.actionButton('details')}
                >
                  פרטים
                </Button>
              </Fade>
            ) : (
              <>
                <Fade in={true} timeout={2200}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleSelectPlan(course)}
                    sx={enhancedStyles.actionButton('plan')}
                    fullWidth
                  >
                    הרשמה לקורס
                  </Button>
                </Fade>
                <Fade in={true} timeout={2400}>
                  <Button
                    variant="outlined"
                    color="info"
                    component={Link}
                    to={`/course/${course._id}/details`}
                    sx={enhancedStyles.actionButton('details')}
                    fullWidth
                  >
                    פרטים
                  </Button>
                </Fade>
              </>
            )}
          </Box>
        </StyledCard>
      </Grow>
    </Grid>
  );
});

export default CourseCard;