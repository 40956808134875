import React, { useContext, useEffect, memo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Box,
  Button,
  Alert,
  CircularProgress,
  Fade,
} from '@mui/material';
import { UserContext } from '../../../context/UserContext';
import { SectionHeader } from './styles';
import LessonsSection from './LessonsSection';

const MyLessons = () => {
  const navigate = useNavigate();
  const {
    isSignedIn,
    enrolledLessons,
    upcomingLessons,
    loading,
    error,
    enrollInLesson,
    fetchEnrolledAndUpcomingLessons,
  } = useContext(UserContext);

  useEffect(() => {
    fetchEnrolledAndUpcomingLessons();
  }, [fetchEnrolledAndUpcomingLessons]);

  const handleEnroll = async (lessonId) => {
    try {
      await enrollInLesson(lessonId);
      // התראת הצלחה יכולה להיות מטופלת בקונטקסט
    } catch (err) {
      console.error('Enrollment failed:', err);
    }
  };

  if (loading) {
    return (
      <Container sx={{ py: 8, textAlign: 'center' }}>
        <CircularProgress size={60} />
      </Container>
    );
  }

  if (error) {
    return (
      <Container sx={{ py: 8 }}>
        <Alert 
          severity="error"
          variant="filled"
          sx={{ maxWidth: 600, mx: 'auto' }}
        >
          {error}
        </Alert>
      </Container>
    );
  }

  if (!isSignedIn) {
    return (
      <Container sx={{ py: 8, textAlign: 'center' }}>
        <Alert
          severity="info"
          action={
            <Button 
              color="inherit" 
              size="small"
              onClick={() => navigate('/signin')}
            >
              התחבר
            </Button>
          }
          sx={{ maxWidth: 600, mx: 'auto', mb: 4 }}
        >
          עליך להתחבר כדי לצפות בשיעורים שלך
        </Alert>
      </Container>
    );
  }

  const hasNoLessons = !enrolledLessons?.length && !upcomingLessons?.length;

  return (
    <Fade in>
      <Container sx={{ py: 8 }}>
        <Box sx={{ textAlign: 'center', mb: 8 }}>
          <SectionHeader variant="h3">
            השיעורים שלי
          </SectionHeader>
        </Box>

        {hasNoLessons ? (
          <Alert
            severity="info"
            action={
              <Button 
                color="primary"
                variant="contained"
                onClick={() => navigate('/all-courses')}
              >
                דפדף בקורסים
              </Button>
            }
            sx={{ maxWidth: 600, mx: 'auto' }}
          >
            עדיין לא נרשמת לשיעורים
          </Alert>
        ) : (
          <>
            <LessonsSection
              title="השיעורים הרשומים"
              lessons={enrolledLessons}
              isEnrolled={true}
              emptyMessage="אין שיעורים רשומים כרגע."
            />

            <LessonsSection
              title="השיעורים הקרובים"
              lessons={upcomingLessons}
              isEnrolled={false}
              onEnroll={handleEnroll}
              emptyMessage="אין שיעורים קרובים."
            />
          </>
        )}
      </Container>
    </Fade>
  );
};

export default memo(MyLessons);
