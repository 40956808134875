import React from 'react';
import { Link } from 'react-router-dom';
import { Box, CardMedia } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const Logo = () => {
  const theme = useTheme();
  return (
    <Link
      to="/"
      style={{
        textDecoration: 'none',
        color: theme.palette.text.primary,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <CardMedia
        component="img"
        height={200} // Adjust height as needed
        image="logo.png"
        alt="Logo"
        sx={{ objectFit: 'contain' }}
      />
    </Link>
  );
};

export default Logo;
