import { alpha, Box, Button, Link, Typography } from "@mui/material";

// src/components/Courses/EmptyState.js
export const EmptyState = () => {
    return (
      <Box sx={{ textAlign: 'center' }}>
        <Typography variant="h6" color="text.secondary">
          אין לך קורסים רשומים כרגע.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          component={Link}
          to="/all-courses"
          sx={{
            mt: 2,
            textTransform: 'none',
            borderRadius: '50px',
            py: 1.5,
            px: 4,
            fontWeight: 600,
            transition: 'all 0.3s ease',
            '&:hover': {
              transform: 'translateY(-2px)',
              boxShadow: (theme) => `0 4px 12px ${alpha(theme.palette.primary.main, 0.3)}`,
            },
          }}
        >
          גלה קורסים חדשים
        </Button>
      </Box>
    );
  };