// src/components/Course/CourseDetails/CourseDetails.js
import React, { useContext, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  Container,
  CircularProgress,
  Alert,
  Divider,
  Button,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { DataContext } from '../../../context/DataContext';
import { UserContext } from '../../../context/UserContext';
import CourseHeader from './CourseHeader';
import CourseUnits from './CourseUnits';

const CourseDetails = () => {
  const theme = useTheme();
  const { courseId } = useParams();
  const navigate = useNavigate();
  const { courses, lessons, loading: dataLoading, error: dataError } = useContext(DataContext);
  const { isSignedIn, loading: userLoading, error: userError, hasAccess } = useContext(UserContext);

  const [course, setCourse] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setLocalError] = useState(null);
  const [userHasAccess, setUserHasAccess] = useState(false);

  // Fetch course effect
  useEffect(() => {
    const fetchCourse = async () => {
      setLoading(true);
      setLocalError(null);
      try {
        const selectedCourse = courses.find((c) => c._id === courseId);
        if (selectedCourse) {
          setCourse(selectedCourse);
        } else {
          const response = await fetch(`${process.env.REACT_APP_INFINITY_BACK_URL}/api/courses/${courseId}`);
          if (response.ok) {
            const data = await response.json();
            setCourse(data);
          } else {
            throw new Error(response.status === 404 ? 'הקורס לא נמצא.' : 'התרחשה שגיאה בעת שליפת פרטי הקורס.');
          }
        }
      } catch (err) {
        console.error('Error fetching course details:', err);
        setLocalError(err.message || 'התרחשה שגיאה בלתי צפויה בעת שליפת פרטי הקורס.');
      } finally {
        setLoading(false);
      }
    };

    fetchCourse();
  }, [courseId, courses]);

  // Check user access effect
  useEffect(() => {
    const checkAccess = async () => {
      if (isSignedIn && courseId) {
        const access = await hasAccess('course', courseId);
        setUserHasAccess(access);
      } else {
        setUserHasAccess(false);
      }
    };

    checkAccess();
  }, [isSignedIn, courseId, hasAccess]);

  if (dataLoading || loading || userLoading) {
    return (
      <Container>
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  if (dataError || userError || error) {
    return (
      <Container>
        <Alert severity="error">{dataError || userError || error}</Alert>
      </Container>
    );
  }

  if (!course) return null;

  return (
    <main className="course-details">
      <Container dir="rtl">
        <CourseHeader course={course} userHasAccess={userHasAccess} />
        
        <Divider sx={{ mb: 4 }} />
        
        <CourseUnits units={course.units} lessons={lessons} />

        {userError && (
          <Box sx={{ mt: 4, textAlign: 'center' }}>
            <Alert severity="error">{userError}</Alert>
          </Box>
        )}

        <Box sx={{ mt: 8, textAlign: 'center' }}>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => navigate('/all-courses')}
            sx={{
              textTransform: 'none',
              borderRadius: '50px',
              borderColor: 'primary.main',
              color: 'primary.main',
              boxShadow: theme.shadows[2],
              transition: 'all 0.3s ease',
              '&:hover': {
                backgroundColor: 'primary.main',
                color: 'common.white',
                transform: 'translateY(-2px)',
              },
            }}
          >
            חזרה לקורסים
          </Button>
        </Box>
      </Container>
    </main>
  );
};

export default CourseDetails;
