import React from 'react';
import { BottomNavigation, BottomNavigationAction, Box } from '@mui/material';
import {
  Menu as MenuIcon,
  Person as PersonIcon,
  LibraryBooks as LibraryBooksIcon,
  School as SchoolIcon,
  MenuBook as MenuBookIcon,
} from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { SignedIn, SignedOut, SignInButton } from '@clerk/clerk-react';

const BottomNav = ({
  value,
  onChange,
  onMenuOpen,
  isSignedIn,
}) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: 0,
        width: '100%',
        boxShadow: '0 -2px 10px rgba(0, 0, 0, 0.1)',
        zIndex: theme.zIndex.drawer + 1,
        backgroundColor: theme.palette.background.paper,
      }}
    >
      <BottomNavigation
        value={value}
        onChange={onChange}
        showLabels
        sx={{
          display: 'flex',
          minHeight: '72px',
          maxHeight: '88px',
        }}
      >
        {/* Menu Button */}
        <BottomNavigationAction
          label="תפריט"
          icon={<MenuIcon />}
          onClick={onMenuOpen}
          sx={{
            mt: 1,
            alignSelf: 'flex-start',
          }}
        />
        {/* Profile */}
        {isSignedIn ? (
          <BottomNavigationAction
            label="פרופיל"
            icon={<PersonIcon />}
            sx={{
              mt: 1,
              alignSelf: 'flex-start',
            }}
            component={Link}
            to="/profile"
          />
        ) : (
          <SignInButton>
            <BottomNavigationAction
              label="התחבר"
              icon={<PersonIcon />}
              sx={{
                mt: 1,
                alignSelf: 'flex-start',
              }}
            />
          </SignInButton>
        )}
        {/* My Courses */}
        {isSignedIn && (
          <BottomNavigationAction
            label="הקורסים שלי"
            icon={<LibraryBooksIcon />}
            sx={{ mt: 1, alignSelf: 'flex-start' }}
            component={Link}
            to="/my-courses"
          />
        )}
        {/* My Lessons */}
        {isSignedIn && (
          <BottomNavigationAction
            label="השיעורים שלי"
            icon={<SchoolIcon />}
            sx={{ mt: 1, alignSelf: 'flex-start' }}
            component={Link}
            to="/my-lessons"
          />
        )}
        {/* Courses */}
        <BottomNavigationAction
          label="קורסים"
          icon={<MenuBookIcon />}
          sx={{ mt: 1, alignSelf: 'flex-start' }}
          component={Link}
          to="/all-courses"
        />
      </BottomNavigation>
    </Box>
  );
};

export default BottomNav;
