import React from 'react';
import { 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions, 
  Button, 
  Box, 
  Typography, 
  IconButton,
  Chip,
  useTheme,
  alpha,
  Paper,
  Stack,
  Tooltip
} from '@mui/material';
import { 
  Close as CloseIcon,
  Event as EventIcon,
  AccessTime as TimeIcon,
  School as SchoolIcon,
  Assignment as HomeworkIcon,
  LiveTv as LiveIcon,
  Videocam as RecordIcon,
  Group as StudentsIcon,
  MenuBook as MaterialIcon,
  QueryStats as StatsIcon
} from '@mui/icons-material';
import { motion, AnimatePresence } from 'framer-motion';

const LessonsDialog = ({ plan, open, onClose }) => {
  const theme = useTheme();

  if (!plan) return null;

  const getStatusColor = (status) => {
    const colors = {
      scheduled: 'info',
      live: 'success',
      passed: 'default',
      canceled: 'error',
      bonus: 'warning',
      record: 'secondary'
    };
    return colors[status] || 'default';
  };

  const getStatusLabel = (status) => {
    const labels = {
      scheduled: 'מתוכנן',
      live: 'שידור חי',
      passed: 'הסתיים',
      canceled: 'בוטל',
      bonus: 'בונוס',
      record: 'הקלטה'
    };
    return labels[status] || status;
  };

  const formatDuration = (minutes) => {
    if (!minutes) return 'לא צוין';
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    if (hours === 0) return `${remainingMinutes} דקות`;
    return `${hours} שעות ו-${remainingMinutes} דקות`;
  };

  return (
    <Dialog 
      open={open} 
      onClose={onClose} 
      fullWidth 
      maxWidth="md"
      PaperProps={{
        sx: {
          borderRadius: 2,
          overflow: 'hidden'
        }
      }}
    >
      <DialogTitle sx={{ 
        p: 3,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: `1px solid ${theme.palette.divider}`
      }}>
        <Box component={motion.div}
          initial={{ opacity: 0, x: -10 }}
          animate={{ opacity: 1, x: 0 }}
          display="flex" 
          alignItems="center" 
          gap={2}
        >
          <SchoolIcon color="primary" />
          <Typography variant="h5" fontWeight="500">
            שיעורים בתוכנית: {plan.title}
          </Typography>
        </Box>
        <IconButton onClick={onClose} size="small">
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ p: 3 }}>
        <AnimatePresence>
          {plan.lessons?.length > 0 ? (
            <Stack spacing={2}>
              {plan.lessons.map((lesson, index) => (
                <Paper
                  component={motion.div}
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  key={lesson._id}
                  elevation={0}
                  sx={{ 
                    p: 3,
                    border: `1px solid ${theme.palette.divider}`,
                    borderRadius: 2,
                    '&:hover': {
                      bgcolor: alpha(theme.palette.primary.main, 0.02),
                      borderColor: alpha(theme.palette.primary.main, 0.2)
                    }
                  }}
                >
                  {/* Header Section */}
                  <Box display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
                    <Box>
                      <Box display="flex" alignItems="center" gap={1} mb={1}>
                        <Chip 
                          label={`שיעור ${index + 1}`}
                          size="small"
                          color="primary"
                        />
                        <Chip 
                          label={getStatusLabel(lesson.status)}
                          size="small"
                          color={getStatusColor(lesson.status)}
                        />
                      </Box>
                      <Typography variant="h6">
                        {lesson.title}
                      </Typography>
                    </Box>
                    
                    {/* Live/Record Indicators */}
                    <Box display="flex" gap={1}>
                      {lesson.isLive && (
                        <Tooltip title="שיעור חי">
                          <LiveIcon color="success" />
                        </Tooltip>
                      )}
                      {lesson.recordLink && (
                        <Tooltip title="הקלטה זמינה">
                          <RecordIcon color="primary" />
                        </Tooltip>
                      )}
                    </Box>
                  </Box>

                  {/* Details Grid */}
                  <Stack spacing={2}>
                    {/* Time & Duration */}
                    <Box display="flex" gap={3}>
                      <Box display="flex" alignItems="center" gap={1}>
                        <TimeIcon fontSize="small" color="action" />
                        <Typography variant="body2">
                          משך: {formatDuration(lesson.duration)}
                        </Typography>
                      </Box>
                      {lesson.maxStudents > 0 && (
                        <Box display="flex" alignItems="center" gap={1}>
                          <StudentsIcon fontSize="small" color="action" />
                          <Typography variant="body2">
                            מקסימום תלמידים: {lesson.maxStudents}
                          </Typography>
                        </Box>
                      )}
                    </Box>

                    {/* Study Materials */}
                    {lesson.mainStudyMaterial && (
                      <Box display="flex" alignItems="center" gap={1}>
                        <MaterialIcon fontSize="small" color="primary" />
                        <Typography variant="body2">
                          חומר לימוד עיקרי: {lesson.mainStudyMaterial.title}
                        </Typography>
                      </Box>
                    )}

                    {/* Homework Stats */}
                    {lesson.homeWork?.length > 0 && (
                      <Box display="flex" alignItems="center" gap={1}>
                        <HomeworkIcon fontSize="small" color="secondary" />
                        <Typography variant="body2">
                          שאלות לתרגול: {lesson.homeWork.length}
                        </Typography>
                      </Box>
                    )}

                    {/* Additional Materials Count */}
                    {lesson.supplementaryMaterials?.length > 0 && (
                      <Box display="flex" alignItems="center" gap={1}>
                        <StatsIcon fontSize="small" color="info" />
                        <Typography variant="body2">
                          חומרי עזר נוספים: {lesson.supplementaryMaterials.length}
                        </Typography>
                      </Box>
                    )}
                  </Stack>
                </Paper>
              ))}
            </Stack>
          ) : (
            <Typography 
              color="text.secondary"
              textAlign="center"
            >
              אין שיעורים כלולים בתוכנית זו.
            </Typography>
          )}
        </AnimatePresence>
      </DialogContent>

      <DialogActions sx={{ p: 2, borderTop: `1px solid ${theme.palette.divider}` }}>
        <Button 
          onClick={onClose}
          variant="outlined"
          sx={{
            px: 3,
            borderRadius: 1.5,
            textTransform: 'none'
          }}
        >
          סגור
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LessonsDialog;