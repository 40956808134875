import React, { useState } from 'react';
import {
  Box,
  Typography,
  Collapse,
  IconButton,
  Chip,
  Divider,
} from '@mui/material';
import {
  AccessTime as AccessTimeIcon,
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
  Lightbulb as LightbulbIcon,
} from '@mui/icons-material';
import {
  QuestionCard,
  LevelChip,
  QuestionButton,
  SolutionContainer,
  QuestionImage,
  TimeIndicator,
} from './styles';
import VideoPlayer from '../../../Widgets/VideoPlayer';

const QuestionCardComponent = ({ 
  question = {
    title: '',
    description: '',
    level: 'beginner',
    estimatedTime: 0,
    image: null,
    explanation: {
      answerText: '',
      videoUrl: '',
    },
  }, 
  index = 0 
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [showSolution, setShowSolution] = useState(false);

  const {
    title,
    description,
    level,
    estimatedTime,
    image,
    explanation
  } = question;

  return (
    <QuestionCard elevation={isExpanded ? 3 : 1}>
      <Box sx={{ p: 3 }}>
        {/* Header */}
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
          <Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 1 }}>
              <Chip
                label={`שאלה ${index + 1}`}
                color="primary"
                size="small"
                sx={{ 
                  borderRadius: '12px',
                  fontWeight: 'bold',
                }}
              />
              {title && (
                <Typography variant="h6" fontWeight="bold">
                  {title}
                </Typography>
              )}
            </Box>
            
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mt: 1 }}>
              {level && (
                <LevelChip
                  label={level}
                  level={level}
                  size="small"
                />
              )}
              {estimatedTime > 0 && (
                <TimeIndicator>
                  <AccessTimeIcon fontSize="small" />
                  {estimatedTime} דקות
                </TimeIndicator>
              )}
            </Box>
          </Box>

          <IconButton
            onClick={() => setIsExpanded(!isExpanded)}
            sx={{
              transition: 'transform 0.3s',
              transform: isExpanded ? 'rotate(180deg)' : 'none',
            }}
          >
            {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </Box>

        {/* Preview */}
        {!isExpanded && description && (
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{
              mt: 2,
              display: '-webkit-box',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
            }}
          >
            {description}
          </Typography>
        )}

        {/* Expanded Content */}
        <Collapse in={isExpanded}>
          <Divider sx={{ my: 3 }} />
          
          {description && (
            <Typography variant="body1" paragraph>
              {description}
            </Typography>
          )}

          {image?.url && (
            <Box sx={{ my: 3 }}>
              <QuestionImage
                src={image.url}
                alt={image.alt || title || 'תמונת שאלה'}
                loading="lazy"
              />
            </Box>
          )}

          <QuestionButton
            variant="contained"
            color="primary"
            startIcon={<LightbulbIcon />}
            onClick={() => setShowSolution(!showSolution)}
            fullWidth
          >
            {showSolution ? 'הסתר פתרון' : 'הצג פתרון'}
          </QuestionButton>

          <Collapse in={showSolution}>
            <SolutionContainer>
              <Typography variant="h6" gutterBottom fontWeight="bold">
                פתרון והסבר
              </Typography>
              {explanation?.answerText && (
                <Typography variant="body1" sx={{ mb: 3 }}>
                  {explanation.answerText}
                </Typography>
              )}

              {explanation?.videoUrl && (
                <Box sx={{ mt: 4 }}>
                  <Typography variant="h6" gutterBottom fontWeight="bold">
                    הסבר מוקלט
                  </Typography>
                  <VideoPlayer 
                    videoUrl={explanation.videoUrl}
                    title={`הסבר מוקלט לשאלה ${index + 1}`}
                  />
                </Box>
              )}
            </SolutionContainer>
          </Collapse>
        </Collapse>
      </Box>
    </QuestionCard>
  );
};

export default QuestionCardComponent;