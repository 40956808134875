import React, { useContext, useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Container,
  Box,
  Button,
  CircularProgress,
  Alert,
  Divider,
  Typography,
} from '@mui/material';
import { Check as CheckIcon } from '@mui/icons-material';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

// Components
import LessonHeader from './LessonHeader';
import LessonInfo from './LessonInfo';
import LessonVideo from './LessonVideo';
import EnrollmentSection from './EnrollmentSection';
import ParticipantsSection from './ParticipantsSection';
import HomeworkSection from './HomeWork/HomeworkSection';
import FormulasTheorems from '../../Widgets/formulasThermoes';
import NotificationSnackbar from './NotificationSnackbar';
import { ContentSection, ActionButton } from './styles';

// Context
import { DataContext } from '../../../context/DataContext';
import { UserContext } from '../../../context/UserContext';

// Extend dayjs
dayjs.extend(relativeTime);

const LessonDetail = () => {
  const { lessonId } = useParams();
  const navigate = useNavigate();

  // Context
  const {
    lessons,
    courses,
    fetchLessons,
    loading: dataLoading,
    error: dataError,
  } = useContext(DataContext);

  const {
    user,
    isSignedIn,
    hasAccess,
    enrollInLesson,
    cancelLesson,
    loading: userLoading,
    error: userError,
  } = useContext(UserContext);

  // State
  const [lesson, setLesson] = useState(null);
  const [course, setCourse] = useState(null);
  const [userHasAccess, setUserHasAccess] = useState(false);
  const [accessLoading, setAccessLoading] = useState(true);
  const [accessError, setAccessError] = useState(null);
  const [isEnrolled, setIsEnrolled] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [loadingQuestions, setLoadingQuestions] = useState(false);

  const [lessonFormulas, setLessonFormulas] = useState({
    title: '',
    description: '',
    formulas: [],
    theorems: []
  });

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  // Format dates
  const formattedStartDate = lesson?.startDateTime
    ? dayjs(lesson.startDateTime).format('DD/MM/YYYY HH:mm')
    : 'לא נקבע';

  const formattedEndDate = lesson?.endDateTime
    ? dayjs(lesson.endDateTime).format('DD/MM/YYYY HH:mm')
    : 'לא נקבע';

  // Calculate enrollment stats
  const remainingSeats = lesson?.maxStudents > 0
    ? lesson.maxStudents - (lesson.enrolledStudents?.length || 0)
    : null;

  const enrollmentPercentage = lesson?.maxStudents > 0
    ? (lesson.enrolledStudents?.length / lesson.maxStudents) * 100
    : 0;

  // Effects
  useEffect(() => {
    if (!dataLoading && lessons.length > 0) {
      const foundLesson = lessons.find(l => l._id === lessonId);
      setLesson(foundLesson);

      if (foundLesson) {
        const foundCourse = courses.find(c => c._id === foundLesson.course._id);
        setCourse(foundCourse);

        setLessonFormulas({
          title: foundLesson.mainStudyMaterial?.title || 'נוסחאות ותיאוריות של השיעור',
          description: foundLesson.mainStudyMaterial?.description || 'נוסחאות ותיאוריות חשובות',
          formulas: foundLesson.mainStudyMaterial?.formulas || [],
          theorems: foundLesson.mainStudyMaterial?.theorems || []
        });
      }
    }
  }, [dataLoading, lessons, courses, lessonId]);

  useEffect(() => {
    const checkAccess = async () => {
      setLoadingQuestions(true);
      setAccessLoading(true);
      try {
        const access = await hasAccess('lesson', lessonId);
        setUserHasAccess(access);
        setQuestions(lesson?.homeWork || []);
      } catch (err) {
        console.error('Error checking access:', err);
        setAccessError('אירעה שגיאה בבדיקת הגישה לשיעור זה.');
        setUserHasAccess(false);
      } finally {
        setAccessLoading(false);
        setLoadingQuestions(false);
      }
    };

    if (isSignedIn && lessonId) {
      checkAccess();
    } else {
      setUserHasAccess(false);
      setAccessLoading(false);
    }
  }, [isSignedIn, lessonId, hasAccess, lesson]);

  useEffect(() => {
    if (lesson && user) {
      setIsEnrolled(lesson.enrolledStudents?.some(student => student._id === user._id) || false);
    }
  }, [lesson, user]);

  // Handlers
  const handleEnroll = async () => {
    try {
      await enrollInLesson(lessonId);
      setSnackbar({
        open: true,
        message: 'נרשמת בהצלחה לשיעור!',
        severity: 'success',
      });
      await fetchLessons();
    } catch (error) {
      setSnackbar({
        open: true,
        message: error.message || 'ההרשמה נכשלה. אנא נסה שוב.',
        severity: 'error',
      });
    }
  };

  const handleCancelLesson = async () => {
    try {
      await cancelLesson(lessonId);
      setSnackbar({
        open: true,
        message: 'ביטלת את ההרשמה בהצלחה!',
        severity: 'success',
      });
      await fetchLessons();
    } catch (error) {
      setSnackbar({
        open: true,
        message: error.message || 'ביטול ההרשמה נכשל. אנא נסה שוב.',
        severity: 'error',
      });
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') return;
    setSnackbar(prev => ({ ...prev, open: false }));
  };

  // ... (rest of your render code stays the same)

  if (dataLoading || accessLoading || !lesson) {
    return (
      <Container sx={{ py: 8, display: 'flex', justifyContent: 'center' }}>
        <CircularProgress size={60} />
      </Container>
    );
  }

  return (
    <Container sx={{ py: 4 }}>
      <LessonHeader lesson={lesson} course={course} />

      {userError && (
        <Alert severity="error" sx={{ mb: 4 }}>
          {userError}
        </Alert>
      )}

      {userHasAccess ? (
        <>
          <FormulasTheorems lessonFormulas={lessonFormulas} />
          
          <ContentSection>
            <Typography variant="h5" gutterBottom fontWeight="bold">
              תיאור
            </Typography>
            <Typography variant="body1">
              {lesson.description || 'אין תיאור זמין לשיעור זה.'}
            </Typography>
          </ContentSection>

          {lesson.status === 'live' && lesson.liveLink && (
            <LessonVideo
              status="live"
              videoUrl={lesson.liveLink}
              title="שידור חי"
            />
          )}

          {lesson.status === 'scheduled' && (
            <>
              <LessonInfo
                lesson={lesson}
                formattedStartDate={formattedStartDate}
                formattedEndDate={formattedEndDate}
              />
              
              <EnrollmentSection
                lesson={lesson}
                isEnrolled={isEnrolled}
                remainingSeats={remainingSeats}
                enrollmentPercentage={enrollmentPercentage}
                userLoading={userLoading}
                handleEnroll={handleEnroll}
                handleCancelLesson={handleCancelLesson}
              />

              <ParticipantsSection students={lesson.enrolledStudents} />
            </>
          )}

          {(lesson.status === 'passed' || lesson.status === 'record') && lesson.recordLink && (
            <LessonVideo
              status="record"
              videoUrl={lesson.recordLink}
              title="הקלטת השיעור"
            />
          )}

          <Divider sx={{ my: 4 }} />

          <HomeworkSection questions={questions} loading={loadingQuestions} />
        </>
      ) : (
        <Alert severity="info" sx={{ mb: 4 }}>
          אין לך גישה לשיעור זה. אנא הירשם כדי לקבל גישה.
        </Alert>
      )}

      <Box sx={{ mt: 4, textAlign: 'center' }}>
        <ActionButton
          variant="outlined"
          color="primary"
          onClick={() => navigate(`/course/${course?._id}/details`)}
          startIcon={<CheckIcon />}
        >
          חזרה לקורס
        </ActionButton>
      </Box>

      <NotificationSnackbar
        open={snackbar.open}
        message={snackbar.message}
        severity={snackbar.severity}
        onClose={handleCloseSnackbar}
      />
    </Container>
  );
};

export default LessonDetail;