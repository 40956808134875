// src/components/Course/MyCourses/MyCourses.js
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import {
  Box,
  Typography,
  Container,
  Grid,
  Button,
  CircularProgress,
  Alert,
} from '@mui/material';
import { useTheme, alpha } from '@mui/material/styles';
import { UserContext } from '../../../context/UserContext';
import { CourseCard } from './CourseCard';
import { EmptyState } from './EmptyState';
import { SectionHeader } from './styles';

const MyCourses = () => {
  const theme = useTheme();
  const {
    enrolledCourses,
    loading,
    error,
  } = useContext(UserContext);

  if (loading) {
    return (
      <Container>
        <Box 
          sx={{ 
            display: 'flex', 
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '400px'
          }}
        >
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  if (error) {
    return (
      <Container>
        <Alert 
          severity="error"
          sx={{ 
            borderRadius: 2,
            mt: 4 
          }}
        >
          {error}
        </Alert>
      </Container>
    );
  }

  return (
    <Container dir="rtl">
      <Box sx={{ textAlign: 'center', mb: 8 }}>
        <SectionHeader variant="h3">הקורסים שלי</SectionHeader>
        <Typography variant="h6" color="text.secondary">
          המשך בלימודים והתקדם בקורסים שהרשמת אליהם.
        </Typography>
      </Box>

      {enrolledCourses?.length === 0 ? (
        <EmptyState />
      ) : (
        <Grid container spacing={4}>
          {enrolledCourses.map((course) => (
            <Grid item key={course._id} xs={12} sm={6} md={4}>
              <CourseCard 
                course={course} 
                actionButton={
                  <Button
                    variant="contained"
                    color="primary"
                    component={Link}
                    to={`/course/${course._id}/details`}
                    fullWidth
                    sx={{
                      borderRadius: '50px',
                      py: 1.5,
                      textTransform: 'none',
                      fontWeight: 600,
                      transition: 'all 0.3s ease',
                      '&:hover': {
                        transform: 'translateY(-2px)',
                        boxShadow: `0 4px 12px ${alpha(theme.palette.primary.main, 0.3)}`,
                      },
                    }}
                  >
                    להמשך לימודים
                  </Button>
                }
              />
            </Grid>
          ))}
        </Grid>
      )}
    </Container>
  );
};

export default MyCourses;