// src/components/Checkout/PlanDetails/components/PlanLessonsList.js
import React, { useState } from 'react';
import { 
  Typography, Grow, Box, 
  Stack, Paper, Chip,
  IconButton, Collapse
} from '@mui/material';
import LiveTvIcon from '@mui/icons-material/LiveTv';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import EventIcon from '@mui/icons-material/Event';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import StreamIcon from '@mui/icons-material/Stream';
import StarIcon from '@mui/icons-material/Star';
import CancelIcon from '@mui/icons-material/Cancel';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const getStatusConfig = (status) => {
  const configs = {
    scheduled: {
      icon: <CalendarTodayIcon sx={{ fontSize: '16px' }} />,
      label: 'מתוכנן',
      color: 'info'
    },
    live: {
      icon: <StreamIcon sx={{ fontSize: '16px' }} />,
      label: 'שידור חי',
      color: 'success'
    },
    passed: {
      icon: <OndemandVideoIcon sx={{ fontSize: '16px' }} />,
      label: 'הסתיים',
      color: 'default'
    },
    canceled: {
      icon: <CancelIcon sx={{ fontSize: '16px' }} />,
      label: 'בוטל',
      color: 'error'
    },
    bonus: {
      icon: <StarIcon sx={{ fontSize: '16px' }} />,
      label: 'בונוס',
      color: 'warning'
    },
    record: {
      icon: <OndemandVideoIcon sx={{ fontSize: '16px' }} />,
      label: 'מוקלט',
      color: 'secondary'
    }
  };
  return configs[status] || configs.scheduled;
};

const formatDateTime = (dateStr) => {
  if (!dateStr) return '';
  const date = new Date(dateStr);
  return date.toLocaleDateString('he-IL', {
    day: 'numeric',
    month: 'long',
    hour: '2-digit',
    minute: '2-digit'
  });
};
const LessonCard = ({ lesson }) => {
  const statusConfig = getStatusConfig(lesson.status);
  const isUpcoming = ['scheduled', 'live'].includes(lesson.status);

  return (
    <Paper
      variant="outlined"
      sx={{
        p: 1.5,
        transition: 'all 0.2s ease',
        '&:hover': {
          bgcolor: 'action.hover',
          transform: 'translateX(-4px)'
        }
      }}
    >
      <Stack spacing={1}>
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          justifyContent: 'space-between',
          flexWrap: 'wrap',
          gap: 1
        }}>
          <Stack direction="row" spacing={1} alignItems="center" flexWrap="wrap">
            {statusConfig.icon}
            <Typography sx={{ fontWeight: 500 }}>
              {lesson.title}
            </Typography>
            <Stack direction="row" spacing={0.5}>
              <Chip 
                label={statusConfig.label}
                size="small"
                color={statusConfig.color}
                sx={{ height: 20 }}
              />
              {lesson.isLive && (
                <Chip 
                  icon={<LiveTvIcon sx={{ fontSize: '14px' }} />}
                  label="חי"
                  size="small"
                  color="primary"
                  sx={{ height: 20 }}
                />
              )}
            </Stack>
          </Stack>
          
          {lesson.duration && (
            <Chip
              icon={<AccessTimeIcon sx={{ fontSize: '14px' }} />}
              label={`${lesson.duration} דק'`}
              size="small"
              variant="outlined"
              sx={{ height: 20 }}
            />
          )}
        </Box>

        {(isUpcoming && lesson.startDateTime) && (
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            color: 'text.secondary',
            fontSize: '0.875rem'
          }}>
            <EventIcon fontSize="small" />
            {formatDateTime(lesson.startDateTime)}
          </Box>
        )}

        {lesson.description && (
          <Typography variant="body2" color="text.secondary">
            {lesson.description}
          </Typography>
        )}
      </Stack>
    </Paper>
  );
};

const LessonsSection = ({ title, icon, lessons, defaultExpanded = false }) => {
  const [isExpanded, setIsExpanded] = useState(defaultExpanded);

  if (!lessons.length) return null;

  return (
    <Box>
      <Paper
        variant="outlined"
        onClick={() => setIsExpanded(!isExpanded)}
        sx={{
          p: 1,
          mb: 1,
          cursor: 'pointer',
          transition: 'all 0.2s ease',
          '&:hover': {
            bgcolor: 'action.hover'
          },
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <Box sx={{ 
          display: 'flex',
          alignItems: 'center',
          gap: 1
        }}>
          {React.cloneElement(icon, { fontSize: 'small' })}
          <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
            {title}
          </Typography>
          <Chip 
            label={lessons.length}
            size="small"
            sx={{ height: 20 }}
          />
        </Box>
        <IconButton size="small">
          {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
      </Paper>

      <Collapse in={isExpanded}>
        <Stack spacing={1} sx={{ pl: 1 }}>
          {lessons.map((lesson, index) => (
            <Grow 
              key={lesson._id || index} 
              in 
              timeout={300 + (index * 100)}
            >
              <Box>
                <LessonCard lesson={lesson} />
              </Box>
            </Grow>
          ))}
        </Stack>
      </Collapse>
    </Box>
  );
};

export const PlanLessonsList = ({ data: lessons }) => {
  const upcomingLessons = lessons.filter(lesson => 
    ['scheduled', 'live'].includes(lesson.status)
  );
  
  const recordedLessons = lessons.filter(lesson => 
    ['passed', 'record'].includes(lesson.status)
  );

  const bonusLessons = lessons.filter(lesson => 
    lesson.status === 'bonus'
  );

  if (!lessons.length) return null;

  return (
    <Grow in timeout={1900}>
      <Box sx={{ p: 1.5 }}>
        <Stack spacing={1}>
          {upcomingLessons.length > 0 && (
            <LessonsSection 
              title="שיעורים קרובים" 
              icon={<CalendarTodayIcon color="primary" />}
              lessons={upcomingLessons}
              defaultExpanded={false}
            />
          )}

          {recordedLessons.length > 0 && (
            <LessonsSection 
              title="שיעורים מוקלטים" 
              icon={<OndemandVideoIcon color="primary" />}
              lessons={recordedLessons}
              defaultExpanded={false}
            />
          )}

          {bonusLessons.length > 0 && (
            <LessonsSection 
              title="שיעורי בונוס" 
              icon={<StarIcon color="primary" />}
              lessons={bonusLessons}
            />
          )}
        </Stack>
      </Box>
    </Grow>
  );
};