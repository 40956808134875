// src/components/Courses/PlansModal.js
import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  IconButton,
  Grid,
  Grow,
  Fade,
  Zoom,
} from '@mui/material';
import { useTheme, alpha } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import PlanCard from '../../Plan/PlanCard';

const enhancedStyles = {
  dialog: {
    '& .MuiDialog-paper': {
      background: (theme) => `linear-gradient(to bottom, ${alpha(theme.palette.background.paper, 1)}, ${alpha(theme.palette.background.paper, 1)})`,
    }
  },
  dialogTitle: {
    display: 'flex',
    alignItems: 'center',
    gap: 2,
    pr: 2,
    pl: 6,
    position: 'relative',
    background: (theme) => theme.palette.primary.main,
    borderBottom: '1px solid',
    borderColor: 'divider',
  },
  closeButton: {
    position: 'absolute',
    left: 8,
    top: 8,
    transition: 'all 0.2s ease',
    '&:hover': {
      transform: 'rotate(90deg)',
      backgroundColor: (theme) => alpha(theme.palette.error.main, 0.1),
      color: 'error.main',
    },
  },
  content: {
    px: { xs: 2, sm: 3 },
    py: { xs: 2, sm: 3 },
  }
};

const PlansModal = ({ open, onClose, course, plans }) => {
  const theme = useTheme();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
      fullWidth
      dir="rtl"
      TransitionComponent={Grow}
      TransitionProps={{ timeout: 400 }}
      sx={enhancedStyles.dialog}
      PaperProps={{
        sx: {
          borderRadius: 3,
          maxHeight: '90vh',
          m: { xs: 2, sm: 3 },
          width: 'calc(100% - 32px)',
        },
      }}
    >
      <DialogTitle sx={enhancedStyles.dialogTitle}>
        <Fade in timeout={600}>
          <Typography variant="h5" component="div" sx={{ fontWeight: 'bold' }}>
            תוכניות זמינות עבור {course?.title}
          </Typography>
        </Fade>
        <Zoom in timeout={400}>
          <IconButton
            onClick={onClose}
            sx={enhancedStyles.closeButton}
            aria-label="סגור"
          >
            <CloseIcon />
          </IconButton>
        </Zoom>
      </DialogTitle>

      <DialogContent sx={enhancedStyles.content}>
        <Grid 
          container 
          spacing={3}
          sx={{
            mt: { xs: 0, sm: 1 },
            mb: { xs: 1, sm: 2 }
          }}
        >
          {plans.map((plan, index) => (
            <Grid 
              item 
              key={plan._id} 
              xs={12} 
              md={6} 
              lg={4}
              sx={{
                display: 'flex',
                alignItems: 'stretch'
              }}
            >
              <PlanCard 
                plan={{
                  ...plan,
                  // הוספת מידע נוסף שעשוי להיות רלוונטי לתוכנית
                  relatedCourse: course,
                }} 
              />
            </Grid>
          ))}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default PlansModal;