import React from 'react';
import { Drawer, Toolbar, Box } from '@mui/material';
import NavigationList from './NavigationList';
import ThemeToggle from './ThemeToggle';
import UserMenu from './UserMenu';
import Logo from './Logo';
import { useTheme } from '@mui/material/styles';

const MobileDrawer = ({
  open,
  onClose,
  sidebarItems,
}) => {
  const theme = useTheme();

  return (
    <Drawer
      anchor="left"
      open={open}
      onClose={onClose}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
      sx={{
        '& .MuiDrawer-paper': {
          width: '80%',
          maxWidth: 300,
          boxSizing: 'border-box',
          backgroundColor: theme.palette.background.paper,
        },
      }}
    >
      <Toolbar>
        <Logo />
      </Toolbar>
      <Box sx={{ overflow: 'auto' }}>
        <NavigationList items={sidebarItems} onItemClick={onClose} />
      </Box>
      <Box sx={{ flexGrow: 1 }} />
      <ThemeToggle onToggle={onClose} />
      <UserMenu onMenuClose={onClose} />
    </Drawer>
  );
};

export default MobileDrawer;
