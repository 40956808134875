import React, { useState, useEffect, useContext } from 'react';
import { 
  Container, 
  Grid, 
  Box, 
  Typography, 
  Alert, 
  CircularProgress, 
  Button 
} from '@mui/material';
import { Link } from 'react-router-dom';
import PlanCard from './PlanCard';
import LessonsDialog from './LessonsDialog';
import CoursesDialog from './CoursesDialog';
import { DataContext } from '../../context/DataContext';

const AllPlans = () => {
  const { plans, fetchPlans } = useContext(DataContext); // Assuming fetchPlans is available
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [dialogState, setDialogState] = useState({
    lessons: { open: false, plan: null },
    courses: { open: false, plan: null }
  });

  useEffect(() => {
    const loadPlans = async () => {
      try {
        await fetchPlans(); // Assuming fetchPlans fetches the plans and updates the context
        setLoading(false);
      } catch (err) {
        setError('Failed to load plans. Please try again later.');
        setLoading(false);
      }
    };

    loadPlans();
  }, [fetchPlans]);

  // If DataContext automatically fetches plans on mount, you might not need to call fetchPlans here.
  // Instead, you can watch the `plans` variable.
  /*
  useEffect(() => {
    if (plans) {
      setLoading(false);
    }
  }, [plans]);
  */

  if (loading) {
    return (
      <Container sx={{ py: 8, textAlign: 'center' }}>
        <CircularProgress />
      </Container>
    );
  }

  return (
    <Container >
      <Box sx={{ textAlign: 'center', mb: 8 }}>
        <Typography variant="h3" gutterBottom>
          בחר את התוכנית המושלמת עבורך
        </Typography>
        <Typography variant="h6" color="text.secondary">
          בחר תוכנית המתאימה ביותר לצרכי הלמידה וההעדפות שלך
        </Typography>
      </Box>

      {error && (
        <Alert severity="error" sx={{ mb: 4 }}>
          {error}
        </Alert>
      )}

      {!error && plans.length === 0 && (
        <Alert severity="info" sx={{ mb: 4 }}>
          אין תוכניות זמינות כרגע. אנא נסה שוב מאוחר יותר.
        </Alert>
      )}

      <Grid container spacing={4}>
        {plans.map(plan => (
          <Grid item key={plan._id} xs={12} sm={6} md={4}>
            <PlanCard
              plan={plan}
              onShowLessons={() => setDialogState(prevState => ({
                ...prevState,
                lessons: { open: true, plan }
              }))}
              onShowCourses={() => setDialogState(prevState => ({
                ...prevState,
                courses: { open: true, plan }
              }))}
            />
          </Grid>
        ))}
      </Grid>

      <LessonsDialog
        plan={dialogState.lessons.plan}
        open={dialogState.lessons.open}
        onClose={() => setDialogState(prevState => ({
          ...prevState,
          lessons: { open: false, plan: null }
        }))}
      />

      <CoursesDialog
        plan={dialogState.courses.plan}
        open={dialogState.courses.open}
        onClose={() => setDialogState(prevState => ({
          ...prevState,
          courses: { open: false, plan: null }
        }))}
      />

      <Box sx={{ textAlign: 'center', mt: 8 }}>
        <Button
          component={Link}
          to="/"
          variant="outlined"
          size="large"
          sx={{ borderRadius: 25, px: 4, py: 1.5 }}
        >
          חזרה לדף הבית
        </Button>
      </Box>
    </Container>
  );
};

export default AllPlans;
