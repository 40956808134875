import React from 'react';
import { 
  Card, 
  Typography, 
  Box, 
  List,
  ListItem, 
  ListItemText, 
  Chip,
  Button,
  Tooltip,
  useTheme,
  alpha,
  Stack,
  Divider
} from '@mui/material';
import { Link } from 'react-router-dom';
import { 
  LocalOffer as DiscountIcon,
  Star as FeaturesIcon,
  School as CourseIcon,
  Event as LessonIcon,
  Event,
  CheckCircle as CheckIcon,
  ArrowForward as ArrowIcon,
} from '@mui/icons-material';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import { motion } from 'framer-motion';

const MotionCard = motion(Card);

const cardVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { 
    opacity: 1, 
    y: 0,
    transition: { duration: 0.5, ease: "easeOut" }
  }
};

const contentVariants = {
  hidden: { opacity: 0, x: -20 },
  visible: (i) => ({ 
    opacity: 1, 
    x: 0,
    transition: { 
      delay: i * 0.1,
      duration: 0.3
    }
  })
}



const PriceDisplay = ({ price, disPrice }) => {
  const discountPercentage = disPrice ? Math.round(((price - disPrice) / price) * 100) : null;
  
  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      {disPrice ? (
        <>
          <Typography 
            sx={{ textDecoration: 'line-through', opacity: 0.6 }} 
            color="text.secondary"
            variant="h6"
          >
            ₪{price}
          </Typography>
          <Typography 
            variant="h4" 
            color="primary" 
            sx={{ 
              fontWeight: 'bold',
              textShadow: '0 2px 4px rgba(0,0,0,0.1)'
            }}
          >
            ₪{disPrice}
          </Typography>
          <Chip 
            icon={<DiscountIcon />}
            label={`${discountPercentage}% הנחה`}
            color="error" 
            size="small"
            sx={{ 
              animation: 'pulse 2s infinite',
              height: 28
            }}
          />
        </>
      ) : (
        <Typography 
          variant="h4" 
          color="primary" 
          sx={{ 
            fontWeight: 'bold',
            textShadow: '0 2px 4px rgba(0,0,0,0.1)'
          }}
        >
          ₪{price}
        </Typography>
      )}
    </Stack>
  );
};

const daysHebrew = {
  'Sunday': 'ראשון',
  'Monday': 'שני',
  'Tuesday': 'שלישי',
  'Wednesday': 'רביעי',
  'Thursday': 'חמישי',
  'Friday': 'שישי',
  'Saturday': 'שבת'
};

const CourseSchedule = ({ course }) => (
  <Box sx={{ mt: 1, pl: 3 }}>
    {course.schedule?.map((slot, index) => (
      <Typography
        key={index}
        variant="body2"
        color="text.secondary"
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          fontSize: '0.875rem'
        }}
      >
        <Event fontSize="small" color="action" />
        יום {daysHebrew[slot.day]} | {slot.startTime} - {slot.endTime}
      </Typography>
    ))}
  </Box>
);

const CourseDisplay = ({ course, index }) => (
  <motion.div variants={contentVariants} custom={index}>
    <Box
      sx={{
        p: 1.5,
        borderRadius: 1,
        transition: 'all 0.2s',
        '&:hover': {
          bgcolor: alpha('#000', 0.02)
        }
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        {course.isLive ? (
          <Chip 
            size="small"
            color="primary"
            label="שיעור חי"
            sx={{ height: 24 }}
          />
        ) : (
          <Chip 
            size="small"
            variant="outlined"
            label="מוקלט"
            sx={{ height: 24 }}
          />
        )}
        <Typography>{course.title}</Typography>
      </Box>
      {course.isLive && course.schedule && <CourseSchedule course={course} />}
    </Box>
  </motion.div>
);

const PlanCard = ({ plan, onShowLessons, onShowCourses }) => {
  const theme = useTheme();
  const { price, disPrice, title, description, courses, lessons, liveSessionLimit } = plan;

  const liveCourses = courses?.filter(course => course.isLive) || [];
  const recordedCourses = courses?.filter(course => !course.isLive) || [];

  return (
    <MotionCard
      variants={cardVariants}
      initial="hidden"
      animate="visible"
      whileHover={{ 
        y: -8,
        transition: { duration: 0.3 }
      }}
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 3,
        overflow: 'hidden',
        boxShadow: theme.shadows[2],
        '&:hover': {
          boxShadow: theme.shadows[8],
        },
      }}
    >
      {/* Header Section */}
      <Box sx={{ p: 4, pb: 2 }}>
        <motion.div variants={contentVariants} custom={0}>
          <Typography 
            variant="h4" 
            color="primary" 
            gutterBottom 
            sx={{ fontWeight: 800 }}
          >
            {title}
          </Typography>
        </motion.div>

        <motion.div variants={contentVariants} custom={1}>
          <Typography 
            color="text.secondary" 
            paragraph
            sx={{ opacity: 0.8 }}
          >
            {description}
          </Typography>
        </motion.div>
      </Box>

      {/* Price Section */}
      <Box sx={{ px: 4, pb: 3 }}>
        <motion.div variants={contentVariants} custom={2}>
          <PriceDisplay price={price} disPrice={disPrice} />
        </motion.div>
      </Box>

      {/* Sessions Info */}
      <Box sx={{ px: 4, pb: 4 }}>
        <motion.div variants={contentVariants} custom={3}>
          <Box 
            sx={{ 
              bgcolor: alpha(theme.palette.primary.main, 0.04),
              p: 2,
              borderRadius: 2,
              display: 'flex',
              alignItems: 'center',
              gap: 1
            }}
          >
            <Event color="primary" />
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              {liveSessionLimit} שיעורים חודשיים
            </Typography>
          </Box>
        </motion.div>
      </Box>

      {/* Courses Section */}
      <Box sx={{ px: 4, pb: 4, flexGrow: 1 }}>
        {liveCourses.length > 0 && (
          <Box sx={{ mb: 4 }}>
            <Typography
              variant="subtitle1"
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                mb: 2,
                fontWeight: 'bold'
              }}
            >
              <CourseIcon color="primary" />
              קורסים חיים ({liveCourses.length})
            </Typography>
            <Box>
              {liveCourses.map((course, index) => (
                <CourseDisplay 
                  key={course._id || index} 
                  course={course} 
                  index={index + 4}
                />
              ))}
            </Box>
          </Box>
        )}

        {recordedCourses.length > 0 && (
          <Box>
            <Typography
              variant="subtitle1"
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                mb: 2,
                fontWeight: 'bold'
              }}
            >
              <OndemandVideoIcon color="primary" />
              קורסים מוקלטים ({recordedCourses.length})
            </Typography>
            <Box>
              {recordedCourses.map((course, index) => (
                <CourseDisplay 
                  key={course._id || index} 
                  course={course} 
                  index={index + liveCourses.length + 4}
                />
              ))}
            </Box>
          </Box>
        )}

        {lessons?.length > 0 && (
          <Box sx={{ mt: 3 }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1
              }}
            >
              <LessonIcon color="primary" />
              <Typography fontWeight="medium">
                {lessons.length} שיעורים נוספים
              </Typography>
            </Box>
          </Box>
        )}
      </Box>

      <Divider />

      {/* Actions Section */}
      <Box sx={{ p: 4 }}>
        <Stack spacing={2}>
          <Stack direction="row" spacing={2}>
            {lessons?.length > 0 && (
              <Button
                variant="outlined"
                onClick={onShowLessons}
                fullWidth
                sx={{ 
                  py: 1.5,
                  borderRadius: 2,
                  transition: 'all 0.2s',
                  '&:hover': {
                    transform: 'translateY(-2px)',
                    boxShadow: 2
                  }
                }}
              >
                הצג שיעורים
              </Button>
            )}

            {courses?.length > 0 && (
              <Button
                variant="outlined"
                onClick={onShowCourses}
                fullWidth
                sx={{ 
                  py: 1.5,
                  borderRadius: 2,
                  transition: 'all 0.2s',
                  '&:hover': {
                    transform: 'translateY(-2px)',
                    boxShadow: 2
                  }
                }}
              >
                הצג קורסים
              </Button>
            )}
          </Stack>

          <Button
            variant="contained"
            component={Link}
            to={`/checkout/${plan._id}`}
            fullWidth
            endIcon={<ArrowIcon />}
            sx={{ 
              py: 2,
              borderRadius: 2,
              transition: 'all 0.2s',
              fontWeight: 'bold',
              '&:hover': {
                transform: 'translateY(-2px)',
                boxShadow: 4
              }
            }}
          >
            בחר תוכנית
          </Button>
        </Stack>
      </Box>

      <style>
        {`
          @keyframes pulse {
            0% { transform: scale(1); }
            50% { transform: scale(1.05); }
            100% { transform: scale(1); }
          }
        `}
      </style>
    </MotionCard>
  );
};

export default PlanCard;