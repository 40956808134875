import React from 'react';
import { Box, Typography, Paper } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import EditableField from './EditField';

const DetailsContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: alpha(theme.palette.background.paper, 0.8),
  backdropFilter: 'blur(8px)',
  borderRadius: theme.spacing(2),
  boxShadow: theme.shadows[3],
}));

const PersonalDetails = ({ user, handleSave, validations }) => {
  return (
    <DetailsContainer elevation={0}>
      <Typography variant="h5" gutterBottom sx={{ mb: 3 }}>
        עדכון פרטים אישיים
      </Typography>
      
      <EditableField
        label="שם מלא"
        value={user.fullName}
        onSave={(value) => handleSave('fullName', value)}
        required
        validation={validations.validateFullName}
        helperText="השם שיוצג בפרופיל שלך"
      />

      <EditableField
        label="מספר טלפון"
        value={user.phoneNumber}
        onSave={(value) => handleSave('phoneNumber', value)}
        type="tel"
        validation={validations.validatePhoneNumber}
        helperText="לצורך עדכונים חשובים"
      />

      <EditableField
        label="אימייל"
        value={user.email}
        onSave={(value) => handleSave('email', value)}
        required
        type="email"
        validation={validations.validateEmail}
        helperText="משמש להתחברות ועדכונים"
      />
    </DetailsContainer>
  );
};

export default PersonalDetails;
