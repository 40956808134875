import { styled } from '@mui/system';
import { Typography, Card, Box } from '@mui/material';

export const SectionHeader = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  fontWeight: 700,
  textAlign: 'center',
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.8rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '2.5rem',
  },
}));

export const StyledCard = styled(Card)(({ theme }) => ({
  position: 'relative',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  boxShadow: theme.shadows[2],
  borderRadius: theme.shape.borderRadius,
  transition: 'transform 0.3s, box-shadow 0.3s',
  '&:hover': {
    transform: 'translateY(-8px)',
    boxShadow: theme.shadows[4],
  },
}));

export const AccessBadge = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'userHasAccess',
})(({ theme, userHasAccess }) => ({
  position: 'absolute',
  top: theme.spacing(1),
  right: theme.spacing(1),
  padding: theme.spacing(0.5, 1),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: userHasAccess
    ? theme.palette.success.main
    : theme.palette.error.main,
  color: theme.palette.common.white,
  fontWeight: 600,
  zIndex: 1,
  fontSize: '0.75rem',
}));

export const CourseTypeBadge = styled(Box)(({ theme, isLive }) => ({
  position: 'absolute',
  top: theme.spacing(1),
  left: theme.spacing(1),
  padding: theme.spacing(0.5, 1),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: isLive
    ? theme.palette.primary.main
    : theme.palette.warning.main,
  color: theme.palette.common.white,
  fontWeight: 600,
  zIndex: 1,
  fontSize: '0.75rem',
}));

export const CourseActions = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  textAlign: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  gap: theme.spacing(1),
}));

// Button styles object
export const buttonStyles = {
  details: (theme) => ({
    flex: 1,
    textTransform: 'none',
    borderRadius: '50px',
    borderColor: 'info.main',
    color: 'info.main',
    boxShadow: theme.shadows[2],
    transition: 'background-color 0.3s ease, color 0.3s ease',
    '&:hover': {
      backgroundColor: 'info.main',
      color: theme.palette.common.white,
    },
  }),
  plan: (theme) => ({
    flex: 1,
    textTransform: 'none',
    borderRadius: '50px',
    boxShadow: theme.shadows[2],
    transition: 'background-color 0.3s ease, color 0.3s ease',
    '&:hover': {
      backgroundColor: 'secondary.dark',
      color: theme.palette.common.white,
    },
  }),
};

export const planCardStyles = (theme) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  border: `1px solid`,
  borderColor: 'divider',
  borderRadius: theme.shape.borderRadius,
  transition: 'transform 0.2s, box-shadow 0.2s',
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: theme.shadows[4],
  },
});