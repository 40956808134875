// src/components/Checkout/validations.js
const CURRENT_YEAR = new Date().getFullYear() % 100;
const CURRENT_MONTH = new Date().getMonth() + 1;

/**
 * Main validation function for payment form
 */
export const validatePaymentForm = (formData) => {
  const { cardNumber, expiryDate, cvv, cardHolder } = formData;
  
  // Check for empty fields
  if (!cardNumber || !expiryDate || !cvv || !cardHolder) {
    return 'כל השדות נדרשים.';
  }

  // Validate card holder name
  if (cardHolder.trim().length < 2) {
    return 'נא להזין שם מלא.';
  }

  // Validate card number - simple 16 digits check
  const cleanCardNumber = cardNumber.replace(/\D/g, '');
  if (!/^\d{16}$/.test(cleanCardNumber)) {
    return 'מספר הכרטיס חייב להיות 16 ספרות.';
  }

  // Validate expiry date and check if not expired
  const expiryDateRegex = /^(0[1-9]|1[0-2])\/\d{2}$/;
  if (!expiryDateRegex.test(expiryDate)) {
    return 'תאריך התפוגה חייב להיות בפורמט MM/YY.';
  }

  const [month, year] = expiryDate.split('/').map(num => parseInt(num, 10));
  if (year < CURRENT_YEAR || (year === CURRENT_YEAR && month < CURRENT_MONTH)) {
    return 'תאריך תפוגה לא תקין.';
  }

  // Validate CVV - simple 3 digits check
  if (!/^\d{3}$/.test(cvv)) {
    return 'CVV חייב להיות 3 ספרות.';
  }

  return null;
};

/**
 * Format credit card number with spaces
 */
export const formatCardNumber = (value) => {
  if (!value) return value;
  const v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '');
  if (v.length > 16) return value;
  const matches = v.match(/\d{4,16}/g);
  const match = matches && matches[0] || '';
  const parts = [];

  for (let i = 0, len = match.length; i < len; i += 4) {
    parts.push(match.substring(i, i + 4));
  }

  if (parts.length) {
    return parts.join(' ');
  }
  return value;
};

/**
 * Format expiry date
 */
export const formatExpiryDate = (value) => {
  if (!value) return value;
  const v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '');
  if (v.length >= 2) {
    return `${v.substring(0, 2)}/${v.substring(2, 4)}`;
  }
  return v;
};

/**
 * Simple field validations for real-time feedback
 */
export const validateField = {
  cardNumber: (value) => {
    if (!value) return 'מספר כרטיס נדרש';
    const clean = value.replace(/\D/g, '');
    if (clean.length !== 16) return 'מספר הכרטיס חייב להיות 16 ספרות';
    return null;
  },

  expiryDate: (value) => {
    if (!value) return 'תאריך תפוגה נדרש';
    if (!/^(0[1-9]|1[0-2])\/\d{2}$/.test(value)) return 'פורמט לא תקין (MM/YY)';
    const [month, year] = value.split('/').map(num => parseInt(num, 10));
    if (year < CURRENT_YEAR || (year === CURRENT_YEAR && month < CURRENT_MONTH)) {
      return 'תאריך תפוגה לא תקין';
    }
    return null;
  },

  cvv: (value) => {
    if (!value) return 'CVV נדרש';
    if (!/^\d{3}$/.test(value)) return 'CVV חייב להיות 3 ספרות';
    return null;
  },

  cardHolder: (value) => {
    if (!value?.trim()) return 'שם בעל הכרטיס נדרש';
    if (value.trim().length < 2) return 'נא להזין שם מלא';
    return null;
  }
};