
// src/components/Checkout/checkoutService.js
export const fetchPlanDetails = async (planId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_INFINITY_BACK_URL}/api/plans/${planId}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'כשל בשליפת פרטי התוכנית.');
      }
  
      return await response.json();
    } catch (err) {
      console.error('שגיאה בשליפת פרטי התוכנית:', err);
      throw new Error('אירעה שגיאה בלתי צפויה בעת שליפת פרטי התוכנית.');
    }
  };