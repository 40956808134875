// src/components/Checkout/PaymentFormFields.js
import React from 'react';
import { 
  Box, TextField, Grid, Button, 
  Zoom, InputAdornment,
  CircularProgress
} from '@mui/material';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import PersonIcon from '@mui/icons-material/Person';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import LockIcon from '@mui/icons-material/Lock';

const formatCreditCardNumber = (value) => {
  if (!value) return value;
  const v = value.replace(/\D/g, '');
  const cardNumber = v.substring(0, 16);
  const parts = [];
  for (let i = 0; i < cardNumber.length; i += 4) {
    parts.push(cardNumber.substring(i, i + 4));
  }
  return parts.join(' ');
};

const formatExpiryDate = (value) => {
  if (!value) return value;
  const cleanValue = value.replace(/\D/g, '');
  
  // הגבלת החודש ל-12
  if (cleanValue.length >= 2) {
    const month = parseInt(cleanValue.substring(0, 2));
    if (month > 12) {
      return '12' + (cleanValue.length > 2 ? '/' + cleanValue.substring(2, 4) : '');
    }
  }

  // הוספת ה-/ אוטומטית אחרי חודש
  if (cleanValue.length >= 2) {
    return cleanValue.substring(0, 2) + '/' + cleanValue.substring(2, 4);
  }

  return cleanValue;
};

export const PaymentFormFields = ({ 
  formData, 
  handleChange, 
  handleSubmit, 
  isMobile,
  isProcessing,
  fieldErrors
}) => {
  const handleCardNumberChange = (e) => {
    const formattedValue = formatCreditCardNumber(e.target.value);
    handleChange({
      target: {
        name: 'cardNumber',
        value: formattedValue
      }
    });
  };

  const handleExpiryDateChange = (e) => {
    const formattedValue = formatExpiryDate(e.target.value);
    handleChange({
      target: {
        name: 'expiryDate',
        value: formattedValue
      }
    });
  };

  return (
    <Box 
      component="form" 
      onSubmit={handleSubmit} 
      noValidate
      sx={{ width: '100%' }}
    >
      <Grid container spacing={2}>
        {/* שם בעל הכרטיס נשאר אותו דבר */}
        <Grid item xs={12}>
          <Zoom in timeout={800}>
            <TextField
              label="שם בעל הכרטיס"
              name="cardHolder"
              variant="outlined"
              fullWidth
              required
              value={formData.cardHolder}
              onChange={handleChange}
              error={!!fieldErrors?.cardHolder}
              helperText={fieldErrors?.cardHolder || ' '}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PersonIcon color={fieldErrors?.cardHolder ? "error" : "action"} />
                  </InputAdornment>
                ),
                style: { fontSize: isMobile ? 16 : undefined }
              }}
            />
          </Zoom>
        </Grid>

        {/* מספר כרטיס נשאר אותו דבר */}
        <Grid item xs={12}>
          <Zoom in timeout={1000}>
            <TextField
              label="מספר כרטיס"
              name="cardNumber"
              variant="outlined"
              fullWidth
              required
              value={formData.cardNumber}
              onChange={handleCardNumberChange}
              error={!!fieldErrors?.cardNumber}
              helperText={fieldErrors?.cardNumber || 'הכנס 16 ספרות'}
              inputProps={{ 
                maxLength: 19,
                inputMode: 'numeric'
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <CreditCardIcon color={fieldErrors?.cardNumber ? "error" : "action"} />
                  </InputAdornment>
                ),
                style: { fontSize: isMobile ? 16 : undefined }
              }}
            />
          </Zoom>
        </Grid>

        {/* תאריך תפוגה עם הפורמט החדש */}
        <Grid item xs={12} sm={6}>
          <Zoom in timeout={1200}>
            <TextField
              label="תאריך תפוגה"
              name="expiryDate"
              variant="outlined"
              fullWidth
              required
              value={formData.expiryDate}
              onChange={handleExpiryDateChange}
              error={!!fieldErrors?.expiryDate}
              helperText={fieldErrors?.expiryDate || 'MM/YY'}
              inputProps={{ 
                maxLength: 5,
                placeholder: 'MM/YY',
                inputMode: 'numeric'
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <CalendarTodayIcon color={fieldErrors?.expiryDate ? "error" : "action"} />
                  </InputAdornment>
                ),
                style: { fontSize: isMobile ? 16 : undefined }
              }}
            />
          </Zoom>
        </Grid>

        {/* CVV נשאר אותו דבר */}
        <Grid item xs={12} sm={6}>
          <Zoom in timeout={1400}>
            <TextField
              label="CVV"
              name="cvv"
              variant="outlined"
              fullWidth
              required
              value={formData.cvv}
              onChange={handleChange}
              error={!!fieldErrors?.cvv}
              helperText={fieldErrors?.cvv || '3 ספרות'}
              inputProps={{ 
                maxLength: 3,
                inputMode: 'numeric'
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockIcon color={fieldErrors?.cvv ? "error" : "action"} />
                  </InputAdornment>
                ),
                style: { fontSize: isMobile ? 16 : undefined }
              }}
            />
          </Zoom>
        </Grid>
      </Grid>

      {/* כפתור נשאר אותו דבר */}
      <Zoom in timeout={1600}>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          disabled={isProcessing || Object.keys(fieldErrors || {}).length > 0}
          startIcon={isProcessing ? <CircularProgress size={20} /> : <CreditCardIcon />}
          sx={{
            mt: 3,
            py: 1.5,
            textTransform: 'none',
            borderRadius: '50px',
            boxShadow: 2,
            bgcolor: theme => theme.palette.primary.main,
            transition: 'all 0.3s ease',
            '&:hover': {
              transform: 'translateY(-2px)',
              boxShadow: 4,
              bgcolor: theme => theme.palette.primary.dark,
            },
            '&:active': {
              transform: 'translateY(0)',
            },
            '&:disabled': {
              bgcolor: 'action.disabledBackground',
            }
          }}
        >
          {isProcessing ? 'מעבד תשלום...' : 'אישור תשלום'}
        </Button>
      </Zoom>
    </Box>
  );
};