import React from 'react';
import {
  Box,
  Alert,
  CircularProgress,
  LinearProgress,
  Typography,
} from '@mui/material';
import { Check as CheckIcon } from '@mui/icons-material';
import { ActionButton, ContentSection } from './styles';

const EnrollmentSection = ({
  lesson,
  isEnrolled,
  remainingSeats,
  enrollmentPercentage,
  userLoading,
  handleEnroll,
  handleCancelLesson,
}) => {
  return (
    <ContentSection>
      {!isEnrolled ? (
        <>
          <Alert 
            severity="info" 
            sx={{ 
              mb: 2,
              borderRadius: 2,
            }}
          >
            אנא הירשם כדי לשמור את מקומך.
          </Alert>
          <Box sx={{ textAlign: 'center' }}>
            <ActionButton
              variant="contained"
              color="primary"
              onClick={handleEnroll}
              startIcon={userLoading ? <CircularProgress size={20} /> : <CheckIcon />}
              disabled={userLoading || (lesson.maxStudents > 0 && remainingSeats <= 0)}
            >
              {userLoading ? 'מעבד...' : 'הירשם לשיעור'}
            </ActionButton>
          </Box>
          {lesson.maxStudents > 0 && (
            <Box sx={{ mt: 3 }}>
              <Typography variant="body2" color="text.secondary" gutterBottom>
                מקומות פנויים: {remainingSeats} מתוך {lesson.maxStudents}
              </Typography>
              <LinearProgress
                variant="determinate"
                value={enrollmentPercentage}
                sx={{
                  height: 8,
                  borderRadius: 4,
                  bgcolor: 'background.paper',
                  '& .MuiLinearProgress-bar': {
                    borderRadius: 4,
                  },
                }}
              />
            </Box>
          )}
        </>
      ) : (
        <>
          <Alert 
            severity="success" 
            sx={{ 
              mb: 2,
              borderRadius: 2,
            }}
          >
            אתה רשום לשיעור זה.
          </Alert>
          <Box sx={{ textAlign: 'center' }}>
            <ActionButton
              variant="outlined"
              color="error"
              onClick={handleCancelLesson}
              startIcon={userLoading ? <CircularProgress size={20} /> : <CheckIcon />}
              disabled={userLoading}
            >
              {userLoading ? 'מעבד...' : 'בטל הרשמה'}
            </ActionButton>
          </Box>
        </>
      )}
    </ContentSection>
  );
};

export default React.memo(EnrollmentSection);
