// src/components/Course/CourseDetails/UnitLessons.js
import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import { List, Typography, Box } from '@mui/material';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import SchoolIcon from '@mui/icons-material/School';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import StarIcon from '@mui/icons-material/Star';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import LayersIcon from '@mui/icons-material/Layers';
import dayjs from 'dayjs';
import {
  LessonItem,
  LessonContent,
  LessonIconContainer,
  LessonNumber,
  LessonTitle,
  LessonDescription,
  MetaInfoContainer,
  MetaInfo,
  ActionButton,
  LessonTextContent
} from './styles';

const getLessonIcon = (status, index) => {
  const icons = [
    <SchoolIcon fontSize="large" color="primary" />,
    <LayersIcon fontSize="large" color="primary" />,
    <StarIcon fontSize="large" color="primary" />,
    <MenuBookIcon fontSize="large" color="primary" />
  ];
  if (status === 'record') {
    return <PlayCircleOutlineIcon fontSize="large" color="primary" />;
  }
  return icons[index % icons.length];
};

const LessonMetaInfo = memo(({ lesson }) => (
  <MetaInfoContainer>
    {lesson.status !== 'record' && (
      <MetaInfo>
        <CalendarTodayIcon />
        <span>
          {lesson.startDateTime
            ? dayjs(lesson.startDateTime).format('DD/MM/YYYY HH:mm')
            : 'תאריך לא נקבע'}
        </span>
      </MetaInfo>
    )}
    <MetaInfo>
      <AccessTimeIcon />
      <span>
        {lesson.duration ? `${lesson.duration} דקות` : 'לא ידוע'}
      </span>
    </MetaInfo>
  </MetaInfoContainer>
));

const LessonCard = memo(({ lesson, order, index }) => (
  <LessonItem
    elevation={0}
    component="li"
  >
    <LessonNumber>
    שיעור {order}
      <StarIcon fontSize="small" />
    </LessonNumber>

    <LessonContent>
      <LessonIconContainer>
        {getLessonIcon(lesson.status, index)}
      </LessonIconContainer>

      <LessonTextContent>
        <LessonTitle>
          {lesson.title}
        </LessonTitle>

        <Box>
          <LessonDescription>
            {lesson.description?.length > 100
              ? `${lesson.description.substring(0, 100)}...`
              : lesson.description || 'אין תיאור זמין.'}
          </LessonDescription>

          <LessonMetaInfo lesson={lesson} />

          <ActionButton
            variant="contained"
            component={Link}
            to={`/lessons/${lesson._id}`}
            endIcon={<ArrowForwardIcon />}
          >
            לפרטים נוספים
          </ActionButton>
        </Box>
      </LessonTextContent>
    </LessonContent>
  </LessonItem>
));

const UnitLessons = ({ roadmap = [], lessons }) => {
  const sortedRoadmap = React.useMemo(() => 
    [...roadmap].sort((a, b) => a.order - b.order),
    [roadmap]
  );

  if (!roadmap.length) {
    return (
      <Typography 
        variant="body2" 
        color="text.secondary"
        sx={{ textAlign: 'center', py: 4 }}
      >
        אין שיעורים זמינים ביחידה זו.
      </Typography>
    );
  }

  return (
    <List sx={{ pt: 2 }}>
      {sortedRoadmap.map((item, index) => {
        const lesson = lessons.find((l) => l._id === item.lesson._id) || item.lesson;
        return (
          <LessonCard
            key={lesson._id}
            lesson={lesson}
            order={item.order}
            index={index}
          />
        );
      })}
    </List>
  );
};

export default memo(UnitLessons);