// src/components/Checkout/PlanDetails/PlanDetails.js
import React from 'react';
import { 
  CardContent, Typography, List, Box,
  Fade, Grow, Divider 
} from '@mui/material';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import { PlanFeaturesList } from './components/PlanFeaturesList';
import { PlanCoursesList } from './components/PlanCoursesList';
import { PlanLessonsList } from './components/PlanLessonsList';
import { PlanMainDetails } from './components/PlanMainDetails';
import { 
  StyledHeader, 
  StyledCard, 
  StyledBottomBorder, 
  StyledContainer 
} from './styles';

export const PlanDetails = ({ plan }) => {
  const renderSectionWithDivider = (Component, items, isLast = false) => {
    if (!items?.length) return null;
    return (
      <>
        <Component data={items} />
        {!isLast && <Divider sx={{ my: 3 }} />}
      </>
    );
  };

  return (
    <Fade in timeout={800}>
      <StyledContainer>
        {/* Header Section */}
        <StyledHeader>
          <Grow in timeout={1000}>
            <Box sx={{ position: 'relative' }}>
              <Typography 
                variant="h4" 
                sx={{ 
                  textAlign: 'center',
                  color: 'white',
                  fontWeight: 'bold',
                  textShadow: '0 2px 4px rgba(0,0,0,0.2)',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: 1
                }}
              >
                <LocalOfferIcon sx={{ fontSize: 32 }} />
                פרטי התוכנית
              </Typography>
            </Box>
          </Grow>
        </StyledHeader>

        {/* Main Content Card */}
        <StyledCard>
          <CardContent sx={{ p: 4 }}>
            {/* Plan Main Details Section */}
            <Box sx={{ mb: 4 }}>
              <PlanMainDetails plan={plan} />
            </Box>

            <Divider sx={{ my: 3 }} />

            {/* Features, Courses, and Lessons Sections */}
            <List sx={{ '& > *:not(:last-child)': { mb: 2 } }}>
              {renderSectionWithDivider(PlanFeaturesList, plan.features)}
              {renderSectionWithDivider(PlanCoursesList, plan.courses)}
              {renderSectionWithDivider(PlanLessonsList, plan.lessons, true)}
            </List>
          </CardContent>

          <StyledBottomBorder />
        </StyledCard>

        <style>
          {`
            @keyframes pulse {
              0% { transform: scale(1); opacity: 0.8; }
              50% { transform: scale(1.1); opacity: 0.4; }
              100% { transform: scale(1); opacity: 0.8; }
            }
          `}
        </style>
      </StyledContainer>
    </Fade>
  );
};