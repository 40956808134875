// Regular expressions
const PATTERNS = {
  EMAIL: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
  PHONE_IL: /^05\d{8}$/, // Israeli phone format
  NAME: /^[\u0590-\u05FF\w\s'-]{2,50}$/, // Hebrew and English names
  PASSWORD: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/, // Strong password
  URL: /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([\/\w .-]*)*\/?$/,
  USERNAME: /^[\w\d_-]{3,20}$/,
};

// Error Messages
const ERRORS = {
  REQUIRED: 'שדה חובה',
  INVALID_EMAIL: 'כתובת אימייל לא תקינה',
  INVALID_PHONE: 'מספר טלפון לא תקין (יש להזין מספר ישראלי תקין)',
  INVALID_NAME: 'שם לא תקין (יש להזין אותיות בעברית או באנגלית)',
  INVALID_PASSWORD: 'הסיסמה חייבת להכיל לפחות 8 תווים, אות גדולה, אות קטנה ומספר',
  INVALID_URL: 'כתובת URL לא תקינה',
  INVALID_USERNAME: 'שם משתמש יכול להכיל רק אותיות, מספרים וקו תחתון',
  TOO_SHORT: (min) => `אורך מינימלי הוא ${min} תווים`,
  TOO_LONG: (max) => `אורך מקסימלי הוא ${max} תווים`,
  PASSWORDS_DONT_MATCH: 'הסיסמאות אינן תואמות',
};

// Helper Functions
const isEmptyValue = (value) => {
  return value === undefined || value === null || value.trim() === '';
};

const validateLength = (value, { min, max } = {}) => {
  if (min && value.length < min) {
    return ERRORS.TOO_SHORT(min);
  }
  if (max && value.length > max) {
    return ERRORS.TOO_LONG(max);
  }
  return '';
};

// Main Validation Functions
export const validateRequired = (value) => {
  return isEmptyValue(value) ? ERRORS.REQUIRED : '';
};

export const validateEmail = (value) => {
  if (isEmptyValue(value)) return ERRORS.REQUIRED;
  if (!PATTERNS.EMAIL.test(value)) return ERRORS.INVALID_EMAIL;
  return '';
};

export const validatePhoneNumber = (value) => {
  if (isEmptyValue(value)) return ''; // Phone is optional
  if (!PATTERNS.PHONE_IL.test(value)) return ERRORS.INVALID_PHONE;
  return '';
};

export const validateFullName = (value) => {
  if (isEmptyValue(value)) return ERRORS.REQUIRED;
  if (!PATTERNS.NAME.test(value)) return ERRORS.INVALID_NAME;
  const lengthError = validateLength(value, { min: 2, max: 50 });
  if (lengthError) return lengthError;
  return '';
};

export const validatePassword = (value) => {
  if (isEmptyValue(value)) return ERRORS.REQUIRED;
  if (!PATTERNS.PASSWORD.test(value)) return ERRORS.INVALID_PASSWORD;
  return '';
};

export const validatePasswordConfirm = (value, password) => {
  if (isEmptyValue(value)) return ERRORS.REQUIRED;
  if (value !== password) return ERRORS.PASSWORDS_DONT_MATCH;
  return '';
};

export const validateUsername = (value) => {
  if (isEmptyValue(value)) return ERRORS.REQUIRED;
  if (!PATTERNS.USERNAME.test(value)) return ERRORS.INVALID_USERNAME;
  const lengthError = validateLength(value, { min: 3, max: 20 });
  if (lengthError) return lengthError;
  return '';
};

export const validateUrl = (value) => {
  if (isEmptyValue(value)) return ''; // URL is optional
  if (!PATTERNS.URL.test(value)) return ERRORS.INVALID_URL;
  return '';
};

// Custom Validation Factory
export const createValidator = (validations) => (value) => {
  for (const validation of validations) {
    const error = validation(value);
    if (error) return error;
  }
  return '';
};

// Combined Validations Examples
export const validateNewPassword = createValidator([
  validateRequired,
  validatePassword,
]);

export const validateProfileUrl = createValidator([
  validateUrl,
]);

// Advanced Validations
export class ValidationBuilder {
  constructor() {
    this.validations = [];
  }

  required() {
    this.validations.push(validateRequired);
    return this;
  }

  email() {
    this.validations.push((value) => 
      !isEmptyValue(value) ? validateEmail(value) : '');
    return this;
  }

  phone() {
    this.validations.push((value) => 
      !isEmptyValue(value) ? validatePhoneNumber(value) : '');
    return this;
  }

  minLength(min) {
    this.validations.push((value) => 
      !isEmptyValue(value) ? validateLength(value, { min }) : '');
    return this;
  }

  maxLength(max) {
    this.validations.push((value) => 
      !isEmptyValue(value) ? validateLength(value, { max }) : '');
    return this;
  }

  pattern(pattern, errorMessage) {
    this.validations.push((value) => {
      if (isEmptyValue(value)) return '';
      return pattern.test(value) ? '' : errorMessage;
    });
    return this;
  }

  custom(validationFn) {
    this.validations.push(validationFn);
    return this;
  }

  build() {
    return createValidator(this.validations);
  }
}

// Usage Examples:
export const createProfileValidations = {
  fullName: new ValidationBuilder()
    .required()
    .pattern(PATTERNS.NAME, ERRORS.INVALID_NAME)
    .minLength(2)
    .maxLength(50)
    .build(),

  email: new ValidationBuilder()
    .required()
    .email()
    .build(),

  phone: new ValidationBuilder()
    .pattern(PATTERNS.PHONE_IL, ERRORS.INVALID_PHONE)
    .build(),

  username: new ValidationBuilder()
    .required()
    .pattern(PATTERNS.USERNAME, ERRORS.INVALID_USERNAME)
    .minLength(3)
    .maxLength(20)
    .build(),
};

// Error Formatter
export const formatValidationError = (error, fieldName) => {
  if (!error) return '';
  return `${fieldName}: ${error}`;
};

// Async Validation Helper
export const asyncValidator = (validationFn) => async (value) => {
  try {
    return await validationFn(value);
  } catch (error) {
    console.error('Validation error:', error);
    return 'שגיאת אימות';
  }
};

// Form Validation Helper
export const validateForm = (values, validations) => {
  const errors = {};
  Object.keys(validations).forEach(field => {
    const value = values[field];
    const validate = validations[field];
    const error = validate(value);
    if (error) errors[field] = error;
  });
  return errors;
};
