import React, { useState } from 'react';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
} from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Link, useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';

const NavigationList = ({ items, onItemClick }) => {
  const theme = useTheme();
  const location = useLocation();
  const [openSubmenus, setOpenSubmenus] = useState({});

  const handleClick = (name) => {
    setOpenSubmenus((prev) => ({ ...prev, [name]: !prev[name] }));
  };

  return (
    <List>
      {items.map((item) => {
        if (item.submenu) {
          return (
            <React.Fragment key={item.name}>
              <ListItem button onClick={() => handleClick(item.name)}>
                <ListItemIcon sx={{ color: theme.palette.text.primary }}>
                  {item.icon}
                </ListItemIcon>
                <ListItemText
                  primary={item.name}
                  sx={{ color: theme.palette.text.primary }}
                />
                {openSubmenus[item.name] ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse
                in={openSubmenus[item.name]}
                timeout="auto"
                unmountOnExit
              >
                <List component="div" disablePadding>
                  {item.submenu.map((subItem) => (
                    <ListItem
                      button
                      key={subItem.name}
                      component={Link}
                      to={subItem.link}
                      onClick={onItemClick}
                      sx={{ pl: 4 }}
                      selected={location.pathname === subItem.link}
                    >
                      <ListItemIcon sx={{ color: theme.palette.text.primary }}>
                        {subItem.icon}
                      </ListItemIcon>
                      <ListItemText
                        primary={subItem.name}
                        sx={{ color: theme.palette.text.primary }}
                      />
                    </ListItem>
                  ))}
                </List>
              </Collapse>
            </React.Fragment>
          );
        } else {
          return (
            <ListItem
              button
              key={item.name}
              component={Link}
              to={item.link}
              onClick={onItemClick}
              selected={location.pathname === item.link}
            >
              <ListItemIcon sx={{ color: theme.palette.text.primary }}>
                {item.icon}
              </ListItemIcon>
              <ListItemText
                primary={item.name}
                sx={{ color: theme.palette.text.primary }}
              />
            </ListItem>
          );
        }
      })}
    </List>
  );
};

export default NavigationList;
