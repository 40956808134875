import React, { useContext, useState } from 'react';
import {
  Container,
  Grid,
  Box,
  Typography,
  Alert,
  Fade,
} from '@mui/material';
import { UserContext } from '../../context/UserContext';

// Components
import PersonalDetails from './PersonalDetails';
import EnrolledCourses from './EnrolledCourses';
import SubscriptionDetails from './SubscriptionDetails';
import { SectionHeader } from './styles';

// Validation utilities
import { validateFullName, validatePhoneNumber, validateEmail } from './validations';

const Profile = () => {
  const {
    user,
    updateUser,
    error,
    setError,
    fetchUserData,
    enrolledCourses,
    fetchEnrolledCourses,
  } = useContext(UserContext);

  const [globalSuccess, setGlobalSuccess] = useState('');

  const handleSave = async (field, value) => {
    try {
      await updateUser({ [field]: value });
      setGlobalSuccess('הפרטים עודכנו בהצלחה!');
      await Promise.all([fetchUserData(), fetchEnrolledCourses()]);
      setTimeout(() => setGlobalSuccess(''), 3000);
    } catch (err) {
      console.error('Error updating profile:', err);
    }
  };

  return (
    <Container maxWidth="lg" sx={{ py: 6 }}>
      <SectionHeader>
        <Typography variant="h2" component="h1">
          פרופיל המשתמש
        </Typography>
        <Typography variant="subtitle1" color="text.secondary">
          נהל את הפרטים האישיים שלך והצפה במידע החשבון שלך
        </Typography>
      </SectionHeader>

      <Fade in={Boolean(globalSuccess || error)} timeout={500}>
        <Box sx={{ mb: 4 }}>
          {globalSuccess && (
            <Alert severity="success" variant="filled">
              {globalSuccess}
            </Alert>
          )}
          {error && (
            <Alert severity="error" variant="filled" onClose={() => setError(null)}>
              {error}
            </Alert>
          )}
        </Box>
      </Fade>

      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <PersonalDetails
            user={user}
            handleSave={handleSave}
            validations={{ validateFullName, validatePhoneNumber, validateEmail }}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <SubscriptionDetails
            subscription={user.subscriptions?.[user.subscriptions.length - 1]}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom sx={{ mb: 3 }}>
            הקורסים שלי
          </Typography>
          <EnrolledCourses courses={enrolledCourses} />
        </Grid>
      </Grid>
    </Container>
  );
};

export default Profile;
