import { styled, alpha } from '@mui/material/styles';
import { Box, Typography, Accordion, Chip, Button, Paper } from '@mui/material';

export const HeaderContainer = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  marginBottom: theme.spacing(8),
  position: 'relative',
}));

export const CourseTitle = styled(Typography)(({ theme }) => ({
  fontSize: {
    xs: '2rem',
    sm: '2.5rem',
    md: '3rem'
  },
  fontWeight: 800,
  marginBottom: theme.spacing(4),
  background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  position: 'relative',
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: -8,
    left: '50%',
    transform: 'translateX(-50%)',
    width: '60px',
    height: '3px',
    background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
    borderRadius: theme.shape.borderRadius,
  }
}));

export const CourseDescription = styled(Typography)(({ theme }) => ({
  fontSize: {
    xs: '1rem',
    sm: '1.2rem',
    md: '1.4rem'
  },
  color: theme.palette.text.secondary,
  maxWidth: '800px',
  margin: '0 auto',
  marginBottom: theme.spacing(4),
  lineHeight: 1.6,
}));

export const InfoCard = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  marginTop: theme.spacing(2),
  borderRadius: theme.shape.borderRadius * 2,
  backgroundColor: alpha(theme.palette.background.paper, 0.6),
  backdropFilter: 'blur(10px)',
  border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
}));

export const CourseTypeBadge = styled(Box)(({ theme, isLive }) => ({
  position: 'absolute',
  top: theme.spacing(2),
  left: theme.spacing(2),
  padding: theme.spacing(0.5, 1.5),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: isLive
    ? theme.palette.primary.main
    : theme.palette.warning.main,
  color: theme.palette.common.white,
  fontWeight: 600,
  zIndex: 1,
  fontSize: '0.875rem',
}));

export const UnitsContainer = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(8),
}));

export const StyledAccordion = styled(Accordion)(({ theme }) => ({
  '&.MuiAccordion-root': {
    margin: '8px 0',
    '&:before': {
      display: 'none',
    },
    // Add this to ensure proper expansion behavior
    '& .MuiCollapse-root': {
      transformOrigin: 'top',
    },
  },
  // Optional: Add transition for smooth expansion
  transition: theme.transitions.create(['margin', 'height'], {
    duration: theme.transitions.duration.standard,
  }),
}));

export const UnitTitle = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
  fontSize: {
    xs: '1.2rem',
    sm: '1.4rem',
    md: '1.6rem'
  },
  '.Mui-expanded &': {
    color: theme.palette.primary.main,
  }
}));

export const TopicChip = styled(Chip)(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

export const SectionHeader = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  fontWeight: 800,
  textAlign: 'center',
  background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  fontSize: {
    xs: '1.8rem',
    md: '2.5rem'
  },
}));

export const AccessChip = styled(Chip)(({ theme }) => ({
  marginTop: theme.spacing(2),
  padding: theme.spacing(2),
  '& .MuiChip-label': {
    fontSize: '1rem',
  },
}));

export const UnitDescription = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  fontSize: {
    xs: '0.9rem',
    sm: '1rem',
    md: '1.1rem'
  },
  color: theme.palette.text.secondary,
  lineHeight: 1.6,
}));

export const TopicsContainer = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(3),
}));

export const TopicsTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  marginBottom: theme.spacing(2),
}));

export const IconContainer = styled(Box)(({ theme }) => ({
  display: 'inline-block',
  marginBottom: theme.spacing(3),
}));

export const LessonItem = styled(Paper)(({ theme }) => ({
  position: 'relative',
  padding: theme.spacing(3),
  marginBottom: theme.spacing(2.5),
  backgroundColor: alpha(theme.palette.background.paper, 0.9),
  backdropFilter: 'blur(8px)',
  borderRadius: theme.shape.borderRadius * 2,
  border: `1px solid ${alpha(theme.palette.primary.main, 0.08)}`,
  overflow: 'hidden',
}));

export const LessonContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(3),
  alignItems: 'flex-start',
}));

export const LessonIconContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius * 2,
  backgroundColor: alpha(theme.palette.primary.main, 0.1),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '&::after': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    borderRadius: 'inherit',
    border: `2px solid ${alpha(theme.palette.primary.main, 0.2)}`,
  },
}));

export const LessonNumber = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(3),
  right: theme.spacing(3),
  padding: theme.spacing(0.5, 1.5),
  borderRadius: theme.shape.borderRadius,
  fontSize: '0.85rem',
  fontWeight: 600,
  color: theme.palette.primary.main,
  backgroundColor: alpha(theme.palette.primary.main, 0.1),
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  border: `1px solid ${alpha(theme.palette.primary.main, 0.2)}`,
}));

export const LessonTitle = styled(Typography)(({ theme }) => ({
  fontSize: '1.25rem',
  fontWeight: 700,
  marginBottom: theme.spacing(1),
  color: theme.palette.text.primary,
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
}));

export const LessonDescription = styled(Typography)(({ theme }) => ({
  color: alpha(theme.palette.text.secondary, 0.9),
  fontSize: '0.95rem',
  lineHeight: 1.7,
  marginBottom: theme.spacing(2),
}));

export const MetaInfoContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: theme.spacing(2),
  marginTop: theme.spacing(2),
  padding: theme.spacing(2),
  backgroundColor: alpha(theme.palette.background.default, 0.5),
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
}));

export const MetaInfo = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  padding: theme.spacing(0.5, 1.5),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.paper,
  fontSize: '0.875rem',
  color: theme.palette.text.secondary,
  border: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
  '& svg': {
    fontSize: '1.1rem',
    color: theme.palette.primary.main,
  },
}));

export const ActionButton = styled(Button)(({ theme }) => ({
  position: 'relative',
  marginTop: theme.spacing(2),
  padding: theme.spacing(1.2, 3),
  borderRadius: theme.shape.borderRadius * 3,
  textTransform: 'none',
  fontWeight: 600,
  fontSize: '0.9rem',
  background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
}));

export const LessonTextContent = styled(Box)(({ theme }) => ({
  flex: 1,
  marginRight: theme.spacing(3),
}));