import React from 'react';
import { Box, Typography } from '@mui/material';
import ReactPlayer from 'react-player';
import { VideoContainer, ContentSection } from './styles';

const LessonVideo = ({ status, videoUrl, title }) => {
  if (!videoUrl) return null;

  return (
    <ContentSection>
      <Typography variant="h5" gutterBottom fontWeight="bold">
        {status === 'live' ? 'השיעור החי' : 'הקלטת השיעור'}
      </Typography>
      <VideoContainer>
        <ReactPlayer
          url={videoUrl}
          playing={status === 'live'}
          controls
          width="100%"
          height="100%"
        />
      </VideoContainer>
    </ContentSection>
  );
};

export default React.memo(LessonVideo);
