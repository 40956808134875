import React from 'react';
import {
  Typography,
  Box,
  CardContent,
  CardActions,
  Button,
  Chip,
  Tooltip,
} from '@mui/material';
import { 
  Schedule,
  LiveTv,
  Event,
  CalendarToday,
} from '@mui/icons-material';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { LessonCard, BadgeContainer, LessonDetails, StatusChip } from './styles';

const LessonCardComponent = ({ 
  lesson = {}, // הוספת ערך ברירת מחדל למניעת undefined
  isEnrolled = false,
  onEnroll,
}) => {
  // Destructuring עם ערכי ברירת מחדל למניעת undefined
  const {
    _id = '',
    title = '',
    description = '',
    duration = 0,
    startDateTime = null,
    endDateTime = null,
    status = 'scheduled',
    isLive = false,
    course = {},
    courseTitle = '',
  } = lesson;

  // בדיקת תיאור עם טיפול ב-null/undefined
  const formattedDescription = description && description.length > 100
    ? `${description.substring(0, 100)}...`
    : description;

  // פונקצית עזר לפורמוט תאריכים עם טיפול ב-null/undefined
  const formatDate = (date) => {
    return date ? dayjs(date).format('DD/MM/YYYY HH:mm') : 'לא צוין תאריך';
  };

  return (
    <LessonCard>
      <BadgeContainer>
        <Chip
          label={course?.title || courseTitle || 'קורס לא מוגדר'}
          color={isEnrolled ? "primary" : "secondary"}
          size="small"
          sx={{ fontSize: '0.75rem' }}
        />
        {isLive && (
          <Tooltip title="שידור חי" arrow>
            <Chip
              icon={<LiveTv />}
              label="Live"
              color="error"
              size="small"
              sx={{ ml: 1, fontSize: '0.75rem' }}
            />
          </Tooltip>
        )}
      </BadgeContainer>

      <CardContent sx={{ flexGrow: 1, p: 3 }}>
        <Typography
          variant="h5"
          component="h2"
          gutterBottom
          sx={{
            fontSize: { xs: '1.1rem', sm: '1.2rem', md: '1.3rem' },
            fontWeight: 600,
            mb: 2,
          }}
        >
          {title || 'שיעור ללא כותרת'}
        </Typography>

        {formattedDescription && (
          <Typography
            variant="body2"
            color="text.secondary"
            paragraph
            sx={{
              fontSize: { xs: '0.9rem', sm: '1rem' },
              mb: 3,
            }}
          >
            {formattedDescription}
          </Typography>
        )}

        <LessonDetails>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, mb: 2 }}>
            {duration > 0 && (
              <Tooltip title="משך השיעור" arrow>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Schedule color="action" fontSize="small" />
                  <Typography variant="body2">
                    {duration} דקות
                  </Typography>
                </Box>
              </Tooltip>
            )}

            <Tooltip title="תאריך התחלה" arrow>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Event color="action" fontSize="small" />
                <Typography variant="body2">
                  {formatDate(startDateTime)}
                </Typography>
              </Box>
            </Tooltip>

            <Tooltip title="תאריך סיום" arrow>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <CalendarToday color="action" fontSize="small" />
                <Typography variant="body2">
                  {formatDate(endDateTime)}
                </Typography>
              </Box>
            </Tooltip>
          </Box>

          <StatusChip 
            status={status.toLowerCase()} 
            component="div"
          >
            {status === 'scheduled' ? 'מתוכנן' :
             status === 'live' ? 'שידור חי' :
             status === 'passed' ? 'הסתיים' :
             status === 'canceled' ? 'בוטל' :
             status === 'bonus' ? 'בונוס' :
             status === 'record' ? 'הקלטה' : status}
          </StatusChip>
        </LessonDetails>
      </CardContent>

      <CardActions sx={{ p: 3, pt: 0, gap: 2 }}>
        {_id && (
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to={`/lessons/${_id}`}
            fullWidth
            sx={{
              borderRadius: '50px',
              py: 1,
              textTransform: 'none',
              fontWeight: 600,
            }}
          >
            צפה בשיעור
          </Button>
        )}
        {!isEnrolled && onEnroll && _id && (
          <Button
            variant="outlined"
            color="success"
            onClick={() => onEnroll(_id)}
            fullWidth
            sx={{
              borderRadius: '50px',
              py: 1,
              textTransform: 'none',
              fontWeight: 600,
            }}
          >
            הירשם
          </Button>
        )}
      </CardActions>
    </LessonCard>
  );
};

export default LessonCardComponent;