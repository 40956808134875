import React, { useContext } from 'react';
import { Container, Grid, Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import InfoCard from './InfoCard/InfoCard';
import { ThemeContext } from '../../context/ThemeContext';
import ScheduleComponent from './ScheduleComponent/ScheduleComponent';
import infoCards from './InfoCard/infoCards';
import ExploreStudyMaterials from './Draft/ExploreStudyMaterials';
import InfoCards from './InfoCard/InfoCards.jsx';

const Home = () => {
  const theme = useTheme();
  const { fontSize } = useContext(ThemeContext);

  return (
    <>
      <main className="home" style={{ backgroundColor: theme.palette.background.default }}>
        <Container>
          <ScheduleComponent />
        </Container>
        <Container sx={{ py: { xs: 4, sm: 6 } }}>
          <InfoCards/>
        </Container>
        <Container sx={{ py: { xs: 4, sm: 6 } }}>
          <ExploreStudyMaterials/>
        </Container>
      </main>
    </>
  );
};

export default Home;
