import React from 'react';
import { 
  Card, 
  CardContent, 
  CardMedia, 
  Typography,
  Box,
  useTheme 
} from '@mui/material';
import PropTypes from 'prop-types';

const InfoCard = ({ 
  title, 
  description, 
  imagePath, 
  elevation = 0,
  imageHeight = { xs: 120, sm: 160 },
  onClick 
}) => {
  const theme = useTheme();

  return (
    <Card
      onClick={onClick}
      elevation={elevation}
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        border: `1px solid ${theme.palette.divider}`,
        position: 'relative',
        overflow: 'hidden',
        transition: theme.transitions.create(['transform', 'box-shadow'], {
          duration: theme.transitions.duration.standard,
        }),
        '&:hover': {
          transform: 'translateY(-8px)',
          boxShadow: theme.shadows[8],
        },
        cursor: onClick ? 'pointer' : 'default',
        backgroundColor: theme.palette.background.paper,
      }}
    >
      <Box sx={{ position: 'relative' }}>
        <CardMedia
          component="img"
          height={imageHeight}
          image={imagePath}
          alt={`${title} illustration`}
          sx={{
            objectFit: 'cover',
            transition: theme.transitions.create('transform', {
              duration: theme.transitions.duration.standard,
            }),
            '&:hover': {
              transform: 'scale(1.05)',
            },
          }}
        />
      </Box>
      
      <CardContent sx={{ flexGrow: 1 }}>
        <Typography
          variant="h6"
          gutterBottom
          sx={{
            fontWeight: theme.typography.fontWeightMedium,
            color: theme.palette.text.primary,
          }}
        >
          {title}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            color: theme.palette.text.secondary,
            lineHeight: 1.6,
          }}
        >
          {description}
        </Typography>
      </CardContent>
    </Card>
  );
};

InfoCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  imagePath: PropTypes.string.isRequired,
  elevation: PropTypes.number,
  imageHeight: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.object
  ]),
  onClick: PropTypes.func
};

export default InfoCard;