import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Typography,
  Box,
  Grid,
  Chip,
  Divider,
  Paper,
  IconButton,
  Alert,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  Close,
  AccessTime,
  Payment,
  Event,
  CalendarToday,
  Warning,
  CheckCircle,
} from '@mui/icons-material';
import { format } from 'date-fns';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: theme.spacing(2),
    padding: theme.spacing(2),
  },
}));

const StatusChip = styled(Chip)(({ theme, status }) => ({
  borderRadius: '16px',
  fontWeight: 'bold',
  '& .MuiChip-label': {
    padding: '0 12px',
  },
  ...(status === 'Active' && {
    backgroundColor: theme.palette.success.light,
    color: theme.palette.success.dark,
  }),
  ...(status === 'Paused' && {
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.warning.dark,
  }),
  ...(status === 'Cancelled' && {
    backgroundColor: theme.palette.error.light,
    color: theme.palette.error.dark,
  }),
}));

const InfoBox = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: theme.spacing(1),
  backgroundColor: theme.palette.background.default,
  border: `1px solid ${theme.palette.divider}`,
  '&:hover': {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[2],
  },
  transition: theme.transitions.create(['background-color', 'box-shadow'], {
    duration: theme.transitions.duration.short,
  }),
}));

export const DetailsDialog = ({ open, onClose, subscription, plan }) => (
  <StyledDialog open={open} onClose={onClose} maxWidth="md" fullWidth>
    <DialogTitle>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="h5" component="span">
          פרטי המנוי
        </Typography>
        <IconButton onClick={onClose} size="small">
          <Close />
        </IconButton>
      </Box>
    </DialogTitle>

    <DialogContent>
      {subscription && plan ? (
        <>
          <Box sx={{ mb: 4 }}>
            <Typography variant="h4" gutterBottom color="primary">
              {plan.title}
            </Typography>
            <Box sx={{ display: 'flex', gap: 1, mb: 2 }}>
              <StatusChip
                status={subscription.status}
                label={subscription.status}
                icon={subscription.status === 'Active' ? <CheckCircle /> : <Warning />}
              />
              <Chip
                label={`₪${plan.price}/חודש`}
                color="primary"
                variant="outlined"
              />
            </Box>
          </Box>

          <Divider sx={{ my: 3 }} />

          <Grid container spacing={3} sx={{ mb: 4 }}>
            <Grid item xs={12} md={6}>
              <InfoBox>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Event color="primary" />
                  <Typography variant="subtitle1">תאריכים</Typography>
                </Box>
                <Typography variant="body1">
                  התחלה: {format(new Date(subscription.startDate), 'dd/MM/yyyy')}
                </Typography>
                <Typography variant="body1">
                  סיום: {format(new Date(subscription.endDate), 'dd/MM/yyyy')}
                </Typography>
                {subscription.pausedAt && (
                  <Typography variant="body1" color="warning.main">
                    הושהה: {format(new Date(subscription.pausedAt), 'dd/MM/yyyy')}
                  </Typography>
                )}
              </InfoBox>
            </Grid>

            <Grid item xs={12} md={6}>
              <InfoBox>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Payment color="primary" />
                  <Typography variant="subtitle1">פרטי תשלום</Typography>
                </Box>
                <Typography variant="body1">
                  סטטוס: {subscription.paymentStatus}
                </Typography>
                {subscription.lastPaymentDate && (
                  <Typography variant="body1">
                    תשלום אחרון: {format(new Date(subscription.lastPaymentDate), 'dd/MM/yyyy')}
                  </Typography>
                )}
                {subscription.nextPaymentDue && (
                  <Typography variant="body1">
                    תשלום הבא: {format(new Date(subscription.nextPaymentDue), 'dd/MM/yyyy')}
                  </Typography>
                )}
              </InfoBox>
            </Grid>
          </Grid>

          <Box sx={{ mb: 3 }}>
            <Typography variant="h6" gutterBottom>
              תכונות המנוי
            </Typography>
            <Grid container spacing={1}>
              {plan.features.map((feature, index) => (
                <Grid item key={index}>
                  <Chip
                    label={feature}
                    variant="outlined"
                    size="small"
                    sx={{ borderRadius: 2 }}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>

          {subscription.status === 'Active' && (
            <Alert severity="info" sx={{ mt: 2 }}>
              המנוי שלך פעיל ותקף עד {format(new Date(subscription.endDate), 'dd/MM/yyyy')}
            </Alert>
          )}
        </>
      ) : (
        <Box sx={{ textAlign: 'center', py: 3 }}>
          <Typography>טוען...</Typography>
        </Box>
      )}
    </DialogContent>

    <DialogActions sx={{ p: 2, pt: 0 }}>
      <Button 
        onClick={onClose} 
        variant="contained" 
        color="primary"
        sx={{ borderRadius: 2, px: 4 }}
      >
        סגור
      </Button>
    </DialogActions>
  </StyledDialog>
);

export const CancelDialog = ({ open, onClose, onConfirm }) => (
  <StyledDialog open={open} onClose={onClose}>
    <DialogTitle>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="h5" component="span" color="error">
          ביטול מנוי
        </Typography>
        <IconButton onClick={onClose} size="small">
          <Close />
        </IconButton>
      </Box>
    </DialogTitle>
    
    <DialogContent>
      <Alert severity="warning" sx={{ mb: 3 }}>
        פעולה זו אינה הפיכה!
      </Alert>
      <DialogContentText>
        האם אתה בטוח שברצונך לבטל את המנוי? 
        לאחר הביטול תאבד גישה לכל התכנים והשיעורים הכלולים במנוי.
      </DialogContentText>
    </DialogContent>
    
    <DialogActions sx={{ p: 2, pt: 0 }}>
      <Button 
        onClick={onClose} 
        variant="outlined" 
        sx={{ borderRadius: 2, px: 4 }}
      >
        ביטול
      </Button>
      <Button 
        onClick={onConfirm} 
        variant="contained" 
        color="error" 
        autoFocus
        sx={{ borderRadius: 2, px: 4 }}
      >
        אישור ביטול מנוי
      </Button>
    </DialogActions>
  </StyledDialog>
);