// src/components/Checkout/PlanDetails/components/PlanFeaturesList.js
import React from 'react';
import { 
  ListItem, ListItemIcon, ListItemText, 
  List, Typography, Zoom, Grow 
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

export const PlanFeaturesList = ({ data: features }) => (
  <Grow in timeout={1800}>
    <ListItem
      sx={{
        transition: 'background-color 0.3s ease',
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.02)'
        }
      }}
    >
      <ListItemIcon>
        <CheckIcon color="primary" />
      </ListItemIcon>
      <ListItemText
        primary={
          <>
            <Typography variant="subtitle1" gutterBottom>תכונות:</Typography>
            <List dense>
              {features.map((feature, index) => (
                <Zoom 
                  key={index} 
                  in 
                  timeout={1800 + (index * 200)}
                >
                  <ListItem
                    sx={{
                      transition: 'all 0.3s ease',
                      '&:hover': {
                        backgroundColor: 'rgba(0, 0, 0, 0.02)',
                        transform: 'translateX(-4px)'
                      }
                    }}
                  >
                    <ListItemIcon>
                      <CheckIcon color="secondary" fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary={feature} />
                  </ListItem>
                </Zoom>
              ))}
            </List>
          </>
        }
      />
    </ListItem>
  </Grow>
);