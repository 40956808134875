// src/components/Checkout/PlanDetails/components/PlanCoursesList.js
import React from 'react';
import { 
  Typography, Grow, Box, 
  Stack, Paper, Chip 
} from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import LiveTvIcon from '@mui/icons-material/LiveTv';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';

const daysHebrew = {
  'Sunday': 'ראשון',
  'Monday': 'שני',
  'Tuesday': 'שלישי',
  'Wednesday': 'רביעי',
  'Thursday': 'חמישי',
  'Friday': 'שישי',
  'Saturday': 'שבת'
};

const CourseCard = ({ course }) => {
  return (
    <Paper
      variant="outlined"
      sx={{
        p: 1.5,
        mb: 1,
        transition: 'all 0.2s ease',
        '&:hover': {
          bgcolor: 'action.hover',
          transform: 'translateX(-4px)'
        }
      }}
    >
      <Stack spacing={1}>
        {/* Course Title & Type */}
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          {course.isLive ? 
            <LiveTvIcon fontSize="small" color="primary" /> : 
            <OndemandVideoIcon fontSize="small" color="action" />
          }
          <Typography 
            variant="body1" 
            sx={{ fontWeight: 500, flex: 1 }}
          >
            {course.title}
          </Typography>
          <Chip 
            label={course.isLive ? "חי" : "מוקלט"}
            size="small"
            color={course.isLive ? "primary" : "default"}
            variant={course.isLive ? "filled" : "outlined"}
            sx={{ 
              height: 20,
              '& .MuiChip-label': { px: 1, py: 0 }
            }}
          />
        </Box>

        {/* Schedule Times for Live Courses */}
        {course.isLive && course.schedule?.length > 0 && (
          <Stack direction="row" flexWrap="wrap" gap={2}>
            {course.schedule.map((slot, index) => (
              <Box
                key={index}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 0.5,
                  color: 'text.secondary',
                  fontSize: '0.875rem'
                }}
              >
                <CalendarMonthIcon 
                  sx={{ fontSize: 16, color: 'primary.light' }} 
                />
                יום {daysHebrew[slot.day]}
                <AccessTimeIcon 
                  sx={{ fontSize: 16, color: 'primary.light', ml: 0.5 }} 
                />
                {slot.startTime} - {slot.endTime}
              </Box>
            ))}
          </Stack>
        )}
      </Stack>
    </Paper>
  );
};

const CoursesSection = ({ courses, isLive }) => {
  if (!courses.length) return null;

  return (
    <Box>
      <Box 
        sx={{ 
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          mb: 1,
          mt: isLive ? 0 : 1
        }}
      >
        {isLive ? 
          <LiveTvIcon fontSize="small" color="primary" /> : 
          <OndemandVideoIcon fontSize="small" color="primary" />
        }
        <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
          {isLive ? "קורסים חיים" : "קורסים מוקלטים"}
        </Typography>
        <Chip 
          label={courses.length}
          size="small"
          variant="outlined"
          sx={{ 
            height: 20,
            '& .MuiChip-label': { px: 1, py: 0 }
          }}
        />
      </Box>

      <Stack>
        {courses.map((course, index) => (
          <CourseCard 
            key={course._id || index} 
            course={course} 
          />
        ))}
      </Stack>
    </Box>
  );
};

export const PlanCoursesList = ({ data: courses }) => {
  const liveCourses = courses.filter(course => course.isLive);
  const recordedCourses = courses.filter(course => !course.isLive);

  if (!courses.length) return null;

  return (
    <Grow in timeout={1900}>
      <Box sx={{ px: 1.5, py: 1 }}>
        <Stack spacing={1}>
          {liveCourses.length > 0 && (
            <CoursesSection 
              courses={liveCourses}
              isLive={true}
            />
          )}
          {recordedCourses.length > 0 && (
            <CoursesSection 
              courses={recordedCourses}
              isLive={false}
            />
          )}
        </Stack>
      </Box>
    </Grow>
  );
};