
import React from 'react';
import { 
  Box, 
  Typography, 
  Grid, 
  Avatar, 
  Tooltip,
  AvatarGroup 
} from '@mui/material';
import { ContentSection } from './styles';
import { styled, alpha } from '@mui/material/styles';

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: 56,
  height: 56,
  border: `3px solid ${theme.palette.background.paper}`,
  boxShadow: `0 0 0 2px ${alpha(theme.palette.primary.main, 0.3)}`,
  transition: theme.transitions.create(['transform', 'box-shadow'], {
    duration: 200,
  }),
  '&:hover': {
    transform: 'scale(1.1)',
    boxShadow: `0 0 0 3px ${alpha(theme.palette.primary.main, 0.5)}`,
    zIndex: 1,
  },
}));

const ParticipantsSection = ({ students = [] }) => {
  if (!students.length) {
    return (
      <ContentSection>
        <Typography variant="h5" gutterBottom fontWeight="bold">
          רשומים לשיעור
        </Typography>
        <Typography variant="body1" color="text.secondary" textAlign="center">
          אין תלמידים רשומים לשיעור זה.
        </Typography>
      </ContentSection>
    );
  }

  return (
    <ContentSection>
      <Typography variant="h5" gutterBottom fontWeight="bold" sx={{ mb: 3 }}>
        רשומים לשיעור ({students.length})
      </Typography>
      <AvatarGroup 
        max={12}
        spacing="medium"
        sx={{
          justifyContent: 'center',
          '& .MuiAvatar-root': {
            width: 56,
            height: 56,
            fontSize: '1.5rem',
          },
        }}
      >
        {students.map((student) => (
          <Tooltip 
            key={student._id} 
            title={student.fullName}
            arrow
            placement="top"
          >
            <StyledAvatar
              alt={student.fullName}
              src={student.avatarUrl}
            >
              {student.fullName.charAt(0).toUpperCase()}
            </StyledAvatar>
          </Tooltip>
        ))}
      </AvatarGroup>
    </ContentSection>
  );
};

export default React.memo(ParticipantsSection);
