
// src/components/Courses/CourseCard.js
import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Button,
} from '@mui/material';
import { useTheme, alpha, styled } from '@mui/material/styles';
import ScheduleIcon from '@mui/icons-material/Schedule';
import EventIcon from '@mui/icons-material/Event';
import IconDisplay from '../../Icon/IconDisplay';
import { CourseScheduleDisplay } from './CourseScheduleDisplay';
import { CourseTypeBadge } from '../AllCourses/styles';

export const CourseCard = memo(({ course }) => {
    const theme = useTheme();
  
    return (
      <Card
        sx={{
          position: 'relative',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          boxShadow: theme.shadows[2],
          borderRadius: theme.shape.borderRadius,
          transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
          overflow: 'hidden',
          '&:hover': {
            transform: 'translateY(-8px)',
            boxShadow: theme.shadows[4],
          },
        }}
      >
        <CourseTypeBadge
          isLive={course.isLive}
          aria-label={`סוג קורס: ${course.isLive ? 'רץ בלייב' : 'מוקלט'}`}
        >
          {course.isLive ? 'רץ בלייב' : 'מוקלט'}
        </CourseTypeBadge>
  
        <Box 
          sx={{ 
            p: 3,
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
          }}
        >
          <Box 
            sx={{ 
              display: 'flex', 
              justifyContent: 'center',
              mb: 2 
            }}
          >
            <IconDisplay
              iconName={course.icon}
              size={80}
              color="primary.main"
            />
          </Box>
  
          <CardContent sx={{ flexGrow: 1, textAlign: 'center', p: 0 }}>
            <Typography
              variant="h5"
              component="h2"
              gutterBottom
              sx={{
                fontSize: {
                  xs: '1.2rem',
                  sm: '1.4rem',
                  md: '1.6rem',
                },
                fontWeight: 600,
              }}
            >
              {course.title}
            </Typography>
            
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{
                mb: 2,
                fontSize: {
                  xs: '0.9rem',
                  sm: '1rem',
                  md: '1.1rem',
                },
              }}
            >
              {course.description?.length > 100
                ? `${course.description.substring(0, 100)}...`
                : course.description}
            </Typography>
  
            {course.isLive && course.schedule && (
              <Box
                sx={{
                  mt: 2,
                  p: 2,
                  backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.05),
                  borderRadius: theme.shape.borderRadius,
                }}
              >
                <Typography
                  variant="subtitle2"
                  sx={{
                    mb: 1,
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    color: 'primary.main',
                    fontWeight: 600,
                  }}
                >
                  <ScheduleIcon fontSize="small" />
                  זמני שיעורים
                </Typography>
                <CourseScheduleDisplay schedule={course.schedule} />
              </Box>
            )}
          </CardContent>
  
          <Box sx={{ pt: 3 }}>
            <Button
              variant="contained"
              color="primary"
              component={Link}
              to={`/course/${course._id}/details`}
              fullWidth
              sx={{
                textTransform: 'none',
                borderRadius: '50px',
                py: 1.5,
                boxShadow: theme.shadows[2],
                transition: 'all 0.3s ease',
                fontWeight: 600,
                '&:hover': {
                  transform: 'translateY(-2px)',
                  boxShadow: `0 4px 12px ${alpha(theme.palette.primary.main, 0.3)}`,
                },
              }}
              aria-label={`להמשך לימודים בקורס ${course.title}`}
            >
              להמשך לימודים
            </Button>
          </Box>
        </Box>
      </Card>
    );
  });