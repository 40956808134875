import React from 'react';
import { Box, Typography, Grid, CardContent, LinearProgress } from '@mui/material';
import { CalendarToday, Timeline, AccountBalanceWallet } from '@mui/icons-material';
import { format, differenceInDays } from 'date-fns';
import { InfoCard } from './styles';

const getSubscriptionProgress = (subscription) => {
  const total = subscription.duration * 30; // Approximate days
  const elapsed = differenceInDays(new Date(), new Date(subscription.startDate));
  return Math.min((elapsed / total) * 100, 100);
};

const SubscriptionTimeline = ({ subscription }) => (
  <Grid container spacing={3}>
    <Grid item xs={12} md={4}>
      <InfoCard>
        <CardContent>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <CalendarToday sx={{ mr: 1 }} />
            <Typography variant="h6">תאריך התחלה</Typography>
          </Box>
          <Typography variant="body1">
            {format(new Date(subscription.startDate), 'dd/MM/yyyy')}
          </Typography>
        </CardContent>
      </InfoCard>
    </Grid>
    <Grid item xs={12} md={4}>
      <InfoCard>
        <CardContent>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <Timeline sx={{ mr: 1 }} />
            <Typography variant="h6">משך המנוי</Typography>
          </Box>
          <Typography variant="body1">
            {subscription.duration} חודשים
          </Typography>
          <Box sx={{ mt: 2 }}>
            <LinearProgress
              variant="determinate"
              value={getSubscriptionProgress(subscription)}
              sx={{ height: 8, borderRadius: 4 }}
            />
          </Box>
        </CardContent>
      </InfoCard>
    </Grid>
    <Grid item xs={12} md={4}>
      <InfoCard>
        <CardContent>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <AccountBalanceWallet sx={{ mr: 1 }} />
            <Typography variant="h6">תשלום הבא</Typography>
          </Box>
          <Typography variant="body1">
            {subscription.nextPaymentDue
              ? format(new Date(subscription.nextPaymentDue), 'dd/MM/yyyy')
              : 'לא זמין'}
          </Typography>
        </CardContent>
      </InfoCard>
    </Grid>
  </Grid>
);

export default SubscriptionTimeline;
