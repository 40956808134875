// src/components/Course/CourseDetails/CourseHeader.js
import React from 'react';
import { Box, Typography, Tooltip, Fade, Grow, Zoom } from '@mui/material';
import IconDisplay from '../../Icon/IconDisplay';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LockIcon from '@mui/icons-material/Lock';
import {
  HeaderContainer,
  CourseTitle,
  CourseDescription,
  InfoCard,
  CourseTypeBadge,
  IconContainer,
  AccessChip
} from './styles';

const CourseHeader = ({ course, userHasAccess }) => {
  return (
    <HeaderContainer>
      <Zoom in timeout={500}>
        <Tooltip title={course.isLive ? 'קורס בשידור חי' : 'קורס מוקלט'}>
          <CourseTypeBadge 
            isLive={course.isLive} 
            aria-label={`סוג קורס: ${course.isLive ? 'רץ בלייב' : 'מוקלט'}`}
          >
            {course.isLive ? 'רץ בלייב' : 'מוקלט'}
          </CourseTypeBadge>
        </Tooltip>
      </Zoom>

      <Zoom in timeout={700}>
        <IconContainer>
          <IconDisplay iconName={course.icon} size={80} color="primary.main" />
        </IconContainer>
      </Zoom>
      
      <Fade in timeout={1000}>
        <CourseTitle variant="h3" component="h1">
          {course.title}
        </CourseTitle>
      </Fade>

      <Fade in timeout={1200}>
        <CourseDescription variant="h6">
          {course.description}
        </CourseDescription>
      </Fade>

      <Grow in timeout={1400}>
        <InfoCard>
          <Typography variant="subtitle1" color="text.secondary">
            נושא: {course.subject}
          </Typography>

          <Typography variant="subtitle1" color="text.secondary" sx={{ mt: 1 }}>
            משך הקורס: {course.duration} שעות
          </Typography>

          <AccessChip
            icon={userHasAccess ? <LockOpenIcon /> : <LockIcon />}
            label={userHasAccess ? 'יש לך גישה לקורס זה' : 'אין לך גישה לקורס זה'}
            color={userHasAccess ? 'success' : 'error'}
          />
        </InfoCard>
      </Grow>
    </HeaderContainer>
  );
};

export default CourseHeader;