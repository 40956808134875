import React from 'react';
import { Grid, Box, Typography } from '@mui/material';
import {
  CalendarToday,
  Event,
  AccessTime,
  People,
  LiveTv,
} from '@mui/icons-material';
import dayjs from 'dayjs';
import { ContentSection } from './styles';

const LessonInfo = ({ lesson, formattedStartDate, formattedEndDate }) => {
  return (
    <ContentSection>
      <Typography variant="h5" gutterBottom fontWeight="bold">
        פרטי השיעור
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <CalendarToday color="primary" />
            <Box>
              <Typography variant="body1" fontWeight="medium">
                תאריך התחלה: {formattedStartDate}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {dayjs(lesson.startDateTime).fromNow()}
              </Typography>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Event color="primary" />
            <Typography variant="body1" fontWeight="medium">
              תאריך סיום: {formattedEndDate}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <AccessTime color="primary" />
            <Typography variant="body1" fontWeight="medium">
              משך: {lesson.duration ? `${lesson.duration} דקות` : 'לא נקבע'}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <People color="primary" />
            <Typography variant="body1" fontWeight="medium">
              מקסימום תלמידים: {lesson.maxStudents > 0 ? lesson.maxStudents : 'ללא הגבלה'}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <LiveTv color="primary" />
            <Typography variant="body1" fontWeight="medium">
              שידור חי: {lesson.isLive ? 'כן' : 'לא'}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </ContentSection>
  );
};

export default React.memo(LessonInfo);
