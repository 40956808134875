// src/components/Checkout/PlanDetails/styles.js
import { styled } from '@mui/material/styles';
import { Paper, Card, Box } from '@mui/material';

export const StyledContainer = styled(Paper)({
  borderRadius: '16px',
  overflow: 'hidden',
  background: 'transparent',
  elevation: 0
});

export const StyledHeader = styled(Box)(({ theme }) => ({
  background: `linear-gradient(135deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
  padding: theme.spacing(3),
  position: 'relative',
  overflow: 'hidden',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'radial-gradient(circle at 50% 50%, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0) 70%)',
    animation: 'pulse 3s infinite'
  }
}));

export const StyledCard = styled(Card)(({ theme }) => ({
  transition: 'all 0.3s ease-in-out',
  borderRadius: theme.shape.borderRadius * 2,
  border: `1px solid ${theme.palette.divider}`,
  background: theme.palette.background.paper,
  '&:hover': {
    boxShadow: theme.shadows[8],
    transform: 'translateY(-4px)'
  },
  margin: theme.spacing(0, 2),
  marginTop: theme.spacing(-4),
  position: 'relative',
  zIndex: 1
}));

export const StyledBottomBorder = styled(Box)(({ theme }) => ({
  height: 4,
  background: `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0
}));
