// ./InfoCard/InfoCards.jsx
import React from 'react';
import { Grid } from '@mui/material';
import InfoCard from './InfoCard';
import infoCards from './infoCards';

const InfoCards = () => {
  return (
    <Grid container spacing={4}>
      {infoCards.map((card, index) => (
        <Grid item key={index} xs={12} sm={6} md={4}>
          <InfoCard
            title={card.title}
            description={card.description}
            imagePath={card.path}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default InfoCards;
