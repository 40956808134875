import React, { useContext, useEffect, useState, useCallback } from 'react';
import { useTheme } from '@mui/material/styles';
import {
  Box,
  Typography,
  Container,
  Grid,
  Alert,
  Snackbar,
  Skeleton,
  Card,
} from '@mui/material';

// Contexts
import { DataContext } from '../../../context/DataContext';
import { UserContext } from '../../../context/UserContext';

// Components
import CourseCard from './CourseCard';
import PlansModal from './PlansModal';
import AlertSnackbar from '../../Common/AlertSnackbar';

// Styles
import { SectionHeader } from './styles';

const AllCourses = () => {
  const theme = useTheme();
  
  // Context
  const { 
    courses, 
    plans,
    loading: dataLoading, 
    error: dataError 
  } = useContext(DataContext);
  
  const {
    isSignedIn,
    hasAccess,
    loading: userLoading,
    error: userError,
    setError,
  } = useContext(UserContext);

  // State Management
  const [courseAccess, setCourseAccess] = useState({});
  const [accessLoading, setAccessLoading] = useState(false);
  const [accessError, setAccessError] = useState(null);

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  const [plansModalOpen, setPlansModalOpen] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [availablePlans, setAvailablePlans] = useState([]);

  // Handlers
  const handleSelectPlan = (course) => {
    const plansForCourse = plans.filter((plan) =>
      plan.courses.some((courseItem) => courseItem._id === course._id)
    );

    if (plansForCourse.length === 0) {
      setSnackbar({
        open: true,
        message: 'אין תוכניות זמינות עבור קורס זה.',
        severity: 'warning',
      });
      return;
    }

    setSelectedCourse(course);
    setAvailablePlans(plansForCourse);
    setPlansModalOpen(true);
  };

  const handleCloseModal = () => {
    setPlansModalOpen(false);
    setTimeout(() => {
      setSelectedCourse(null);
      setAvailablePlans([]);
    }, 300);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  // Access Check Logic
  const checkAccessForCourses = useCallback(async () => {
    if (!isSignedIn || courses.length === 0) {
      const noAccess = {};
      courses.forEach((course) => {
        noAccess[course._id] = false;
      });
      setCourseAccess(noAccess);
      return;
    }

    setAccessLoading(true);
    setAccessError(null);

    try {
      const accessResults = {};
      await Promise.all(
        courses.map(async (course) => {
          const access = await hasAccess('course', course._id);
          accessResults[course._id] = access;
        })
      );
      setCourseAccess(accessResults);
    } catch (err) {
      console.error('Error checking course access:', err);
      setAccessError('נכשל אימות הגישה לחלק מהקורסים.');
    } finally {
      setAccessLoading(false);
    }
  }, [isSignedIn, courses, hasAccess]);

  // Initial Access Check
  useEffect(() => {
    checkAccessForCourses();
  }, [checkAccessForCourses]);

  // Loading Skeletons
  const renderSkeletons = () => {
    const skeletonArray = Array.from(new Array(6));
    return skeletonArray.map((_, index) => (
      <Grid item key={index} xs={12} sm={6} md={4}>
        <Card
          sx={{
            position: 'relative',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            boxShadow: theme.shadows[2],
            borderRadius: theme.shape.borderRadius,
            padding: theme.spacing(2),
          }}
        >
          <Skeleton
            variant="rectangular"
            width={80}
            height={24}
            sx={{
              position: 'absolute',
              top: theme.spacing(1),
              left: theme.spacing(1),
              borderRadius: 1,
            }}
          />
          <Skeleton
            variant="rectangular"
            width={100}
            height={24}
            sx={{
              position: 'absolute',
              top: theme.spacing(1),
              right: theme.spacing(1),
              borderRadius: 1,
            }}
          />
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
            <Skeleton variant="circular" width={80} height={80} />
          </Box>
          <Box sx={{ flexGrow: 1, textAlign: 'center' }}>
            <Skeleton variant="text" height={40} />
            <Skeleton variant="text" height={20} />
            <Skeleton variant="text" height={20} />
          </Box>
          <Box sx={{ display: 'flex', gap: 1, mt: 2 }}>
            <Skeleton variant="rectangular" width="100%" height={36} />
            <Skeleton variant="rectangular" width="100%" height={36} />
          </Box>
        </Card>
      </Grid>
    ));
  };

  // Loading and Error States
  if (dataLoading || userLoading || accessLoading) {
    return (
      <Container>
        <Box sx={{ textAlign: 'center', mb: 8 }}>
          <SectionHeader variant="h3">כל הקורסים</SectionHeader>
        </Box>
        <Grid container spacing={4}>
          {renderSkeletons()}
        </Grid>
      </Container>
    );
  }

  if (dataError || userError || accessError) {
    return (
      <Container>
        <Alert severity="error">
          {dataError || userError || accessError}
        </Alert>
      </Container>
    );
  }

  // Main Render
  return (
    <main className="all-courses">
      <Container dir="rtl">
        {/* Header Section */}
        <Box sx={{ textAlign: 'center', mb: 8 }}>
          <SectionHeader variant="h3">כל הקורסים</SectionHeader>
          <Typography variant="h6" color="text.secondary">
            גלה מגוון קורסים שנועדו לשפר את היכולות והידע שלך.
          </Typography>
        </Box>

        {/* Courses Grid */}
        <Grid container spacing={4}>
          {courses.length > 0 ? (
            courses.map((course) => (
              <CourseCard
                key={course._id}
                course={course}
                userHasAccess={courseAccess[course._id] || false}
                handleSelectPlan={handleSelectPlan}
              />
            ))
          ) : (
            <Grid item xs={12}>
              <Box sx={{ width: '100%', textAlign: 'center', mt: 4 }}>
                <Typography variant="h6">
                  אין קורסים זמינים כרגע.
                </Typography>
              </Box>
            </Grid>
          )}
        </Grid>

        {/* Error Message */}
        {userError && (
          <Box sx={{ mt: 4 }}>
            <Alert severity="error" onClose={() => setError(null)}>
              {userError}
            </Alert>
          </Box>
        )}

        {/* Modals and Notifications */}
        <PlansModal
          open={plansModalOpen}
          onClose={handleCloseModal}
          course={selectedCourse}
          plans={availablePlans}
        />

        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <AlertSnackbar
            onClose={handleCloseSnackbar}
            severity={snackbar.severity}
            sx={{ width: '100%' }}
          >
            {snackbar.message}
          </AlertSnackbar>
        </Snackbar>
      </Container>
    </main>
  );
};

export default AllCourses;