import React, { useState, useContext } from 'react';
import {
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  useMediaQuery,
  Divider,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  Box,
  Paper,
  Fade,
  Collapse,
} from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import {
  Accessible,
  Close,
  FontDownload,
  Contrast,
  Brightness4,
  Brightness7,
  MotionPhotosOff,
  TextFields,
  FontDownloadOff,
  Palette,
  RecordVoiceOver,
  KeyboardArrowDown,
} from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import { ThemeContext } from '../../context/ThemeContext';

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    width: 320,
    padding: theme.spacing(3),
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    borderRadius: '16px 0 0 16px',
    boxShadow: theme.shadows[8],
  },
}));

const AccessibilityButton = styled(IconButton)(({ theme }) => ({
  position: 'fixed',
  bottom: theme.spacing(22),
  right: theme.spacing(-1),
  zIndex: 1300,
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.common.white,
  boxShadow: `0 4px 14px ${alpha(theme.palette.secondary.main, 0.4)}`,
  '&:hover': {
    backgroundColor: theme.palette.secondary.dark,
    transform: 'scale(1.1) translateX(2px)',
  },
  borderRadius: '50%',
  width: 48,
  height: 48,
  transition: theme.transitions.create(['transform', 'background-color'], {
    duration: 200,
  }),
}));

const Section = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
  borderRadius: 12,
  backgroundColor: alpha(theme.palette.background.default, 0.6),
  '&:hover': {
    backgroundColor: alpha(theme.palette.background.default, 0.9),
    transform: 'translateY(-2px)',
  },
  transition: theme.transitions.create(['transform', 'background-color'], {
    duration: 200,
  }),
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  borderRadius: 8,
  marginBottom: theme.spacing(1),
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.main, 0.08),
  },
  '& .MuiListItemIcon-root': {
    color: theme.palette.primary.main,
    minWidth: 40,
  },
}));

const AccessibilitySidebar = () => {
  const theme = useTheme();
  const {
    mode,
    highContrast,
    fontSize,
    reduceMotion,
    dyslexiaFont,
    colorBlindMode,
    textToSpeech,
    toggleTheme,
    toggleHighContrast,
    increaseFontSize,
    decreaseFontSize,
    toggleReduceMotion,
    toggleDyslexiaFont,
    setColorBlindMode,
    toggleTextToSpeech,
    setFontSize,
  } = useContext(ThemeContext);
  
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [open, setOpen] = useState(false);
  const [expandedSection, setExpandedSection] = useState('');

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const resetFontSize = () => {
    setFontSize(16);
  };

  const handleSectionClick = (section) => {
    setExpandedSection(expandedSection === section ? '' : section);
  };

  const renderSection = (title, icon, content, sectionKey) => (
    <Section elevation={0}>
      <StyledListItem button onClick={() => handleSectionClick(sectionKey)}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText 
          primary={
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <Typography variant="subtitle1" fontWeight="medium">
                {title}
              </Typography>
              <KeyboardArrowDown 
                sx={{ 
                  transform: expandedSection === sectionKey ? 'rotate(180deg)' : 'rotate(0)',
                  transition: '0.3s',
                }} 
              />
            </Box>
          } 
        />
      </StyledListItem>
      <Collapse in={expandedSection === sectionKey}>
        <Box p={1}>
          {content}
        </Box>
      </Collapse>
    </Section>
  );

  return (
    <>
      <Fade in={true}>
        <Tooltip title="אפשרויות נגישות" arrow>
          <AccessibilityButton
            onClick={toggleDrawer}
            aria-label="פתח אפשרויות נגישות"
          >
            <Accessible />
          </AccessibilityButton>
        </Tooltip>
      </Fade>

      <StyledDrawer
        anchor="right"
        open={open}
        onClose={toggleDrawer}
        variant={isMobile ? 'temporary' : 'persistent'}
      >
        <Box p={2}>
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
            <Typography variant="h5" fontWeight="bold">
              אפשרויות נגישות
            </Typography>
            <IconButton onClick={toggleDrawer} size="small">
              <Close />
            </IconButton>
          </Box>

          {renderSection(
            "גודל טקסט",
            <TextFields />,
            <List disablePadding>
              <StyledListItem button onClick={increaseFontSize}>
                <ListItemIcon><FontDownload /></ListItemIcon>
                <ListItemText primary="הגדל גודל גופן" />
              </StyledListItem>
              <StyledListItem button onClick={decreaseFontSize}>
                <ListItemIcon><FontDownloadOff /></ListItemIcon>
                <ListItemText primary="הקטן גודל גופן" />
              </StyledListItem>
              <StyledListItem button onClick={resetFontSize}>
                <ListItemIcon><TextFields /></ListItemIcon>
                <ListItemText primary="אפס גודל גופן" />
              </StyledListItem>
            </List>,
            'fontSize'
          )}

          {renderSection(
            "מראה ותצוגה",
            <Palette />,
            <List disablePadding>
              <StyledListItem button onClick={toggleTheme}>
                <ListItemIcon>
                  {mode === 'dark' ? <Brightness7 /> : <Brightness4 />}
                </ListItemIcon>
                <ListItemText primary={mode === 'dark' ? 'מצב בהיר' : 'מצב כהה'} />
              </StyledListItem>
              <StyledListItem button onClick={toggleHighContrast}>
                <ListItemIcon><Contrast /></ListItemIcon>
                <ListItemText primary={highContrast ? 'בטל ניגודיות גבוהה' : 'ניגודיות גבוהה'} />
              </StyledListItem>
            </List>,
            'appearance'
          )}

          {renderSection(
            "הנגשת טקסט",
            <FontDownload />,
            <List disablePadding>
              <StyledListItem button onClick={toggleDyslexiaFont}>
                <ListItemIcon><FontDownload /></ListItemIcon>
                <ListItemText primary={dyslexiaFont ? 'בטל גופן דיסלקסיה' : 'גופן דיסלקסיה'} />
              </StyledListItem>
              <StyledListItem button onClick={toggleTextToSpeech}>
                <ListItemIcon><RecordVoiceOver /></ListItemIcon>
                <ListItemText primary={textToSpeech ? 'בטל הקראת טקסט' : 'הקראת טקסט'} />
              </StyledListItem>
            </List>,
            'textAccess'
          )}

          {renderSection(
            "התאמות צבע",
            <Palette />,
            <FormControl component="fieldset" fullWidth>
              <FormLabel component="legend" sx={{ mb: 2 }}>מצב עיוורון צבעים:</FormLabel>
              <RadioGroup
                value={colorBlindMode}
                onChange={(e) => setColorBlindMode(e.target.value)}
              >
                <FormControlLabel value="none" control={<Radio />} label="רגיל" />
                <FormControlLabel value="protanopia" control={<Radio />} label="פרוטנופיה" />
                <FormControlLabel value="deuteranopia" control={<Radio />} label="דויטרנופיה" />
                <FormControlLabel value="tritanopia" control={<Radio />} label="טריטאנופיה" />
              </RadioGroup>
            </FormControl>,
            'colorBlind'
          )}

          {renderSection(
            "אנימציות ותנועה",
            <MotionPhotosOff />,
            <StyledListItem button onClick={toggleReduceMotion}>
              <ListItemIcon><MotionPhotosOff /></ListItemIcon>
              <ListItemText primary={reduceMotion ? 'בטל הפחתת תנועה' : 'הפחת תנועה'} />
            </StyledListItem>,
            'motion'
          )}
        </Box>
      </StyledDrawer>
    </>
  );
};

export default AccessibilitySidebar;