import React, { useState, useRef } from 'react';
import { Box, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SchoolIcon from '@mui/icons-material/School';
import UnitLessons from './UnitLessons';
import {
  UnitsContainer,
  StyledAccordion,
  UnitTitle,
  TopicChip,
  SectionHeader,
  UnitDescription,
  TopicsContainer,
  TopicsTitle
} from './styles';

const CourseUnits = ({ units = [], lessons }) => {
  const [expandedPanel, setExpandedPanel] = useState(false);
  const unitRefs = useRef({});

  const handleAccordionChange = (unitId) => {
    if (expandedPanel !== unitId) {
      setExpandedPanel(unitId);
      setTimeout(() => {
        const element = unitRefs.current[unitId];
        if (element) {
          const rect = element.getBoundingClientRect();
          const isVisible = (
            rect.top >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
          );
          if (!isVisible) {
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
          }
        }
      }, 300); // Match the transition duration
    } else {
      setExpandedPanel(false);
    }
  };

  if (!units?.length) return null;

  return (
    <UnitsContainer>
      <SectionHeader variant="h4">יחידות הקורס</SectionHeader>

      {units.map((unit) => (
        <StyledAccordion
          key={unit._id}
          expanded={expandedPanel === unit._id}
          onChange={() => handleAccordionChange(unit._id)}
          TransitionProps={{ 
            timeout: {
              enter: 300,
              exit: 200
            },
            unmountOnExit: true
          }}
          sx={{
            '& .MuiCollapse-root': {
              transformOrigin: 'top'
            }
          }}
        >
          <AccordionSummary
            ref={(el) => unitRefs.current[unit._id] = el} // Correct ref assignment
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`unit-content-${unit._id}`}
            id={`unit-header-${unit._id}`}
          >
            <UnitTitle>
              <SchoolIcon className="icon" color="primary" />
              {unit.title}
            </UnitTitle>
          </AccordionSummary>
          
          <AccordionDetails sx={{ pt: 0 }}>
            <Box>
              {unit.description && (
                <UnitDescription>
                  {unit.description}
                </UnitDescription>
              )}

              {unit.mainTopics?.length > 0 && (
                <TopicsContainer>
                  <TopicsTitle variant="subtitle1">
                    נושאים עיקריים:
                  </TopicsTitle>
                  <Box>
                    {unit.mainTopics.map((topic, index) => (
                      <TopicChip
                        key={index}
                        label={topic}
                        variant="outlined"
                        color="primary"
                      />
                    ))}
                  </Box>
                </TopicsContainer>
              )}

              <UnitLessons 
                roadmap={unit.roadmap} 
                lessons={lessons} 
              />
            </Box>
          </AccordionDetails>
        </StyledAccordion>
      ))}
    </UnitsContainer>
  );
};

export default CourseUnits;
