import React from 'react';
import { 
  Container,
  Typography,
  Paper,
  Box,
  Divider,
  Link,
  useTheme,
  Fade
} from '@mui/material';
import { motion } from 'framer-motion';
import { Shield, Lock, PersonSearch, DataObject, Gavel } from '@mui/icons-material';

const PrivacyPolicy = () => {
  const theme = useTheme();
  
  const sections = [
    {
      title: 'המידע שאנו אוספים',
      icon: <PersonSearch />,
      content: `אנו אוספים מידע אישי הכרחי בלבד כגון:
      • שם מלא ופרטי התקשרות
      • מידע לימודי רלוונטי
      • היסטוריית למידה והתקדמות
      מידע זה נדרש לצורך מתן שירות מיטבי ומותאם אישית.`
    },
    {
      title: 'שימוש במידע',
      icon: <DataObject />,
      content: `המידע משמש אותנו ל:
      • התאמת תוכנית לימודים אישית
      • מעקב אחר התקדמות והישגים
      • שיפור השירות והחומרים הלימודיים
      • תקשורת בנושאים פדגוגיים ומנהלתיים`
    },
    {
      title: 'אבטחת מידע',
      icon: <Lock />,
      content: 'אנו נוקטים באמצעי אבטחה מתקדמים להגנה על המידע האישי שלך. כל המידע מאוחסן בשרתים מאובטחים ומוצפן בסטנדרטים המחמירים ביותר.'
    },
    {
      title: 'שיתוף מידע',
      icon: <Shield />,
      content: 'איננו משתפים מידע אישי עם צדדים שלישיים, למעט במקרים בהם הדבר נדרש על פי חוק או בהסכמתך המפורשת.'
    },
    {
      title: 'זכויותיך',
      icon: <Gavel />,
      content: 'הנך זכאי לעיין במידע האישי שלך, לבקש את תיקונו או מחיקתו. לפניות בנושא ניתן ליצור קשר בדוא"ל: ohadinfinite@gmail.com'
    }
  ];                  
  // Animation variants for sections
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2
      }
    }
  };

  const sectionVariants = {
    hidden: { 
      opacity: 0,
      x: -20
    },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        type: "spring",
        stiffness: 100,
        damping: 10
      }
    }
  };

  const iconVariants = {
    hidden: { scale: 0 },
    visible: {
      scale: 1,
      transition: {
        type: "spring",
        stiffness: 260,
        damping: 20
      }
    }
  };

  return (
    <Container maxWidth="md" >
      <Fade in timeout={1000}>
        <Paper 
          elevation={3}
          sx={{ 
            p: { xs: 3, sm: 4, md: 5 }, 
            borderRadius: 2,
            background: `linear-gradient(145deg, ${theme.palette.background.paper} 0%, ${theme.palette.background.default} 100%)`
          }}
        >
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
          >
            <Typography 
              variant="h3" 
              component="h1" 
              gutterBottom 
              align="center"
              sx={{ 
                color: 'primary.main',
                fontWeight: 'bold',
                mb: 4
              }}
            >
              מדיניות פרטיות
            </Typography>

            <Typography 
              variant="subtitle1" 
              paragraph
              sx={{ mb: 4 }}
            >
              בתור מורה פרטי, אני מתחייב להגן על פרטיותך ולשמור על המידע האישי שלך. מסמך זה מפרט את האופן בו אני אוסף ומשתמש במידע במסגרת שירותי ההוראה הפרטית.
            </Typography>
          </motion.div>

          <motion.div
            variants={containerVariants}
            initial="hidden"
            animate="visible"
          >
            {sections.map((section, index) => (
              <motion.div
                key={index}
                variants={sectionVariants}
              >
                <Box 
                  sx={{ 
                    mb: 4,
                    p: 3,
                    borderRadius: 2,
                    transition: 'transform 0.3s, box-shadow 0.3s',
                    '&:hover': {
                      transform: 'translateY(-4px)',
                      boxShadow: theme.shadows[4],
                      bgcolor: 'rgba(0, 0, 0, 0.02)'
                    }
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <motion.div variants={iconVariants}>
                      <Box
                        sx={{
                          mr: 2,
                          color: 'primary.main',
                          display: 'flex',
                          alignItems: 'center'
                        }}
                      >
                        {section.icon}
                      </Box>
                    </motion.div>
                    <Typography 
                      variant="h5"
                      sx={{ 
                        color: 'primary.main',
                        fontWeight: 'bold',
                      }}
                    >
                      {section.title}
                    </Typography>
                  </Box>
                  <Divider sx={{ mb: 2 }} />
                  <Typography 
                    variant="body1"
                    sx={{ 
                      lineHeight: 1.8,
                      whiteSpace: 'pre-line'
                    }}
                  >
                    {section.content}
                  </Typography>
                </Box>
              </motion.div>
            ))}
          </motion.div>

          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 1.5 }}
          >
            <Box sx={{ 
              mt: 6, 
              pt: 3, 
              borderTop: `1px solid ${theme.palette.divider}`,
              textAlign: 'center'
            }}>
              <Typography variant="subtitle2" color="text.secondary">
                עדכון אחרון: נובמבר 2024
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                לשאלות נוספות ניתן ליצור קשר ב-
                <Link 
                  href="mailto:ohad@example.com" 
                  color="primary"
                  sx={{ 
                    mx: 1,
                    textDecoration: 'none',
                    '&:hover': { 
                      textDecoration: 'underline' 
                    }
                  }}
                >
                  ohadinfinite@gmail.com
                </Link>
              </Typography>
            </Box>
          </motion.div>
        </Paper>
      </Fade>
    </Container>
  );
};

export default PrivacyPolicy;