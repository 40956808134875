import React from 'react';
import { Drawer, Toolbar, Box } from '@mui/material';
import NavigationList from './NavigationList';
import ThemeToggle from './ThemeToggle';
import UserMenu from './UserMenu';
import Logo from './Logo';
import { useTheme } from '@mui/material/styles';

const Sidebar = ({ drawerWidth, sidebarItems }) => {
  const theme = useTheme();
  
  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: drawerWidth,
          boxSizing: 'border-box',
          backgroundColor: theme.palette.background.paper,
          borderRight: `1px solid ${theme.palette.divider}`,
        },
      }}
    >
      <Toolbar>
        <Logo />
      </Toolbar>
      <Box sx={{ overflow: 'auto' }}>
        <NavigationList items={sidebarItems} onItemClick={() => {}} />
      </Box>
      <Box sx={{ flexGrow: 1 }} />
      <ThemeToggle onToggle={() => {}} />
      <UserMenu />
    </Drawer>
  );
};

export default Sidebar;
