import React, { useContext } from 'react';
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { Brightness4 as Brightness4Icon } from '@mui/icons-material';
import { ThemeContext } from '../../context/ThemeContext';
import { useTheme } from '@mui/material/styles';

const ThemeToggle = ({ onToggle }) => {
  const theme = useTheme();
  const { toggleTheme } = useContext(ThemeContext);

  return (
    <List>
      <ListItem button onClick={() => { toggleTheme(); onToggle(); }}>
        <ListItemIcon sx={{ color: theme.palette.text.primary }}>
          <Brightness4Icon />
        </ListItemIcon>
        <ListItemText
          primary="שנה נושא"
          sx={{ color: theme.palette.text.primary }}
        />
      </ListItem>
    </List>
  );
};

export default ThemeToggle;
